import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import {
  Box,
    Button, CircularProgress, LinearProgress, Typography,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: theme.spacing(2)
  },
  loadingBar: {
    width: '100%',
  },
  loadingCircle: {
      width: '20px !important',
      height: '20px !important',
      marginRight: theme.spacing(1)
  }
}));

function LinearProgressWithLabel(props) {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

export default function ProgressLoadingButton(props) {
  const classes = useStyles();

  return (
    <>
    <Button
        {...props}
        color={props.loading ? "default" : props.color}
        disabled={props.disabled || props.loading }
        startIcon={
            <>
                {props.loading&& <CircularProgress className={classes.loadingCircle} color={"default"} value={props.progress} />}
                {props.startIcon}
            </>
        }>
        <div  className={classes.content} >
          {props.children}
          <div  className={classes.loadingBar} >
            {props.loading&& <LinearProgressWithLabel value={props.progress} />}
          </div>
        </div>
    </Button>

    </>
  )
}