import React, { useContext, useEffect, useState } from 'react';
import clsx from 'clsx';
import { makeStyles, withTheme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
// import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
// import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { SiteContext } from '../context/SiteContext/context'
import { BrandContext } from '../context/BrandContext/context'
import { UserContext } from '../context/UserContext/context';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Icon from "@material-ui/core/Icon";
import { Link, NavLink } from "react-router-dom";
import Typography from '@material-ui/core/Typography';
import { useHistory } from 'react-router-dom'

import {
    ChevronRight,
    ChevronLeft,
    Clear

} from '@material-ui/icons';
import { Button, Checkbox, CircularProgress, FormControlLabel, Grid, TextField } from '@material-ui/core';
import AuthController from '../controllers/AuthController';
import { Alert } from '@material-ui/lab';


const drawerWidth = 240;


const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: '550px'
    },
    paper: {
        margin: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    title: {
        textAlign: 'center'
    },
    loading: {
        width: '20px !important',
        height: '20px !important',
        marginLeft: '10px',
    }
}));


export default function SignUp(props) {
    const classes = useStyles();
    const [agreeTermsAndCondition, setAgreeTermsAndCondition] = useState(false)
    const [error, setError] = useState(false)
    const [loading, setLoading] = useState(false)
    const history = useHistory()

    const handleSubmit = async (e) => {
        try {
            e.preventDefault()
            setError(false)
            setLoading(true)
            let data = e.target

            let client = {
                user: {
                    firstName: data.firstName.value,
                    email: data.email.value,
                    password: data.password.value,
                    contactNumber: data.contactNumber.value,
                },
                name: data.businessName.value,
                physicalAddress: { },
                agreeTermsAndCondition: agreeTermsAndCondition,
                agreePrivacyPolicy: agreeTermsAndCondition
            }

            if (data.lastName.value) client.user.lastName = data.lastName.value
            if (data.contactNumber.value) client.user.contactNumber = data.contactNumber.value
            if (data.vatNumber.value) client.vatNumber = data.vatNumber.value
            if (data.streetAddress.value) client.physicalAddress.streetAddress = data.streetAddress.value
            if (data.suburb.value) client.physicalAddress.suburb = data.suburb.value
            if (data.city.value) client.physicalAddress.city = data.city.value
            if (data.province.value) client.physicalAddress.province = data.province.value
            if (data.country.value) client.physicalAddress.country = data.country.value

            let success = await AuthController.SignUpClient(client)
            if(success) history.push('/signin');

            setLoading(false)

            console.log('!!! SUCCESS', success)
        } catch (error) {
            setLoading(false)
            setError(error.message)
            console.error('!!! Registration Error', error)
        }

    };

    return (
        <div className={classes.root}>
            <Typography variant="h2" className={classes.title}> Sign Up</Typography>
            <form className={classes.form} onSubmit={handleSubmit} >
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h6"> Account Information</Typography>
                        <Divider />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            disabled={loading}
                            name="firstName"
                            variant="outlined"
                            required
                            fullWidth
                            id="firstName"
                            label="First Name"
                            autoFocus
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            disabled={loading}
                            variant="outlined"
                            fullWidth
                            id="lastName"
                            label="Last Name"
                            name="lastName"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            disabled={loading}
                            variant="outlined"
                            required
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                        />
                    </Grid>
                    <Grid item xs={12} >
                        <TextField
                            disabled={loading}
                            variant="outlined"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant="h6"> Business Information</Typography>
                        <Divider />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            disabled={loading}
                            variant="outlined"
                            required
                            fullWidth
                            id="businessNumber"
                            label="Business Name"
                            name="businessName"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            disabled={loading}
                            variant="outlined"
                            required
                            fullWidth
                            id="contactNumber"
                            label="Contact number"
                            name="contactNumber"
                            type="number"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            disabled={loading}
                            variant="outlined"
                            fullWidth
                            id="vatNumber"
                            label="VAT number"
                            name="vatNumber"
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant="h6"> Business Address</Typography>
                        <Divider />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            disabled={loading}
                            variant="outlined"
                            fullWidth
                            id="streetAddress"
                            label="Street Number, Street Name"
                            name="streetAddress"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            disabled={loading}
                            variant="outlined"
                            fullWidth
                            id="suburb"
                            label="Suburb"
                            name="suburb"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            disabled={loading}
                            variant="outlined"
                            fullWidth
                            id="city"
                            label="City"
                            name="city"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            disabled={loading}
                            variant="outlined"
                            fullWidth
                            id="province"
                            label="Province"
                            name="province"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            disabled={loading}
                            variant="outlined"
                            fullWidth
                            id="country"
                            label="Country"
                            name="country"
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Checkbox checked={agreeTermsAndCondition} color="primary" onChange={(e) => { setAgreeTermsAndCondition(e.target.checked) }} />
                        <Typography variant="caption"> I have read and understand the <Link href="#">Terms and Conditions</Link> and the <Link href="#">Privacy Policy</Link></Typography>
                    </Grid>
                </Grid>

                {error && <Alert severity="error"> {error} </Alert>}
                <Button
                    type="submit"
                    disabled={!agreeTermsAndCondition || loading}
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                >
                    Sign Up
                    {loading && <CircularProgress className={classes.loading} />}
                </Button>
            </form>
        </div>
    );
}