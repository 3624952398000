import React, { useContext, useEffect, useRef, useState } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import _ from 'lodash';
import Paper from "@material-ui/core/Paper";
import { UserContext } from "../context/UserContext/context";
import { DataContext } from "../context/DataContext/context";
import Utils from "../utils/Utils";
import DataController from "../controllers/DataController"
import { Checkbox, Dialog, Fab, Grid, IconButton, List, ListItem, ListItemIcon, ListItemText, Typography } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import TagListItem from "../components/TagListItem";
import TagAdd from "../components/TagAdd";


const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexGrow: 1,
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
    fab: {
        position: "absolute",
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    gridList: {
        width: "100%",
        textAlign: "left",
    },
    gridListTile: {
        margin: theme.spacing(1),
    },
    paperContainerHeading: {
        width: "100%",
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
    },
    headingTitle: {
        padding: theme.spacing(1),
    },
    headingActionItems: {
        color: theme.palette.primary.contrastText,
        textAlign: "right",
    },
    paperContainer: {
        width: "100%",
        margin: "auto",
    },
    gridItem: {
        margin: theme.spacing(3),
    },
}));

export default function Tags({ props }) {
    const { user } = useContext(UserContext);
    // const { data, addTags, updateTag, deleteTag } = useContext(DataContext);
    const [tags, setTags] = useState([])

    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const selectedTagRef = useRef()


    useEffect(() => {
        async function init() {
            setTags(await DataController.GetTags())
        }
        init()
    }, [])

    const handleAdd = (e) => {
        selectedTagRef.current = null;
        setOpen(true);
    };

    const addTag = async (tag) => {
        try {
            if (!tag) return

            let newTag = await DataController.AddTag(tag)

            let d = [...tags]
            d.push(newTag)
            setTags(d)
        } catch (error) {
            throw error
        }
    }

    const updateTag = async (tag) => {
        try {
            console.log('update tag: ', tag)
            let d = [...tags]
            let e = d.filter(e => e.id === tag.id)
            if (e.length === 0) return

            let data = await DataController.UpdateTag(tag)
            e[0] = data
            for (let t in d) {
                if (d[t].id === tag.id) {
                    d[t] = { ...tag }
                    break
                }
            }
            console.log('d', d)
            setTags(d)
        } catch (err) {
            console.log(err)
            throw err
        }
    }

    const deleteTag = async (tag) => {
        if (!tag || !tag.id) return
        try {
            let d = [...tags]
            let e = d.filter(e => e.id !== tag.id)
            if (e.length === 0) return

            await DataController.DeleteTag(tag)

            d = e
            setTags(d)
        } catch (err) {
            console.log(err)
            throw err
        }
    }


    const handleSave = (details) => {
        if (!details || !details.name) throw "Cannot save, invalid details provided"
        if (selectedTagRef.current && _.isEqual(details, selectedTagRef.current))
            return handleClose()

        try {
            if (!details.id) handleNewTag(details)
            else updateTag(details)
            setOpen(false);

            selectedTagRef.current = null;
        } catch (error) {
            console.error(error)
        }
    };

    const handleClose = (details) => {
        selectedTagRef.current = null;
        setOpen(false);
    };

    const handleTagCardClick = (tag) => {
        selectedTagRef.current = tag
        setOpen(true);
    }

    const handleNewTag = (tag) => {
        if (!tag || !tag.name || tag.name.length === 0) return
        addTag(tag)
    }

    const handleOnEditClicked = (tag) => {
        if (!tag || !tag.id || !tag.name || tag.name.length === 0) return
        handleTagCardClick(tag)
    }

    const handleTagDelete = tag => {
        if (!tag || !tag.id || !tag.name || tag.name.length === 0) return
        deleteTag(tag)
    }

    return (
        <div className={classes.root}>
            <Paper className={classes.paperContainer}>
                <div className={classes.paperContainerHeading}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm container>
                            <Grid item xs container direction="column">
                                <Grid item xs>
                                    <Typography variant="h6" className={classes.headingTitle}>Tags</Typography>
                                </Grid>
                            </Grid>
                            {user.roles.editor&& <Grid item>
                                <IconButton
                                    aria-label="ListView"
                                    className={classes.headingActionItems}
                                    onClick={handleAdd}
                                >
                                    <Add />
                                </IconButton>
                            </Grid>}
                        </Grid>
                    </Grid>
                </div>
                <List>
                    {tags.map((item) => {
                        return (
                            <ListItem>
                                <TagListItem editable={user.roles.editor}tag={item} onEdit={handleOnEditClicked} onDelete={handleTagDelete} />
                            </ListItem>
                        );
                    })}
                </List>
            </Paper>
            {user.roles.editor&& <Dialog
                onClose={handleClose}
                aria-labelledby="simple-dialog-title"
                open={open}
            >
                <TagAdd
                    handleSave={handleSave}
                    handleCancel={handleClose}
                    editable={
                        true
                    }
                    tags={tags}
                    details={selectedTagRef.current}
                />
            </Dialog>}
           {user.roles.editor&& <Fab
                color="secondary"
                aria-label="add"
                className={classes.fab}
                onClick={handleAdd}
            >
                <Add />
            </Fab>}
        </div>
    );
}
