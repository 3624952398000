import React, { createRef, useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import { Visibility, Delete } from "@material-ui/icons";
import {
    Checkbox,
    Radio,
    Divider,
    IconButton,
    InputAdornment,
    InputLabel,
    List,
    ListItem,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
    TextField,
} from "@material-ui/core";
import FollowupAdd from "./FollowupAdd";
import ConditionAdd from "./ConditionAdd";
import Utils from "../../utils/Utils";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%'
    },
    fullWidth: {
        width: '100%'
    },
    smallIcon: {
        minWidth: '0px'
    },
    addItem: {
        color: theme.palette.grey
    },
    contentContainer: {
        display: 'flex',
        flexGrow: 1
    },
    followup: {
        margin: theme.spacing(2)
    },
    condition: {
        margin: theme.spacing(2)
    },
    textField: {
        marginLeft: theme.spacing(1)
    }
}));

export default function CheckboxesAdd(props) {
    const classes = useStyles();
    const [options, setOptions] = useState(props.options ? props.options : [])
    const [optionCounter, setOptionCounter] = useState(1)
    const [other, setOther] = useState()
    const [details, setDetails] = useState({
        ...props.details,
        // question: props.details && props.details.question ? props.details.question : '',
        type: props.details && props.details.type ? props.details.type : 'checkboxes',
        // answers: props.details && props.details.answers ? props.details.answers : {},
        // subquestions: props.details && props.details.subquestions ? props.details.subquestions : [],
        // followup: props.details && props.details.followup ? props.details.followup : {},
        // condition: props.details && props.details.condition ? props.details.condition : {}
    })

    useEffect(() => {
        if(details.answers){
            let newOptions = [...options]
            Object.keys(details.answers).map(o => {
                let obj = {
                    id: Utils.uuid(),
                    key: o,
                    value: details.answers[o].value ? details.answers[o].value : false
                }
                newOptions.push(obj)
                if(o === 'Other')
                    setOther(obj)
            })
            setOptions(newOptions)
        }
    }, [])

    useEffect(() => {
        if (props.onChange) props.onChange(details)
    }, [details])

    useEffect(() => {
        let d = JSON.parse(JSON.stringify(details))
        let optionsOBJ = {}
        options.map(o => {
            optionsOBJ[o.key] = o.value
        })
        d.answers = optionsOBJ
        setDetails(d)
    }, [options])


    const handleFollowup = (followup) => {
        let d = JSON.parse(JSON.stringify(details))
        d.followup = followup
        setDetails(d)
    }

    const handleCondition = (condition) => {
        let d = JSON.parse(JSON.stringify(details))
        d.condition = condition
        setDetails(d)
    }

    const handleAddClick = (e) => {
        let currOptions = [...options]
        currOptions.push({ id: Utils.uuid(), key: 'Option ' + optionCounter, value: false })
        setOptions(currOptions)
        setOptionCounter(optionCounter + 1)
    }

    const handleAddOther = (e) => {
        let currOptions = [...options]
        let newOther = { id: Utils.uuid(), key: 'Other', value: '' }
        currOptions.push(newOther)
        setOptions(currOptions)
        setOther(newOther)
    }

    const handleDelete = (option) => {
        if (!option || !option.id) return
        let currOptions = options.filter(o => o.id !== option.id)
        setOptions(currOptions)
        if (option.key === 'Other') setOther(null)
    }

    const handleItemChange = (value, newValue) => {
        if (!value || !value.id || !newValue || !newValue.id || value.key === newValue.key) return
        let currOptions = [...options]
        for (let i in currOptions) {
            if (currOptions[i].id === value.id) {
                currOptions[i].key = newValue.key
                break
            }
        }
        setOptions(currOptions)
    }

    return (
        <div className={classes.root}>
            <Divider />
            <List className={classes.root}>
                {options.map((option) => {
                    if (option.key === 'Other') return <></>
                    const labelId = `checkbox-list-label-${option.id}`;
                    return (
                        <ListItem
                            key={option.id}
                            dense >
                            <ListItemIcon className={classes.smallIcon}>
                                <Checkbox
                                    size="small"
                                    edge="start"
                                    checked={false}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{ 'aria-labelledby': labelId }}
                                />
                            </ListItemIcon>
                            <Item value={option} onDelete={handleDelete} onChange={(newValue) => {
                                handleItemChange(option, newValue)
                            }} />
                            <ListItemSecondaryAction size="small">
                                <IconButton
                                    edge="end"
                                    size="small"
                                    aria-label="delete"
                                    onClick={(e) => handleDelete(option)}>
                                    <Delete />
                                </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>
                    );
                })}
                {other && <ListItem
                    key={other.id + "_item"}
                    dense >
                    <ListItemIcon className={classes.smallIcon}>
                        <Checkbox
                            size="small"
                            edge="start"
                            checked={false}
                            tabIndex={-1}
                            disableRipple
                            inputProps={{ 'aria-labelledby': 'add' }}
                        />
                    </ListItemIcon>
                    <TextField
                        id="other"
                        // label="Other"
                        disabled
                        placeholder="Other"
                        margin="small"
                        // variant="outlined"
                        fullWidth
                        className={classes.textField}
                    />
                    <ListItemSecondaryAction size="small">
                        <IconButton
                            edge="end"
                            size="small"
                            aria-label="delete"
                            onClick={(e) => handleDelete(other)}>
                            <Delete />
                        </IconButton>
                    </ListItemSecondaryAction>
                </ListItem>}
                <ListItem
                    key={"addItem"}
                    dense >
                    <ListItemIcon className={classes.smallIcon}>
                        <Checkbox
                            size="small"
                            edge="start"
                            checked={false}
                            tabIndex={-1}
                            disableRipple
                            inputProps={{ 'aria-labelledby': 'add' }}
                        />
                    </ListItemIcon>
                    <Button color="default" className={classes.addItem} onClick={handleAddClick}>Add Option</Button>
                    {!other &&
                        <>or
                            <Button color="primary" className={classes.addItem} onClick={handleAddOther}>Add Other</Button>
                        </>
                    }
                </ListItem>
            </List>
            {/* <Divider /> */}
            {/* <div className={classes.contentContainer}>
                <div className={classes.followup}>
                    <Typography> Follow up Question</Typography>
                    <FollowupAdd details={details.followup} type={"boolean"} onChange={handleFollowup} />
                </div>
                <div className={classes.condition}>
                    <Typography> Condition </Typography>
                    <ConditionAdd details={details.condition} type={"boolean"} onChange={handleCondition} />
                </div>
            </div> */}
            {/* <Divider /> */}
        </div>
    )
}

function Item(props) {
    const classes = useStyles()
    const [value, setValue] = useState(props.value ? props.value.key : '')
    const [valueObj, setValueObj] = useState(props.value ? props.value : { key: '' })

    useEffect(() => {
        console.log('value', valueObj.key)
        setValue(valueObj.key)
    }, [valueObj])

    return (
        <div className={classes.fullWidth}>
            <TextField
                id="option_item"
                placeholder="Option"
                margin="none"
                size="small"
                fullWidth
                style={{ width: "97%", margin: "8pt" }}
                // className={classes.textField}
                defaultValue={value}
                // value={value}
                onChange={(e) => {
                    let newValue = Object.assign({}, valueObj)
                    newValue.key = e.target.value
                    setValueObj(newValue)
                    if (props.onChange) props.onChange(newValue)
                }}
            />
        </div>
    )
}