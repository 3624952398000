import React, { useContext, useEffect, useRef, useState } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import _ from 'lodash';
import Paper from "@material-ui/core/Paper";
import { UserContext } from "../context/UserContext/context";
import { DataContext } from "../context/DataContext/context";
import Utils from "../utils/Utils";
import DataController from "../controllers/DataController"
import { Button, Checkbox, Collapse, Dialog, Fab, Grid, IconButton, List, ListItem, ListItemIcon, ListItemText, Typography } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import QuestionListItem from "../components/QuestionListItem";
import QuestionAdd from "../components/QuestionAdd";
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { useParams } from "react-router-dom";


const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexGrow: 1,
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
    fab: {
        position: "absolute",
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    gridList: {
        width: "100%",
        textAlign: "left",
    },
    gridListTile: {
        margin: theme.spacing(1),
    },
    paperContainerHeading: {
        width: "100%",
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
    },
    headingTitle: {
        padding: theme.spacing(1),
    },
    headingActionItems: {
        color: theme.palette.primary.contrastText,
        textAlign: "right",
    },
    paperContainer: {
        width: "100%",
        margin: "auto",
    },
    gridItem: {
        margin: theme.spacing(3),
    },
}));

export default function Questionnaire({ props }) {
    const { user } = useContext(UserContext);
    const { data } = useContext(DataContext)
    let { type } = useParams();
    const [questions, setQuestions] = useState([])
    // const { data, addQuestion, updateQuestion, deleteQuestion } = useContext(DataContext);

    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [openQuestions, setOpenQuestions] = React.useState([]);
    const selectedQuestionRef = useRef()
    const [endCondition, setEndCondition] = useState()
    const [endConditionOpen, setEndConditionOpen] = useState(false)
    const [questionType, setQuestionType] = useState('inception')
    const [exclusions, setExclusions] = useState([])

    useEffect(() => {
        let newQuestionType = questionType
        if (type) newQuestionType = type
        setQuestionType(newQuestionType)
        async function init() {
            setExclusions(await DataController.GetExclusions())
            setQuestions(await DataController.GetQuestions(newQuestionType))
        }
        init()
    }, [])

    const handleAdd = (e) => {
        selectedQuestionRef.current = null;
        setOpen(true);
    };

    const addQuestion = async (question) => {
        try {
            if (!question) return

            let newQuestion = await DataController.AddQuestion(question)

            let d = [...questions]
            d.push(newQuestion)
            setQuestions(d)
        } catch (error) {
            throw error
        }
    }

    const updateQuestion = async (question) => {
        try {
            console.log('update question: ', question)
            let d = [...questions]
            let e = d.filter(e => e.id === question.id)
            if (e.length === 0) return

            if(question.exclusions) question.exclusions.map(q => delete q.created_at)

            let data = await DataController.UpdateQuestion(question)
            e[0] = data
            for (let t in d) {
                if (d[t].id === question.id) {
                    d[t] = { ...question }
                    break
                }
            }
            console.log('d', d)
            setQuestions(d)
        } catch (err) {
            console.log(err)
            throw err
        }
    }

    const deleteQuestion = async (question) => {
        if (!question || !question.id) return
        try {
            let d = [...questions]
            let e = d.filter(e => e.id !== question.id)
            if (e.length === 0) return

            await DataController.DeleteQuestion(question)

            d = e
            setQuestions(d)
        } catch (err) {
            console.log(err)
            throw err
        }
    }


    const handleSave = async (details) => {
        if (!details || !details.question || !details.type) throw "Cannot save, invalid details provided"
        if (selectedQuestionRef.current && _.isEqual(details, selectedQuestionRef.current))
            return handleClose()

        try {
            let e = await handleNewExclusions(details.exclusions)

            if (!details.question_type) details.question_type = questionType
            if (!details.id) handleNewQuestion(details)
            else updateQuestion(details)
            setOpen(false);

            selectedQuestionRef.current = null;
        } catch (error) {
            console.error(error)
        }
    };

    const handleClose = (details) => {
        selectedQuestionRef.current = null;
        setOpen(false);
    };

    const handleQuestionCardClick = (question) => {
        selectedQuestionRef.current = question
        setOpen(true);
    }

    const handleNewQuestion = (question) => {
        if (!question || !question.question || question.question.length === 0) throw 'Invalid Question'

        addQuestion(question)
    }

    const handleOnEditClicked = (question) => {
        if (!question || !question.id || !question.question || question.question.length === 0) return
        handleQuestionCardClick(question)
    }

    const handleQuestionDelete = question => {
        if (!question || !question.id || !question.question || question.question.length === 0) return
        deleteQuestion(question)
    }

    const onCondition = (answer, question, condition) => {
        setEndCondition(condition)
        if (condition.action === 'cannot_proceed') {
            setEndConditionOpen(true)
        } else {
            setEndConditionOpen(false)
        }
    }

    const onComplete = (data) => {
        alert('WOOOOOOOOOOOOO')
    }

    const dialogClose = () => {
        setEndConditionOpen(false)
    }

    const handleNewExclusions = async (qExclusions) => {
        try {
            if (!qExclusions || qExclusions.length === 0) return
            let newExclusions = qExclusions.filter(t => t.inputValue)
            for (let exclusion in newExclusions) {
                newExclusions[exclusion].name = newExclusions[exclusion].inputValue
                delete newExclusions[exclusion].inputValue
                let id = await DataController.AddExclusion(newExclusions[exclusion])
                newExclusions[exclusion].id = id.id
            }
            if (newExclusions.length > 0) {
                let d = [...exclusions]
                d.push(...newExclusions)
                setExclusions(d)
            }
            return newExclusions

        } catch (error) {
            throw error
        }
    }

    const handleExpandMoreClick = (item) => {
        if (openQuestions.filter(q => q.name === item.name).length > 0) handleExpandLessClick(item)
        let currOpens = openQuestions
        currOpens.push(item)
        setOpenQuestions(currOpens)
    }

    const handleExpandLessClick = (item) => {
        let currOpens = openQuestions.filter(q => q.name !== item.name)
        if (!currOpens || currOpens.length === 0) return
        setOpenQuestions(currOpens)
    }

    return (
        <div className={classes.root}>
            <Paper className={classes.paperContainer}>
                <div className={classes.paperContainerHeading}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm container>
                            <Grid item xs container direction="column">
                                <Grid item xs>
                                    <Typography variant="h6" className={classes.headingTitle}>Questions</Typography>
                                </Grid>
                            </Grid>
                            {user.roles.editor && <Grid item>
                                <IconButton
                                    aria-label="ListView"
                                    className={classes.headingActionItems}
                                    onClick={handleAdd}
                                >
                                    <Add />
                                </IconButton>
                            </Grid>}
                        </Grid>
                    </Grid>
                </div>
                <List>
                    {questions.map((question) => {
                        return <QuestionListItem details={question} editable={user.roles.editor} onEdit={handleOnEditClicked} onDelete={handleQuestionDelete} onCondition={onCondition} onComplete={onComplete} />
                    })}
                </List>
            </Paper>
            {user.roles.editor && <Dialog
                onClose={handleClose}
                aria-labelledby="question-add-title"
                open={open}
            >
                <QuestionAdd
                    handleSave={handleSave}
                    handleCancel={handleClose}
                    exclusions={exclusions}
                    editable={
                        true
                    }
                    // questions={questions}
                    details={selectedQuestionRef.current}
                />
            </Dialog>}
            {user.roles.editor && <Fab
                color="secondary"
                aria-label="add"
                className={classes.fab}
                onClick={handleAdd}
            >
                <Add />
            </Fab>}
            {endCondition &&
                <Dialog
                    open={endConditionOpen}
                    onClose={dialogClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{endCondition.title ? endCondition.title : ''}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {endCondition.text}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={dialogClose} color="primary">
                            Ok
                        </Button>
                    </DialogActions>
                </Dialog>
            }
        </div>
    );
}
