import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Navbar from "../components/Navbar";
import { BrandContext } from "../context/BrandContext/context";
import Sidebar from "../components/Sidebar";
import PortalContent from "../components/PortalContent";
import Exercises from "../containers/Exercises";
import { Route } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: "100vh",
  },
}));

export default function PortalView() {
  const { brand } = useContext(BrandContext);
  const classes = useStyles();
  const [drawerOpen, seDrawertOpen] = React.useState(brand.sidebar.open);

  const handleDrawer = () => {
    seDrawertOpen(!drawerOpen);
  };

  return (
    <div className={classes.root}>
      {!brand.navbar.hidden && <Navbar handleDrawer={handleDrawer} />}
      {!brand.sidebar.hidden && <Sidebar open={drawerOpen} setOpen={handleDrawer} />}
      <PortalContent />
    </div>
  );
}
