import React, { createRef, useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import { DriveEta, Visibility } from "@material-ui/icons";
import {
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  TextareaAutosize,
  TextField,
} from "@material-ui/core";
import Fab from "@material-ui/core/Fab";
import Edit from "@material-ui/icons/Edit";
import Autocomplete, { createFilterOptions } from "@material-ui/lab/Autocomplete";
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { useHistory } from "react-router-dom";
import { ErrorMessage, Formik } from "formik";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  paperContainer: {
    width: "95%",
    margin: "auto",
  },
  paperContainerHeading: {
    width: "100%",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    padding: "5pt",
  },
  input: {
    display: "none",
  },
  media: {
    maxWidth: "250px",
    maxHeight: "250px",
  },
  content: {
    width: "100%",
    padding: "5pt",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "48%",
    minWidth: "200px",
  },
  button: {
    margin: theme.spacing(1),
  },
  buttonContainer: {
    width: "100%"
  },
  buttonLeft: {
    float: 'left'
  },
  buttonRight: {
    float: 'right'
  },
  searchButtom: {
    width: '100%',
    borderTop: '1px solid black',
    textAlign: 'center'
  }
}));

function ClientAdd(props) {
  const classes = useStyles();
  const [details, setDetails] = useState(
    props.details ? {
      id: props.details.id ? props.details.id : undefined,
      name: props.details.name ? props.details.name : '',
      taxNumber: props.details.taxNumber ? props.details.taxNumber : '',
      numberUsers: props.details.numberUsers ? props.details.numberUsers : undefined,
      members: props.details.members ? props.details.members : [],
      email: props.details.email ? props.details.email : ''
    } : {
      name: '',
      taxNumber: '',
      numberUsers: undefined,
      members: [],
      email: ''
    }
  );
  const [errors, setErrors] = useState({})

  const handleSave = (e) => {
    e.preventDefault();
    console.log('details', details)
    if (props.handleSave && validate(details)) props.handleSave(details)
  }

  const handleCancel = (event) => {
    if (props.handleCancel) props.handleCancel();
  }

  const validate = (values) => {
    const errs = {};
    if (!values.email) {
      errs.email = 'Invalid email';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errs.email = 'Invalid email address';
    } else {
      delete errs.email
    }

    if (!values.name) errs.name = 'Invalid name'
    else delete errs.name
    if (!values.numberUsers || values.numberUsers <= 0) errs.numberUsers = 'Invalid number of users'
    else delete errs.numberUsers

    setErrors(errs)
    return Object.keys(errs).length === 0;
  }


  const validateField = (field, values) => {
    const errs = {};
    if (!values[field]) {
      errs.email = 'Invalid ' + field;
    } else if (field === 'email' && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errs[field] = 'Invalid email address';
    } else {
      delete errs[field]
    }

    // if (!values.name) errs.name = 'Invalid name'
    // else delete errs.name
    // if (!values.numberUsers || values.numberUsers <= 0) errs.numberUsers = 'Invalid number of users'
    // else delete errs.numberUsers

    setErrors(errs)
    return Object.keys(errs).length === 0;
  }

  return (
    <div className={classes.root}>
      <form onSubmit={handleSave}>
        <Typography variant="h6" className={classes.paperContainerHeading} >
          {details.id ? "Update Client" : "Add Client"}
        </Typography>
        <div className={classes.content}>
          <div>
            <TextField
              id="name"
              name="name"
              label="Name"
              disabled={!props.editable}
              placeholder="Client Name"
              helperText={errors.name ? errors.name : "Enter the client name"}
              error={errors.name}
              margin="normal"
              variant="outlined"
              fullWidth
              required
              style={{ width: "97%", margin: "8pt" }}
              className={classes.textField}
              defaultValue={details.name}
              onChange={(e) => {
                let d = details
                d.name = e.target.value
                if (validateField('name', d)) setDetails(d)
              }}
            />
            <TextField
              id="taxnumber"
              name="taxNumber"
              label="Tax Number"
              disabled={!props.editable}
              placeholder="Tax number"
              helperText={errors.taxNumber ? errors.taxNumber : "Enter the tax number"}
              error={errors.taxNumber}
              margin="normal"
              variant="outlined"
              fullWidth
              style={{ width: "97%", margin: "8pt" }}
              className={classes.textField}
              defaultValue={details.taxNumber}
              onChange={(e) => {
                let d = details
                d.taxNumber = e.target.value
                if (validateField('taxNumber', d)) setDetails(d)
              }}
            />
            <TextField
              id="numberUsers"
              name="numberUsers"
              label="Number of Users"
              disabled={!props.editable}
              placeholder="Number of users"
              helperText={errors.numberUsers ? errors.numberUsers : "Enter the number of users"}
              error={errors.numberUsers}
              margin="normal"
              variant="outlined"
              fullWidth
              type="number"
              min={1}
              required
              style={{ width: "97%", margin: "8pt" }}
              className={classes.textField}
              defaultValue={details.numberUsers}
              onChange={(e) => {
                let d = details
                d.numberUsers = e.target.value
                if (validateField('numberUsers', d)) setDetails(d)
              }}
            />
            <TextField
              id="email"
              email="email"
              label="User Email"
              disabled={!props.editable}
              placeholder="User Email"
              helperText={errors.email ? errors.email : "Enter the email address for the administrator user that manages the account"}
              error={errors.email}
              margin="normal"
              variant="outlined"
              fullWidth
              required
              type="email"
              style={{ width: "97%", margin: "8pt" }}
              className={classes.textField}
              defaultValue={details.email}
              onChange={(e) => {
                let d = details
                d.email = e.target.value
                if (validateField('email', d)) setDetails(d)
              }}
            />
          </div>
        </div>
        <div className={classes.buttonContainer}>
          <div className={classes.buttonLeft}>
            <Button className={classes.button} variant="contained" onClick={handleCancel}>Cancel</Button>
          </div>
          <div className={classes.buttonRight} >
            {props.editable && <Button className={classes.button} variant="contained" type="submit" color="primary">
              Save
            </Button>}
          </div>
        </div>
      </form>
    </div >
  );
}

export default ClientAdd;
