import React, { useContext, useEffect } from 'react';
import logo from './logo.svg';
import './App.css';
import PortalView from './views/PortalView';
import { Router, BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { MuiThemeProvider, createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { BrandContext } from './context/BrandContext/context'
import { createBrowserHistory } from 'history';
import { createTheme } from '@material-ui/core';
import WebsiteView from './views/WebsiteView';
import { firebaseApp, functions } from './services/firebase.config'
import { getAuth, onAuthStateChanged } from '@firebase/auth';
import { UserContext } from './context/UserContext/context';
import AuthController from './controllers/AuthController';
import { SET_USER } from './context/UserContext/types';
import DataController from './controllers/DataController';

function App() {
  const hist = createBrowserHistory()
  const { brand } = useContext(BrandContext)
  const { user, dispatchUser } = useContext(UserContext)


  const theme = createTheme({
    Sidebar: {
      color: 'black',
      textDecoration: 'none !important'
    },
    SidebarItem: {
      textDecoration: 'none !important'
    },
    BrandContainer: {
      textAlign: 'center',
    },
    palette: {
      primary: brand.color.primary,
      secondary: brand.color.secondary
    }
  });

  useEffect(() => {

    console.log('!!! firebaseApp', firebaseApp)

    addAuthObserver()

  }, [])

  const addAuthObserver = () => {
    onAuthStateChanged(getAuth(), async (usr) => {
      try {
        if (usr) {
          let claims = await AuthController.GetUserClaims(usr)
          let roles = claims.roles
          usr.clientId = claims.clientId
          let q = await AuthController.GetUserRequiredQuestionnaire(usr.uid, usr.accessToken)
          usr.questionnaires = q.data

          dispatchUser({
            type: SET_USER,
            payload: {
              isAuthenticated: usr && !usr.disabled ? true : false,
              isAuthorized: usr && !usr.disabled && roles ? true : false,
              isVerifying: false,
              details: usr,
              roles: roles,
              error: usr && (!usr || usr.disabled || !roles) ? 'Unauthorized' : null
            }
          })
        } else {
          dispatchUser({
            type: SET_USER,
            payload: {
              isAuthenticated: false,
              isAuthorized: false,
              isVerifying: false,
              details: false,
              roles: false,
              error: null
            }
          })
        }
      } catch (e) {
        console.log(e)
      }
    })
  }

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter history={createBrowserHistory()}>
        <Switch>
          {(user && user.isAuthenticated && user.isAuthorized && user.roles && !user.disabled) ?
            <Route path='/' theme={theme} component={PortalView} key="PortalView" /> :
            <Route path='/' theme={theme} component={WebsiteView} key="WebsiteView" />
          }
        </Switch>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
