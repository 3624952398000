import Utils from "../utils/Utils";
import { appFirebase, db, storage, functions } from "../services/firebase.config";
import { isArray, reject } from "lodash";
import { addDoc, arrayRemove, arrayUnion, collection, deleteDoc, doc, getDoc, getDocs, query, where, increment, serverTimestamp, updateDoc, writeBatch } from "@firebase/firestore";
import { getDownloadURL, ref, uploadBytesResumable, uploadString } from "@firebase/storage";
import AuthController from "./AuthController";
const axios = require('axios').default;

const DataController = {}

DataController.GetExercises = async (user) => {
    let results = []

    const querySnapshot = await getDocs(collection(db, "exercises"));
    querySnapshot.forEach((doc) => {
        console.log(`${doc.id}`, doc.data());
        results.push({
            id: doc.id,
            ...doc.data()
        })
    });

    return results
}

DataController.GetUserExercises = async (uid, token) => {
    if (!uid) throw "Invalid uid"
    if (!token) throw "Invalid Token"

    try {
        let headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
        let response = await axios.get(`${process.env.REACT_APP_API_URL}/users/${uid}/exercises`, { headers: headers })

        if (response.data) {
            return {
                success: true,
                data: response.data.data
            }
        }
        return {
            success: false
        }
    } catch (error) {
        throw error
    }
}

DataController.UploadExerciseMedia = async (data, onProgressCallback) => {
    return new Promise((resolve, reject) => {

        if (!data || !data.name || !data.id) throw "Invalid Exericse"
        if (!data.media) throw "Invalid Exericse"
        let metadata = {
            id: data.id,
            name: data.name.replace(' ', '').toLowerCase(),
            contentType: data.media.type,
            cacheControl: 'public,max-age=604800',
        }

        let storageRef

        if (data.media.type === 'video/mp4')
            storageRef = ref(storage, `exercises/mpeg/${metadata.id}_${metadata.name}.mp4`)
        else if (data.media.type === 'video/mpeg')
            storageRef = ref(storage, `exercises/mpeg/${metadata.id}_${metadata.name}.mpeg`)
        else if (data.media.type === 'video/avi')
            storageRef = ref(storage, `exercises/mpeg/${metadata.id}_${metadata.name}.avi`)
        else if (data.media.type === 'image/gif')
            storageRef = ref(storage, `exercises/gifs/${metadata.id}_${metadata.name}.gif`)
        else if (data.media.type === 'image/jpeg' || data.media.type === 'image/jpg')
            storageRef = ref(storage, `thumbnails/${metadata.id}_${metadata.name}.jpeg`)
        else if (data.media.type === 'image/png')
            storageRef = ref(storage, `thumbnails/${metadata.id}_${metadata.name}.png`)
        else
            throw 'Invalid Filetype only support: mp4, mpeg,avi, jpeg, png'

        if (typeof data.media.data === 'string') {
            uploadString(storageRef, data.media.data, 'data_url').then(snapshot => {
                getDownloadURL(snapshot.ref).then((downloadURL) => {
                    console.log('File available at', downloadURL);
                    resolve(downloadURL)
                });
            }).catch(reject)
        } else {
            const uploadTask = uploadBytesResumable(storageRef, data.media.data, metadata);
            uploadTask.on('state_changed',
                (snapshot) => {
                    // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    if (onProgressCallback) onProgressCallback(progress)
                },
                (error) => {
                    reject(error)
                },
                () => {
                    // Upload completed successfully, now we can get the download URL
                    getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                        console.log('File available at', downloadURL);
                        resolve(downloadURL)
                    });
                }
            );
        }
    })
}

DataController.GetExerciseMedia = async (exercise) => {
    if (!exercise) throw "Invalid Exericse"
    if (!exercise.media) throw "Invalid Exericse"
}

DataController.AddExercise = async (exercise) => {
    if (!exercise) throw "Invalid Exericse"
    if (!exercise.name) throw "Invalid Exericse Name"
    try {
        let newExercise = Object.fromEntries(Object.entries(exercise).filter(([_, v]) => v != null && ((Array.isArray(v) && v.length > 0) || !Array.isArray(v))));
        // delete newExercise.media
        newExercise.media = ""
        newExercise.thumbnail = ""
        newExercise.created_at = serverTimestamp()
        // const docRef = await db.collection("exercises").addDoc(exercise);

        const docRef = await addDoc(collection(db, "exercises"), newExercise);
        newExercise.id = docRef.id

        return newExercise
    } catch (e) {
        console.error(e)
        throw e
    }
}

DataController.AddExercises = async (exercises) => {
    if (!exercises || exercises.length === 0) throw "Invalid Exercises"

    const batch = writeBatch(db);

    exercises.forEach(exercise => {
        if (exercise.name) {
            exercise.created_at = serverTimestamp()
            const docRef = doc(db, "exercises");
            batch.set(docRef, exercise);
        }
    });

    await batch.commit();

    return exercises
}

DataController.UpdateExercise = async (exercise) => {
    if (!exercise) throw "Invalid Exericse"
    if (!exercise.id) throw "Invalid Exericse"
    if (!exercise.name) throw "Invalid Exericse Name"

    exercise.last_updated_at = serverTimestamp()
    const docRef = await doc(db, "exercises", exercise.id);
    await updateDoc(docRef, {
        ...exercise
    });

    return exercise
}

DataController.DeleteExercise = async (exercise) => {
    if (!exercise) throw "Invalid Exericse"
    if (!exercise.id) throw "Invalid Exericse"
    if (!exercise.name) throw "Invalid Exericse Name"

    await deleteDoc(doc(db, "exercises", exercise.id));

}

DataController.GetTags = async () => {
    let results = []

    const querySnapshot = await getDocs(collection(db, "tags"));
    querySnapshot.forEach((doc) => {
        console.log(`${doc.id}`, doc.data());
        results.push({
            id: doc.id,
            ...doc.data()
        })
    });

    return results
}

DataController.AddTag = async (tag) => {
    if (!tag) throw "Invalid Tag"
    if (!tag.name) throw "Invalid Tag Name"

    tag.created_at = serverTimestamp()
    // const docRef = await db.collection("tags").add(tag);
    const docRef = await addDoc(collection(db, "tags"), tag);
    tag.id = docRef.id

    return tag
}

DataController.AddTags = async (tags) => {
    if (!tags || tags.length === 0) return

    const batch = writeBatch(db);
    tags.forEach(tag => {
        tag.created_at = serverTimestamp()
        const docRef = doc(db, "tags");
        batch.set(docRef, tag);
    })
    await batch.commit();

    return tags
}

DataController.UpdateTag = async (tag) => {
    if (!tag) throw "Invalid Tag"
    if (!tag.id) throw "Invalid Tag"
    if (!tag.name) throw "Invalid Tag Name"

    tag.last_updated_at = serverTimestamp()
    const docRef = await doc(db, "tags", tag.id);
    await updateDoc(docRef, {
        ...tag
    });

    return tag
}

DataController.DeleteTag = async (tag) => {
    if (!tag) throw "Invalid Tag"
    if (!tag.id) throw "Invalid Tag"
    if (!tag.name) throw "Invalid Tag Name"

    await deleteDoc(doc(db, "tags", tag.id));
}

DataController.GetClients = async (user) => {
    if (!user || !user.details.clientId || !user.roles) throw "Invalid User"
    let results = []
    if (user.roles.admin) {
        const querySnapshot = await getDocs(collection(db, "clients"));
        querySnapshot.forEach((doc) => {
            results.push({
                id: doc.id,
                ...doc.data()
            })
        });

    }
    else {
        const querySnapshot = await getDoc(doc(db, "clients", user.details.clientId))
        results.push({
            id: querySnapshot.id,
            ...querySnapshot.data()
        })
    }
    return results
}

DataController.GetClient = async (id) => {
    if (!id) return
    let docRef = doc(db, 'clients', id)
    let docSnap = await getDoc(docRef);

    if (docSnap.exists())
        return docSnap.data()

    return
}
// DataController.RegisterClient = async (client) => {
//     if (!client) throw "Invalid Client"
//     if (!client.name) throw "Invalid Client Name"
//     if (!client.contactNumber) throw "Invalid Contact Number"
//     if (!client.user || !client.user.email || !client.user.firstName || !client.user.password) throw "Invalid contact person"

//     try {
//         let clientId = await functions.httpsCallable("createClient")(client)

//         if(!clientId) throw "Invalid client"

//         let newUser = {
//             displayName: client.user.firstName + ' ' + client.user.lastName?? '',
//             email: client.user,
//             password: client.user.password,
//             role: {
//                 client: true,
//                 owner: true,
//                 user: true
//             }
//         }
//         let clientUserId = await functions.httpsCallable("createUser")(newUser)

//         return clientId
//     } catch (error) {
//         throw error
//     }
// }

DataController.AddClients = async (clients) => {
    if (!clients || clients.length === 0) return

    const batch = writeBatch(db);
    clients.forEach(client => {
        if (client && client.name && client.number) {
            client.created_at = serverTimestamp()
            const docRef = doc(db, "clients");
            batch.set(docRef, client);
        }
        // client.id = Utils.getRandomInt(10000)
    })
    await batch.commit();

    return clients
}

DataController.AddClient = async (client) => {
    if (!client) throw "Invalid Client"
    if (!client.name) throw "Invalid Client Name"
    // if (!client.numberUsers) throw "Invalid Client Number"
    if (!client.email) throw "Invalid admin user"

    client.created_at = serverTimestamp()
    // const docRef = await db.collection("clients").add(client);
    const docRef = await addDoc(collection(db, "clients"), client);
    client.id = docRef.id

    return client
}

DataController.UpdateClient = async (client) => {
    if (!client) throw "Invalid Client"
    if (!client.id) throw "Invalid Client"
    if (!client.name) throw "Invalid Client Name"
    // if (!client.numberUsers) throw "Invalid number of users"
    // if (!client.email) throw "Invalid admin user"

    // if (!client.numMembersLeft) client.numMembersLeft = client.numberUsers

    client.last_updated_at = serverTimestamp()
    const docRef = await doc(db, "clients", client.id);
    await updateDoc(docRef, {
        ...client
    });

    return client
}

DataController.AddClientMember = async (member, client) => {
    if (!member) throw "Invalid Client"
    if (!member.id) throw "Invalid Client"
    if (!member.name) throw "Invalid Client Name"
    if (!member.client || !member.client.id) throw "Invalid client"

    // const docRef = await doc(db, "clients", member.client.id);
    // if (!client.members) {
    //     await updateDoc(docRef, {
    //         members: [member],
    //         // numMembersLeft: increment(1)
    //     });
    // } else {
    //     await updateDoc(docRef, {
    //         members: arrayUnion([member]),
    //         // numMembersLeft: increment(1)
    //     });
    // }

    return member
}

DataController.RemoveClientMember = async (member) => {
    if (!member) throw "Invalid Client"
    if (!member.id) throw "Invalid Client"
    if (!member.clientId) throw "Invalid client"

    const docRef = await doc(db, "clients", member.clientId);
    await updateDoc(docRef, {
        members: arrayRemove([member]),
        // numMembersLeft: increment(-1)
    });


    return member
}

DataController.DeleteClient = async (client) => {
    if (!client) throw "Invalid Client"
    if (!client.id) throw "Invalid Client"
    if (!client.name) throw "Invalid Client Name"

    await deleteDoc(doc(db, "clients", client.id));

}

DataController.GetExclusions = async () => {
    let results = []

    const querySnapshot = await getDocs(collection(db, "exclusions"));
    querySnapshot.forEach((doc) => {
        console.log(`${doc.id}`, doc.data());
        results.push({
            id: doc.id,
            ...doc.data()
        })
    });

    return results
}

DataController.AddExclusion = async (exclusion) => {
    if (!exclusion) throw "Invalid Exclusion"
    if (!exclusion.name) throw "Invalid Exclusion Name"

    exclusion.created_at = serverTimestamp()
    const docRef = await addDoc(collection(db, "exclusions"), exclusion);
    // const docRef = await db.collection("exclusions").add(exclusion);
    exclusion.id = docRef.id

    return exclusion
}

DataController.AddExclusions = async (exclusions) => {
    if (!exclusions || exclusions.length === 0) return

    const batch = writeBatch(db);
    exclusions.forEach(exclusion => {
        exclusion.created_at = serverTimestamp()
        const docRef = doc(db, "exclusions");
        batch.set(docRef, exclusion);
    })
    await batch.commit();

    return exclusions
}

DataController.UpdateExclusion = async (exclusion) => {
    if (!exclusion) throw "Invalid Exericse"
    if (!exclusion.id) throw "Invalid Exericse"
    if (!exclusion.name) throw "Invalid Exericse Name"

    exclusion.last_updated_at = serverTimestamp()
    const docRef = await doc(db, "exclusions", exclusion.id);
    await updateDoc(docRef, {
        ...exclusion
    });

    return exclusion
}

DataController.DeleteExclusion = async (exclusion) => {
    if (!exclusion) throw "Invalid Exclusion"
    if (!exclusion.id) throw "Invalid Exclusion"
    if (!exclusion.name) throw "Invalid Exclusion Name"

    await deleteDoc(doc(db, "exclusions", exclusion.id));
}

DataController.GetMembers = async (user) => {
    if (!user || !user.details.clientId || !user.roles) throw "Invalid User"
    let results = []
    let queryRef

    if (user.roles.admin)
        queryRef = collection(db, "members")
    else
        queryRef = query(collection(db, "members"), where("client.id", "==", user.details.clientId))

    let querySnapshot = await getDocs(queryRef)
    querySnapshot.forEach((doc) => {
        if (doc.data() && !doc.data().always_hide) {
            console.log(`!! ${doc.id}`, doc.data());
            results.push({
                id: doc.id,
                ...doc.data()
            })
        }
    });

    return results
}

DataController.AddMember = async (member) => {
    if (!member) throw "Invalid Member"
    if (!member.name) throw "Invalid Member Name"
    if (!member.client || !member.client.id) throw "Invalid Member Client"

    // const querySnapshot = await getDoc(db, "clients", member.client.id);
    const querySnapshot = await getDoc(doc(db, "clients", member.client.id));
    if (!querySnapshot.exists()) throw 'Client does not exist'
    let client = querySnapshot.data()
    // if ((!client.billing.product.max_users && client.members.length < client.billing.product.max_users ) || client.numMembrsLeft <= 0)
    if ((!client.adminClient) && (!client.billing || client.billing.product)) {
        throw 'You do not have an active subscription. Please update your subscription.'
    } else if ((client.adminClient) || (!client.members || client.members.length === 0) || (client.members && client.members.length > 0 && client.members.length < +client.billing.product.max_users)) {
        member.created_at = serverTimestamp()
        // const docRef = await db.collection("members").add(member);
        const docRef = await addDoc(collection(db, "members"), member);
        member.id = docRef.id
    } else {
        throw 'Your current plan does not have space left, please upgrade your subscription.'
    }

    // DataController.AddClientMember(member, client)

    return member
}

DataController.inviteMember = async (member, token) => {
    if (!member) throw "Invalid Member"
    if (!member.name) throw "Invalid Member Name"
    if (!member.client || !member.client.id) throw "Invalid Member Client"
    if (!member.id) throw "Invalid Member id"

    try {
        let data = {
            displayName: member.name,
            email: member.email,
            clientId: member.client.id,
            memberId: member.id,
            roles: member.roles,
            redirect: `${process.env.REACT_APP_REDIRECT_URL}/__/invite/accept`
        }
        let headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
        let response = await axios.post(`${process.env.REACT_APP_API_URL}/users/invite`, data, { headers: headers })

        if (response.data) {
            return {
                success: true,
                data: response.data
            }
        }
        return {
            success: false
        }
    } catch (error) {
        throw error
    }
}


DataController.reInviteMember = async (member, token) => {
    if (!member) throw "Invalid Member"
    if (!member.name) throw "Invalid Member Name"
    if (!member.client || !member.client.id) throw "Invalid Member Client"
    if (!member.id || !member.uid) throw "Invalid Member id"
    // if (!member.uid || !member.id) throw "Invalid Member uid"

    try {
        let data = {
            displayName: member.name,
            email: member.email,
            clientId: member.client.id,
            memberId: member.id,
            roles: member.roles,
            redirect: `${process.env.REACT_APP_REDIRECT_URL}/__/invite/accept/${member.uid}`
        }
        let headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
        let response = await axios.post(`${process.env.REACT_APP_API_URL}/users/invite/re`, data, { headers: headers })

        if (response.data) {
            return {
                success: true,
                data: response.data
            }
        }
        return {
            success: false
        }
    } catch (error) {
        throw error
    }
}

DataController.UpdateMember = async (member, token) => {
    if (!member) throw "Invalid Team Member"
    if (!member.id) throw "Invalid Team Member"
    if (!member.uid) throw "Invalid Team Member"
    if (!member.roles) throw "Invalid Team Member Name"

    try {
        let newMember = {
            uid: member.uid,
            memberId: member.id,
            roles: member.roles
        }
        await AuthController.UpdateUser(newMember, token)

    } catch (err) {
        throw err
    }

    return member
}

DataController.DeleteMember = async (member, token) => {
    if (!member) throw "Invalid Team Member"
    if (!member.id) throw "Invalid Team Member"
    if (!member.uid) throw "Invalid Team Member"
    if (member.email === 'administrator@ergomove.co.za') throw "Cannot remove this user"

    try {
        let headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
        let response = await axios.delete(`${process.env.REACT_APP_API_URL}/users/${member.uid}`, { headers: headers })

        if (response.data) return {
            success: true,
        }

        await deleteDoc(doc(db, "members", member.id))

    } catch (error) {
        throw error
    }
}


// DataController.AddQuestion = async (question) => {
//     if (!question) throw "Invalid Question"
//     if (!question.id) throw "Invalid Question"
//     if (!question.question) throw "Invalid Question"
//     if (!question.questionType) throw "Invalid Question Type"

//     // const docRef = await addDoc(collection(db, "members", question.questionType), question);
//     // question.id = docRef.id

//     return question
// }

// DataController.UpdateQuestion = async (question) => {
//     if (!question) throw "Invalid Question"
//     if (!question.id) throw "Invalid Question"
//     if (!question.name) throw "Invalid Question"

//     return question
// }

// DataController.DeleteQuestion = async (question) => {
//     if (!question) throw "Invalid Question"
//     if (!question.id) throw "Invalid Question"

// }

DataController.GetRoles = async (user) => {
    if (!user || !user.details.clientId || !user.roles) throw "Invalid User"
    let results = []

    const querySnapshot = await getDocs(collection(db, "roles"));
    querySnapshot.forEach((doc) => {
        console.log(`${doc.id}`, doc.data());
        results.push({
            name: doc.id,
            ...doc.data()
        })
    });

    if (user && user.roles && user.roles.sysadmin) return results
    else if (user && user.roles && user.roles.admin) return results.filter(r => !r.always_hide)
    else return results.filter(r => !r.hide)
}


DataController.GetExercises = async () => {
    let results = []

    const querySnapshot = await getDocs(collection(db, "exercises"));
    querySnapshot.forEach((doc) => {
        console.log(`${doc.id}`, doc.data());
        results.push({
            id: doc.id,
            ...doc.data()
        })
    });

    return results
}

DataController.GetQuestionTypes = async () => {
    let results = []

    const querySnapshot = await getDocs(collection(db, "questionnaires"));
    querySnapshot.forEach((doc) => {
        console.log(`${doc.id}`);
        results.push(
            doc.id
        )
    });

    return results
}

DataController.AddQuestionType = async (type) => {
    if (!type) throw "Invalid Question Type"

    type.created_at = serverTimestamp()
    // const docRef = await db.collection("questionnaire").add(type);
    const docRef = await addDoc(collection(db, "questionnaires", type), "questions");
    type.id = docRef.id

    return type
}

DataController.UpdateQuestionType = async (type) => {
    if (!type) throw "Invalid Question Type"

    type.last_updated_at = serverTimestamp()
    const docRef = await doc(db, "questionnaires", type);
    await updateDoc(docRef, {
        ...type
    });

    return type
}

DataController.DeleteQuestionType = async (type) => {
    if (!type) throw "Invalid question type"

    await deleteDoc(doc(db, "questionnaires", type));

}

DataController.GetQuestions = async (questionType) => {
    if (!questionType) throw 'Invalid or no question type'
    let results = []

    const querySnapshot = await getDocs(collection(db, "questionnaires", questionType, "questions"));
    querySnapshot.forEach((doc) => {
        console.log(`${doc.id}`);
        results.push({
            id: doc.id,
            question_type: questionType,
            ...doc.data()
        })
    });

    return results
}

DataController.AddQuestion = async (question) => {
    if (!question || !question.question || !question.type || !question.answers) throw "Invalid or not question"
    if (!question.question_type) throw "Invalid Question Type"

    question.created_at = serverTimestamp()
    const docRef = await addDoc(collection(db, "questionnaires", question.question_type, "questions"), question);
    question.id = docRef.id

    return question
}

DataController.UpdateQuestion = async (question) => {
    if (!question || !question.id || !question.question || !question.type || !question.answers) throw "Invalid or not question"
    if (!question.question_type) throw "Invalid Question Type"

    question.last_updated_at = serverTimestamp()
    const docRef = await doc(db, "questionnaires", question.question_type, "questions", question.id);
    await updateDoc(docRef, {
        ...question
    });

    return question
}

DataController.DeleteQuestion = async (question) => {
    if (!question || !question.id || !question.question || !question.type || !question.answers) throw "Invalid or not question"
    if (!question.question_type) throw "Invalid Question Type"

    await deleteDoc(doc(db, "questionnaires", question.question_type, "questions", question.id));

}

export default DataController;