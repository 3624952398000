import React, { useContext, useEffect, useRef, useState } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import _, { set } from 'lodash';
import Paper from "@material-ui/core/Paper";
import { UserContext } from "../context/UserContext/context";
import { DataContext } from "../context/DataContext/context";
import Utils from "../utils/Utils";
import DataController from "../controllers/DataController"
import { Button, Card, CardActionArea, CardActions, CardContent, CardMedia, Checkbox, Dialog, Divider, Fab, Grid, IconButton, List, ListItem, ListItemIcon, ListItemText, TextField, Typography } from "@material-ui/core";
import { AccountCircleOutlined, Add } from "@material-ui/icons";
import TagListItem from "../components/TagListItem";
import TagAdd from "../components/TagAdd";
import PayFastForm from "../components/PayFastForm";
import AuthController from "../controllers/AuthController";
import { Alert } from "@material-ui/lab";
import { SET_USER } from "../context/UserContext/types";
import { NavLink } from "react-router-dom";
import PaymentController from "../controllers/PaymentController";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        margin: 'auto',
        justifyContent: 'center',
        flexWrap: 'wrap',
        '& > *': {
            padding: theme.spacing(2),
        },
    },
    cardRoot: {
        width: 500,
        margin: theme.spacing(1),
        height: '100%'
    },
    media: {
        height: 140,
    },
    userImage: {
        width: 150
    },
    heading: {
        justifyContent: 'center',
        textAlign: 'center'
    },
    content: {
        paddingTop: theme.spacing(1)
    },
    actionArea: {
        paddingTop: theme.spacing(1)
    },
    textField: {
        marginTop: theme.spacing(1),
    },
    pricingButton: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText
    }
}));

export default function Profile(props) {
    const classes = useStyles();
    const { user, dispatchUser } = useContext(UserContext)
    const [details, setDetails] = useState({ })
    const [client, setClient] = useState()
    const [signature, setSignature] = useState(false)
    const [feedback, setFeedback] = useState(false)
    const [loading, setLoading] = useState(false)
    const [resetConfirmation, setResetConfirmation] = useState(false)

    useState(async () => {
        if (user.roles && user.roles.viewer) {
            let c = await DataController.GetClient(user.details.clientId)
            let d = JSON.parse(JSON.stringify(user.details))
            d.client = c
            setDetails(d)
            setClient(c)
        }

        if (user.roles && user.roles.user && !user.roles.viewer) {
            setDetails(JSON.parse(JSON.stringify(user.details)))
        }
    }, [])

    useState(() => {
        if (!signature && user.roles && user.roles.subscription && (client && client.billing && !client.billing.active)) {
            getSignature()
        }
    }, [client])
    // useState(() => {
    //     setUsername(details.displayName)
    //     setEm(details.displayName)
    // }, [details])

    const getSignature = async () => {
        try {
            if (!user || !user.details || !user.details.clientId) return
            let productResponse = await AuthController.ProductPaymentSignature({ id: user.details.clientId }, user.details.accessToken)
            // setProduct(productResponse.data.product)
            setSignature(productResponse.data.signature)
        } catch (e) {
            console.error('s error', e)
        }
    }

    const onResetPassword = async () => {
        try {
            if (details.email) {
                await AuthController.ResetPassword(details.email)
                handleFeedback('user', 'success', 'Reset password email has been send')
            }
            else {
                handleFeedback(false)
            }
        } catch (err) {
            handleFeedback('user', 'error', 'Oops, something went wrong')
            console.error(err)
        }
    };

    const handleFeedback = (type = false, severity = false, message = false) => {
        if (!type) setFeedback(false)
        else setFeedback({
            type: type,
            severity: severity,
            message: message
        })
    }

    const onCancelSubscription = async () => {
        console.log('canceled')
        try {
            await PaymentController.CancelSubscription(user.details.clientId, user.details.accessToken)
            let c = client
            c.billing.active = false
            setClient(c)
            handleFeedback('billing', 'success', 'Subscription Successfully Cancelled')
        } catch (err) {
            handleFeedback('billing', 'error', 'Oops, something went wrong')
            console.error(err)
        }
    }

    const onSaveUser = async () => {
        try {

            await AuthController.UpdateUser({
                displayName: details.displayName,
                uid: details.uid
            }, user.details.accessToken)
            handleFeedback('user', 'success', 'Details Updated')

            dispatchUser({
                type: SET_USER,
                payload: {
                    details: details
                }
            })

        } catch (err) {
            handleFeedback('user', 'error', 'Oops, something went wrong')
            console.error(err)
        }
    }

    const onSaveClient = async () => {
        try {
            let c = {
                ...details.client,
                id: user.details.clientId,
            }
            await DataController.UpdateClient(c)
            handleFeedback('client', 'success', 'Details Updated')

        } catch (err) {
            handleFeedback('client', 'error', 'Oops, something went wrong')
            console.error(err)
        }
    }

    const updateSubscription = async () => {
        try {

        } catch (err) {
            handleFeedback('user', 'error', 'Oops, something went wrong')
            console.error(err)
        }
    }

    const onCancel = () => {
        // setDetails(Object.assign({ }, user.details))
        let d = JSON.parse(JSON.stringify(user.details))
        d.client = client
        setDetails(d)
    }

    return (
        <div className={classes.root}>
            <Paper elevation={2} className={classes.cardRoot}>
                {/* {feedback && <Alert severity="success"> {feedback} </Alert>} */}
                {feedback && feedback.type === 'user' && <Alert severity={feedback.severity}> {feedback.message} </Alert>}
                <Typography variant="h6"> Account Information</Typography>
                <Divider />
                <br />
                <div className={classes.heading}>
                    <img src={details.photoURL ? details.photoURL : 'user.png'} alt="" className={classes.userImage} />
                    <br />
                    <Typography variant='body'>{user.details.email}</Typography>
                </div>
                <div className={classes.content}>
                    <TextField
                        id="userrname"
                        label="Username"
                        // autoFocus
                        // disabled={!props.editable}
                        placeholder="Username"
                        variant="outlined"
                        className={classes.textField}
                        fullWidth
                        value={details.displayName || ''}
                        defaultValue={details.displayName}
                        onChange={(e) => {
                            setDetails({
                                ...details,
                                displayName: e.target.value
                            })
                        }}

                    />

                    <Button size="small" color="default" onClick={onResetPassword}>
                        Reset password
                    </Button>

                </div>
                <div className={classes.actionArea}>
                    <Button size="small" color="secondary" onClick={onCancel}>
                        cancel
                    </Button>
                    <Button size="small" color="primary" onClick={onSaveUser}>
                        Save
                    </Button>
                </div>
            </Paper>
            {client && user.roles.viewer && <>
                <Paper elevation={2} className={classes.cardRoot}>
                    {feedback && feedback.type === 'client' && <Alert severity={feedback.severity}> {feedback.message} </Alert>}
                    <Typography variant="h6"> Business Information </Typography>
                    <Divider />
                    <div className={classes.content}>
                        <TextField
                            disabled={loading || !user.roles.editor}
                            variant="outlined"
                            className={classes.textField}
                            required
                            fullWidth
                            id="businessNumber"
                            label="Business Name"
                            name="businessName"
                            value={details.client.name || ''}
                            defaultValue={details.client.name}
                            onChange={(e) => {
                                setDetails({
                                    ...details,
                                    client: {
                                        ...details.client,
                                        name: e.target.value
                                    }
                                })
                            }}
                        />
                        <TextField
                            disabled={loading || !user.roles.editor}
                            variant="outlined"
                            className={classes.textField}
                            required
                            fullWidth
                            id="contactNumber"
                            label="Contact number"
                            name="contactNumber"
                            type="number"
                            value={details.client.contactNumber || ''}
                            defaultValue={details.client.contactNumber}
                            onChange={(e) => {
                                setDetails({
                                    ...details,
                                    client: {
                                        ...details.client,
                                        user: {
                                            ...details.client.user,
                                            contactNumber: e.target.value
                                        }
                                    }
                                })
                            }}
                        />
                        <TextField
                            disabled={loading || !user.roles.editor}
                            variant="outlined"
                            className={classes.textField}
                            fullWidth
                            id="vatNumber"
                            label="VAT number"
                            name="vatNumber"
                            value={details.client.vatNumber || ''}
                            defaultValue={details.client.vatNumber}
                            onChange={(e) => {
                                setDetails({
                                    ...details,
                                    client: {
                                        ...details.client,
                                        vatNumber: e.target.value
                                    }
                                })
                            }}
                        />
                        <Typography variant="h6"> Business Address</Typography>
                        <Divider />
                        <TextField
                            disabled={loading || !user.roles.editor}
                            variant="outlined"
                            className={classes.textField}
                            fullWidth
                            id="streetAddress"
                            label="Street Number, Street Name"
                            name="streetAddress"
                            value={details.client.physicalAddress ? details.client.physicalAddress.streetAddress : ''}
                            defaultValue={details.client.physicalAddress ? details.client.physicalAddress.streetAddress : ''}
                            onChange={(e) => {
                                let d = { ...details.client.physicalAddress }
                                if (!d) d = { }
                                d = { ...details.client.physicalAddress, streetAddress: e.target.value }
                                setDetails({
                                    ...details,
                                    client: {
                                        ...details.client,
                                        physicalAddress: {
                                            ...d
                                        }
                                    }
                                })
                            }}
                        />
                        <TextField
                            disabled={loading || !user.roles.editor}
                            variant="outlined"
                            className={classes.textField}
                            fullWidth
                            id="suburb"
                            label="Suburb"
                            name="suburb"
                            value={details.client.physicalAddress ? details.client.physicalAddress.suburb : ''}
                            defaultValue={details.client.physicalAddress ? details.client.physicalAddress.suburb : ''}
                            onChange={(e) => {
                                let d = { ...details.client.physicalAddress }
                                if (!d) d = { }
                                d = { ...details.client.physicalAddress, suburb: e.target.value }
                                setDetails({
                                    ...details,
                                    client: {
                                        ...details.client,
                                        physicalAddress: {
                                            ...d
                                        }
                                    }
                                })
                            }}
                        />
                        <TextField
                            disabled={loading || !user.roles.editor}
                            variant="outlined"
                            fullWidth
                            className={classes.textField}
                            id="city"
                            label="City"
                            name="city"
                            value={details.client.physicalAddress ? details.client.physicalAddress.city : ''}
                            defaultValue={details.client.physicalAddress ? details.client.physicalAddress.city : ''}
                            onChange={(e) => {
                                let d = { ...details.client.physicalAddress }
                                if (!d) d = { }
                                d = { ...details.client.physicalAddress, city: e.target.value }
                                setDetails({
                                    ...details,
                                    client: {
                                        ...details.client,
                                        physicalAddress: {
                                            ...d
                                        }
                                    }
                                })
                            }}
                        />
                        <TextField
                            disabled={loading || !user.roles.editor}
                            variant="outlined"
                            className={classes.textField}
                            fullWidth
                            id="province"
                            label="Province"
                            name="province"
                            value={details.client.physicalAddress ? details.client.physicalAddress.province : ''}
                            defaultValue={details.client.physicalAddress ? details.client.physicalAddress.province : ''}
                            onChange={(e) => {
                                let d = { ...details.client.physicalAddress }
                                if (!d) d = { }
                                d = { ...details.client.physicalAddress, province: e.target.value }
                                setDetails({
                                    ...details,
                                    client: {
                                        ...details.client,
                                        physicalAddress: {
                                            ...d
                                        }
                                    }
                                })
                            }}
                        />
                        <TextField
                            disabled={loading || !user.roles.editor}
                            variant="outlined"
                            className={classes.textField}
                            fullWidth
                            id="country"
                            label="Country"
                            name="country"
                            value={details.client.physicalAddress ? details.client.physicalAddress.country : ''}
                            defaultValue={details.client.physicalAddress ? details.client.physicalAddress.country : ''}
                            onChange={(e) => {
                                let d = { ...details.client.physicalAddress }
                                if (!d) d = { }
                                d = { ...details.client.physicalAddress, country: e.target.value }
                                setDetails({
                                    ...details,
                                    client: {
                                        ...details.client,
                                        physicalAddress: {
                                            ...d
                                        }
                                    }
                                })
                            }}
                        />
                    </div>
                    {user.roles.editor && <div className={classes.actionArea}>
                        <Button size="small" color="secondary" onClick={onCancel}>
                            cancel
                        </Button>
                        <Button size="small" color="primary" onClick={onSaveClient}>
                            Save
                        </Button>
                    </div>}
                </Paper>
                {user.roles.viewer && <>
                    <Paper elevation={2} className={classes.cardRoot}>
                        {feedback && feedback.type === 'billing' && <Alert severity={feedback.severity}> {feedback.message} </Alert>}
                        <div className={classes.content}>
                            <Typography variant="h6"> Billing Information</Typography>
                            <Divider />
                            {client.billing && client.billing.active ? <>
                                <Typography variant="body"> <b>Package: </b></Typography>
                                <Typography variant="body"> {client.billing.product.name} </Typography>
                                <br />
                                <Typography variant="body"> <b>Recurrence: </b></Typography>
                                <Typography variant="body"> {client.billing.product.recurring.title} {client.billing.product.recurring ? ` (${client.billing.product.recurring.type_title})` : ''}  </Typography>
                                <br />
                                <Typography variant="body"> <b>Status: </b></Typography>
                                <Typography variant="body"> {client.status} </Typography>
                                <br />
                                <Typography variant="body"> <b>Active: </b></Typography>
                                <Typography variant="body"> {client.billing.active ? 'Yes' : 'No'} </Typography>
                                <br />
                                <Typography variant="body"> <b>Payment Start Date: </b></Typography>
                                <Typography variant="body"> {Utils.FormatDate(client.billing.payment_date)} </Typography>
                                <br />
                                <Typography variant="body"> <b>Next Payment Date: </b></Typography>
                                <Typography variant="body"> {Utils.FormatDateNextMonth(client.billing.payment_date)} </Typography>
                                <br />
                                {user.roles.subscription && <>{client.billing.active ?
                                    <Button size="small" color="secondary" onClick={onCancelSubscription}>
                                        Cancel Subscription
                                    </Button>
                                    :
                                    // <PayFastForm
                                    //     signature={signature}
                                    //     amount={client.billing.product.amount ? client.billing.product.amount : null}
                                    //     clientId={user.details.clientId}
                                    //     successRedirectURL={'https://273f-102-219-119-44.ngrok.io/login'}
                                    //     notifyRedirectURL={'https://273f-102-219-119-44.ngrok.io/pricing'}
                                    //     cancelRedirectURL={'https://273f-102-219-119-44.ngrok.io/pricing'} />
                                    <Button  variant="contained" component={NavLink} color={'secondary'} to="/pricing" >Choose a Package</Button>
                                }
                                </>}
                            </> : <>
                                <Typography variant="body"> You currently do not have an active subscription.</Typography>
                                {user.roles.subscription && <>
                                    <br />
                                    <br />
                                    <Button  variant="contained" component={NavLink} color={'secondary'} to="/pricing" >Choose a Package</Button>
                                </>}
                            </>}
                        </div>
                    </Paper>
                </>}
            </>}
        </div>
    );
}
