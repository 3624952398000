import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import {
  useTheme,
} from "@material-ui/core";
import { UserContext } from "../context/UserContext/context";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexGrow: 1,
  },
  details: {
    display: "flex",
    flexDirection: "column",
  },
  content: {
    flex: "1 0 auto",
  },
  cover: {
    width: '20%',
    minWidth: '100px'
  },
  tags: {
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.spacing(2),
  },
  controls: {
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(2),
  },
  tagHeading: {
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.spacing(2),
  },
  TagsTitle: {
    paddingTop: "10pt",
  },
  chip: {
    position: 'absolute',
    borderRadius: '0pt',
    backgroundColor: '#fff'
  },
  tagChip: {
    borderRadius: '0pt',
    margin: '1px'
  }
}));

function ExerciseListItem(props) {
  const classes = useStyles();
  const { user } = useContext(UserContext)
  const theme = useTheme();

  const handleClick = (e) => {
    if (props.onClick) props.onClick(props.exercise);
  }
  const handleCopy = (e) => {
    if (props.handleCopy) props.handleCopy(props.exercise);
  }

  return (
    <Card className={classes.root}>
      <CardMedia
        className={classes.cover}
        image={props.exercise.thumbnail ? props.exercise.thumbnail : 'placeholder.jpg'}
        title={props.exercise.name}
      />
      <div className={classes.details}>
        <CardContent className={classes.content}>
          <Typography variant="h5">{props.exercise.name}</Typography>
          {/* <Typography variant="subtitle1" color="textSecondary">
            {props.exercise.subtitle}
          </Typography> */}
          <Typography variant="body2" color="textSecondary" component="p">
            {props.exercise.description}
          </Typography>
        </CardContent>
        <div className={classes.tagHeading}>
          <Typography variant="subtitle1" className={classes.TagsTitle}>
            Tags
          </Typography>
        </div>
        <div className={classes.controls}>
          {props.exercise.tags && props.exercise.tags.map((prop, key) => {
            return (
              <Chip
                key={prop + key}
                className={classes.tagChip}
                label={prop.name}
              />
            );
          })}
        </div>
        <div className={classes.controls}>
          {user.roles.viewer && <Button size="small" color="primary" onClick={handleClick}>
            View Exercise
          </Button>}
          {user.roles.editor && <Button size="small" color="primary" onClick={handleCopy}>
            Make Copy
          </Button>}
        </div>
      </div>
    </Card>
  );
}

export default ExerciseListItem;
