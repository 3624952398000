import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import EditIcon from '@material-ui/icons/Edit';

import {
  Checkbox,
  IconButton,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  useTheme,
} from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import { ListItem } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexGrow: 1,
  },
  listItemLinkRoot: {
    width: '100%'
  },
  details: {
    display: "flex",
    flexDirection: "column",
  },
  content: {
    flex: "1 0 auto",
  },
  cover: {
    width: '20%',
    minWidth: '100px'
  },
  types: {
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.spacing(2),
  },
  controls: {
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(2),
  },
  typeHeading: {
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.spacing(2),
  },
  TypesTitle: {
    paddingTop: "10pt",
  },
  chip: {
    position: 'absolute',
    borderRadius: '0pt',
    backgroundColor: '#fff'
  },
  typeChip: {
    borderRadius: '0pt',
    margin: '1px'
  }
}));

function ListItemLink(props) {
  return <ListItem button component="a" {...props} />;
}

function QuestionTypeListItem(props) {
  const classes = useStyles();
  const theme = useTheme();

  const handleEditClick = e => {
    if(props.onEdit) props.onEdit(props.type)
  }

  const handleDeleteClick = e => {
    if(props.onDelete) props.onDelete(props.type)
  }

  return (
    <ListItemLink className={classes.listItemLinkRoot} href={`/questionnaire/${props.type}`}>
      <ListItemText primary={props.type} />
      <ListItemSecondaryAction>
        <IconButton edge="end" aria-label="comments" onClick={handleEditClick}>
          <EditIcon />
        </IconButton>
        <IconButton edge="end" aria-label="comments" onClick={handleDeleteClick}>
          <Delete />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItemLink>
  );
}

export default QuestionTypeListItem;
