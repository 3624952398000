import {
    Dashboard,
    People,
    Lock,
    Queue,
    AttachMoney,
    Layers,
    Category,
    StarHalf,
    Beenhere,
    Money,
    Person,
    Add,
    AccountCircle
} from "@material-ui/icons";
import ExerciseAdd from "../components/ExerciseAdd";
import Clients from "../containers/Clients";
import DashboardPage from "../containers/Dashboard";
import Exclusions from "../containers/Exclusions";

import Exercises from "../containers/Exercises";
import Pricing from "../containers/Pricing";
import Profile from "../containers/Profile";
import Questionnaire from "../containers/Questionnaire";
import Questionnaires from "../containers/Questionnaires";
import SignInContainer from "../containers/SignInContainer";
import SignUpContainer from "../containers/SignUpContainer";
import Tags from "../containers/Tags";
import TeamMembers from "../containers/TeamMembers";

const Routes = [

    {
        path: "/",
        name: "Dashboard",
        icon: Dashboard,
        component: DashboardPage,
    },
    {
        path: "/profile",
        name: "Profile",
        icon: AccountCircle,
        component: Profile,
        hide: true,
        roles: ['user']
    },
    {
        path: "/exercises",
        name: "Exercerise",
        icon: Dashboard,
        component: Exercises,
        roles: ['admin','user']
    },
    {
        path: "/clients",
        name: "Clients",
        icon: Dashboard,
        component: Clients,
        roles: ['admin','user']
    },
    {
        path: "/tags",
        name: "Tags",
        icon: Dashboard,
        component: Tags,
        roles: ['admin','user']
    },
    {
        path: "/exclusions",
        name: "Exclusions",
        icon: Dashboard,
        component: Exclusions,
        roles: ['admin','user']
    },
    {
        path: "/members",
        name: "Team Members",
        icon: Dashboard,
        component: TeamMembers,
        roles: ['admin', 'editor','user']
    },
    {
        path: "/questionnaires",
        name: "Questionnaires",
        icon: Dashboard,
        component: Questionnaires,
        roles: ['admin','user']
    },
    {
        path: "/questionnaire",
        name: "Questionnaire",
        icon: Dashboard,
        component: Questionnaire,
        hide: true,
        roles: ['admin','user']
    },
    {
        path: "/questionnaire/:type",
        name: "Questionnaire",
        icon: Dashboard,
        component: Questionnaire,
        hide: true,
        roles: ['admin','user']
    },
    {
        path: "/pricing",
        name: "Pricing",
        icon: Money,
        component: Pricing,
        hide: true,
    },
    {
        path: "/signin",
        name: "Sign In",
        icon: Person,
        component: SignInContainer,
        hide: true,
        props: {
            showLogin: true
        }
    },
    {
        path: "/signup",
        name: "Sign Up",
        icon: Add,
        component: SignUpContainer,
        hide: true,
        props: {
            showLogin: false
        }
    },
];

export default Routes;