import React, { createRef, useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Divider,
} from "@material-ui/core";
import MultipleChoiceAdd from "./QuestionTypes/MultipleChoiceAdd";
import CheckboxesAdd from "./QuestionTypes/CheckboxesAdd";
import LinearScaleAdd from "./QuestionTypes/LinearScaleAdd";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
}));

export default function QuestionType(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {props.type && props.type.value === 'multiplechoice' &&
        <MultipleChoiceAdd details={props.details} onChange={props.onChange} />
      }
      {props.type && props.type.value === 'checkboxes' &&
        <CheckboxesAdd details={props.details} onChange={props.onChange} />
      }
      {props.type && props.type.value === 'linearscale' &&
        <LinearScaleAdd details={props.details} onChange={props.onChange} />
      }
      {/* {props.type && props.type.value === 'multiplechoicegrid' &&
        <></>
      }
      {props.type && props.type.value === 'checkboxgrid' &&
        <></>
      }
      {props.type && props.type.value === 'short' &&
        <></>
      }
      {props.type && props.type.value === 'long' &&
        <></>
      } */}

    </div>

  )
}
