import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
    Dialog,
    useMediaQuery,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
} from "@material-ui/core";
import { useTheme } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%'
    }
}));

export default function ConfirmationModal(props) {
    const classes = useStyles()
    const theme = useTheme()

    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Dialog
            fullScreen={fullScreen}
            open={props.open}
            onClose={props.onClose}
        >
            <DialogTitle id="dialog-title">{props.title ? props.title : "Are you sure?"}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {props.children ? props.children : "Are you sure?"}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={props.onClose} color="default">
                    Cancel
                </Button>
                <Button onClick={props.onConfirm} color="primary" autoFocus>
                    Yes
                </Button>
            </DialogActions>
        </Dialog>
    )
}
