import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import {
    Button, CircularProgress,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: theme.spacing(2)
  },
  loadingCircle: {
      width: '20px !important',
      height: '20px !important',
      marginRight: theme.spacing(1)
  }
}));

export default function LoadingButton(props) {
  const classes = useStyles();

  return (
    <Button
        {...props}
        color={props.loading ? "default" : props.color}
        disabled={props.disabled || props.loading }
        startIcon={
            <>
                {props.loading&& <CircularProgress className={classes.loadingCircle} color={"default"} />}
                {props.startIcon}
            </>
        }>
        {props.children}
    </Button>
  )
}