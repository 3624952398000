import React, { createRef, useContext, useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import { CloudUpload, CloudUploadOutlined, CloudUploadRounded, Visibility } from "@material-ui/icons";
import DeleteIcon from '@material-ui/icons/Delete';
import GifIcon from '@material-ui/icons/Gif';
import fs from 'fs'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { UserContext } from "../context/UserContext/context";
import ReactPlayer from 'react-player/lazy'
import CircularProgress from '@material-ui/core/CircularProgress';

import {
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  TextareaAutosize,
  TextField,
} from "@material-ui/core";
import Fab from "@material-ui/core/Fab";
import Edit from "@material-ui/icons/Edit";
import Autocomplete, { createFilterOptions } from "@material-ui/lab/Autocomplete";
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { useHistory } from "react-router-dom";
import Utils from "../utils/Utils";
import LoadingButton from "./LoadingBytton";
import ConfirmationModal from "./ConfirmationModal";
import ProgressLoadingButton from "./ProgressLoadingBytton";
import CircularStatic from "./CircularProgressWithLabel";
import LoadingButtonPercentage from "./LoadingByttonPercentage";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  paperContainer: {
    width: "95%",
    margin: "auto",
  },
  paperContainerHeading: {
    width: "100%",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    padding: "5pt",
  },
  input: {
    display: "none",
  },
  media: {
    maxWidth: '100%',
    // maxWidth: "250px",
    maxHeight: "250px",
  },
  content: {
    width: "100%",
    padding: "5pt",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "48%",
    minWidth: "200px",
  },
  button: {
    margin: theme.spacing(1),
  },
  buttonContainer: {
    width: "100%"
  },
  buttonLeft: {
    float: 'left'
  },
  buttonRight: {
    float: 'right'
  },
  deleteButton: {
    float: 'right',
    position: 'absolute',
    zIndex: 1000,
    right: '5px',
  },
  searchButtom: {
    width: '100%',
    borderTop: '1px solid black',
    textAlign: 'center'
  },
  uploadPlaceHolder: {
    textAlign: 'center',
    fontSize: '150px',
    border: '1px solid rgba(0,0,0,0.2)'
  },
  editButton: {
    float: 'right',
    right: '5px',
    zIndex: 1000,
    position: 'absolute',
    marginTop: '-75px'
  }
}));

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const filter = createFilterOptions();

function ExerciseAdd(props) {
  const classes = useStyles();
  const { user } = useContext(UserContext)
  const theme = useTheme();
  // const [selectedFile, setSelectedFile] = React.useState({ type: "image/jpeg", media: "/placeholder_gif.png", thumbnail: "/placeholder_gif.png", });
  const [selectedFile, setSelectedFile] = useState();
  const history = useHistory();
  const nameRef = useRef()
  const descriptionRef = useRef();
  const tagsRef = useRef();
  const exclusionsRef = useRef();
  const [newTags, setNewTags] = useState()
  const [newExclusions, setNewExclusions] = useState()
  const [id, setId] = useState('0')
  const [playGif, setPlayGif] = useState(true)
  const [deleteConfirmationModal, setDeleteConfirmationModal] = useState(false)
  const [copyConfirmationModal, setCopyConfirmationModal] = useState(false)
  const [details, setDetails] = useState(
    props.details ? {
      id: props.details.id ? props.details.id : null,
      name: props.details.name ? props.details.name : '',
      description: props.details.description ? props.details.description : '',
      tags: props.details.tags ? props.details.tags : [],
      exclusions: props.details.exclusions ? props.details.exclusions : [],
      roles: props.details.roles ? props.details.roles : [],
      media: props.details.media ? props.details.media : '',
      thumbnail: props.details.thumbnail ? props.details.thumbnail : ''
    }
      : {
        name: '',
        description: '',
        tags: [],
        exclusions: [],
        roles: [],
        media: '',
        thumbnail: ''
      }
  );

  useEffect(() => {
    if (props.details && props.details.media) {
      let s = Object.assign({}, selectedFile)
      s.media = props.details.media
      s.thumbnail = props.details.thumbnail
      setSelectedFile(s)
      console.log('!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!', s)
    }

  }, [])

  useEffect(() => {
    if (props.details && props.details.media) {
      let s = Object.assign({}, selectedFile)
      s.media = props.details.media
      s.thumbnail = props.details.thumbnail
      setSelectedFile(s)
      console.log('!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!2', s)
    }
  }, [props.details])



  const handleUploadClick = (event) => {
    try {
      let d = details;
      var file = event.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      // setId(Math.round(Utils.getRandomInt(100)))
      setId(Math.round(Utils.uuid()))

      reader.onloadend = function (e) {
        // reader.onload = function (e) {
        let s = {
          type: file.type,
          media: file,
          // dataPreview: reader.result,
          dataPreview: URL.createObjectURL(file),
          // thumbnail: reader.result
          // thumbnail:
        }
        getFrameFromVid(URL.createObjectURL(file), 2).then(img => {
          d.thumbnail = s.thumbnail = img
          d.media = s//reader.result;
          setDetails(d);
          setSelectedFile(s);
        }).catch((err) => {
          console.log('could not create thumbnail')
          d.media = s//reader.result;
          setDetails(d);
          setSelectedFile(s);
        })

        // getFirstFrameFromGif(s)
      }.bind(this);
    } catch (err) {
      console.log('File Upload Error', err)
    }
  };


  const handleSave = (event) => {
    console.log('details', details)
    if (props.handleSave) props.handleSave(details)
  }

  const handleCopy = (event) => {
    if (props.handleCopy) props.handleCopy(details)
    setCopyConfirmationModal(false)
  }

  const handleDelete = (event) => {
    if (props.handleDelete) props.handleDelete(details)
    setDeleteConfirmationModal(false)
  }

  const handleCancel = (event) => {
    if (props.handleCancel) props.handleCancel();
  }

  const handleAddNewTag = (tagName) => {
    let d = details
    d.tags.push({ name: tagName })
    setDetails(d)
    let tags = newTags;
    tags.push({ name: tagName })
    setNewTags(tags)
  }

  const handleAddNewExclusion = (exclusionName) => {
    let d = details
    d.exclusions.push({ name: exclusionName })
    setDetails(d)
    let exclusions = newExclusions;
    exclusions.push({ name: exclusionName })
    setNewExclusions(exclusions)
  }

  const getFrameFromVid = (urlOfFIle, time = 2) => {
    return new Promise((resolve, reject) => {
      let video = document.createElement("video");
      let timeupdate = function () {
        if (snapImage()) {
          video.removeEventListener("timeupdate", timeupdate);
          video.pause();
        }
      };
      video.addEventListener("loadeddata", function () {
        if (snapImage()) {
          video.removeEventListener("timeupdate", timeupdate);
        }
      });
      let snapImage = function () {
        let canvas = document.createElement("canvas");
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        canvas.getContext("2d").drawImage(video, 0, 0, canvas.width, canvas.height);
        let image = canvas.toDataURL();
        let success = image.length > 100000;
        if (success) {
          URL.revokeObjectURL(urlOfFIle);
          resolve(image);
          video.remove()
        }
        return success;
      };
      video.addEventListener("timeupdate", timeupdate);
      video.preload = "metadata";
      video.src = urlOfFIle;
      // Load video in Safari / IE11
      video.muted = true;
      video.playsInline = true;
      video.currentTime = time > video.duration ? 0 : time;
      video.play();
    });

  }

  const getFirstFrameFromGif = async (gif) => {
    let url = gif.dataPreview
    if (!url) url = gif.media
    // var gifFrames = require('gif-frames');
    // gifFrames({ url: url, frames: 0, outputType: 'canvas', cumulative: true }).then(async function (frameData) {
    //   let s = gif
    //   s.thumbnail = frameData[0].getImage().toDataURL()
    //   setSelectedFile(s)
    //   let d = { ...details }
    //   d.thumbnail = s.thumbnail
    //   setDetails(d)
    // }).catch(err => {
    //   console.log('gif thumbnail err', err)
    // });
  }

  useEffect(() => {
    console.log('!! loading', props.loading)
  }, [props.loading])


  useEffect(() => {
    console.log('!! uploadProgress', props.uploadProgress)
  }, [props.uploadProgress])

  return (
    <div className={classes.root}>
      {/* <Paper className={classes.paperContainer}> */}
      <Typography variant="h6" className={classes.paperContainerHeading}>
        {!details || !details.id ? 'Add Exercise' : 'Edit Exercise'}
      </Typography>
      <div className={classes.content}>
        {(details && details.id) &&
          <div className={classes.deleteButton}>
            {user.roles.editor && <Button
              variant="contained"
              color="secondary"
              className={classes.button}
              startIcon={<DeleteIcon />}
              onClick={(e) => { setDeleteConfirmationModal(true) }}
            >
              Delete
            </Button>}
          </div>
        }
        <div id={"media-uploader-" + id}>
          {(selectedFile && selectedFile.media) ?
            <>
              {typeof selectedFile.media === 'object' ?
                <ReactPlayer
                  width='100%'
                  className={classes.media}
                  url={selectedFile.dataPreview}
                  controls
                  muted
                  playing
                  light={selectedFile.thumbnail} />
                : <ReactPlayer
                  width='100%'
                  className={classes.media}
                  url={selectedFile.media}
                  controls
                  muted
                  playing
                  light={selectedFile.thumbnail} />}
            </>
            : <div className={classes.uploadPlaceHolder} >
              <CloudUploadOutlined className={classes.media} />
            </div>
          }
          {user.roles.editor && <input
            // accept="image/*"
            className={classes.input}
            id="contained-button-file"
            // multiple
            type="file"
            onChange={handleUploadClick}
          />}
        </div>
        <label htmlFor="contained-button-file">
          {user.roles.editor && props.editable &&
            <div className={classes.editButton}>
              <Fab
                component="span"
                className={classes.button}
                color="primary">
                <Edit />
              </Fab>
            </div>
          }
        </label>
        <div>
          <TextField
            id="name"
            label="Name"
            ref={nameRef}
            disabled={!props.editable}
            placeholder="Exercise Name"
            helperText="Enter the exercise name"
            margin="normal"
            variant="outlined"
            fullWidth
            style={{ width: "97%", margin: "8pt" }}
            className={classes.textField}
            defaultValue={details.name}
            onChange={(e) => {
              let d = details
              d.name = e.target.value
              setDetails(d)
            }}
          />
        </div>

        <div>
          <TextField
            id="description"
            label="Description"
            ref={descriptionRef}
            disabled={!props.editable}
            style={{ width: "97%", margin: "8pt" }}
            placeholder="Description"
            helperText="Enter Exercise description"
            fullWidth
            multiline
            variant="outlined"
            defaultValue={details.description}
            onChange={(e) => {
              let d = details
              d.description = e.target.value
              setDetails(d)
            }}
          />
        </div>
        <div>
          <Autocomplete
            id="tags"
            ref={tagsRef}
            disabled={!props.editable}
            multiple
            // disableCloseOnSelect
            defaultValue={props.tags.filter(t => details.tags.filter(tt => tt.id === t.id).length > 0)}
            options={props.tags}
            getOptionLabel={(option) => option.name}
            style={{ width: '97%', margin: "8pt" }}
            renderInput={(params) => <TextField
              {...params}
              fullWidth
              variant="outlined"
              label="Tags"
              placeholder="Search for a tag"
              helperText="Select all the appropriate tags, if tag does not exist type new tag name and it will be created" />}
            renderOption={(option, { selected }) => (
              <React.Fragment>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option.name}
              </React.Fragment>
            )}
            filterOptions={(options, params) => {
              const filtered = filter(options, params);
              // Suggest the creation of a new value
              if (params.inputValue !== '') {
                filtered.push({
                  inputValue: params.inputValue,
                  name: `Add "${params.inputValue}"`
                });
                // handleAddNewTag(params.inputValue)
              }
              return filtered;
            }}
            onChange={(e, v) => {
              console.log('value', v)
              let d = details
              d.tags = v
              setDetails(d)
            }}
          />
        </div>
        <div>
          <Autocomplete
            id="exclusions"
            ref={exclusionsRef}
            disabled={!props.editable}
            multiple
            // disableCloseOnSelect
            defaultValue={props.exclusions.filter(t => details.exclusions.filter(tt => tt.id === t.id).length > 0)}
            options={props.exclusions}
            getOptionLabel={(option) => option.name}
            style={{ width: '97%', margin: "8pt" }}
            renderInput={(params) => <TextField
              {...params}
              fullWidth
              variant="outlined"
              label="Exclusions"
              placeholder="Search for a exclusion"
              helperText="Select all the appropriate exclusions, if exclusion does not exist type exclusion tag name and it will be created" />}
            renderOption={(option, { selected }) => (
              <React.Fragment>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option.name}
              </React.Fragment>
            )}
            filterOptions={(options, params) => {
              const filtered = filter(options, params);
              // Suggest the creation of a new value
              if (params.inputValue !== '') {
                filtered.push({
                  inputValue: params.inputValue,
                  name: `Add "${params.inputValue}"`
                });
                // handleAddNewTag(params.inputValue)
              }
              return filtered;
            }}
            onChange={(e, v) => {
              console.log('value', v)
              let d = details
              d.exclusions = v
              setDetails(d)
            }}
          />
        </div>
      </div>

      <div className={classes.buttonContainer}>
        <div className={classes.buttonLeft}>
          <LoadingButton disabled={!!props.loading} className={classes.button} variant="contained" onClick={handleCancel}>Cancel</LoadingButton>
        </div>
        <div className={classes.buttonRight}>

          {props.editable &&
            <>
              {props.makeCopy &&
                <LoadingButton disabled={!!props.loading} loading={props.loading === 'copy'} className={classes.button} color="secondary" onClick={() => { setCopyConfirmationModal(true) }}>
                Make a copy
              </LoadingButton>}
              <LoadingButtonPercentage disabled={!!props.loading} loading={props.loading} progress={props.uploadProgress} className={classes.button} variant="contained" onClick={handleSave} color="primary">
                Save
              </LoadingButtonPercentage>
            </>
          }
        </div>
      </div>
      {props.editable && <ConfirmationModal
        open={deleteConfirmationModal}
        onClose={(e) => { setDeleteConfirmationModal(false) }}
        onConfirm={handleDelete}
      >
        You are about to delete this exercise, are you sure?
      </ConfirmationModal>}
      {props.editable && <ConfirmationModal
        open={copyConfirmationModal}
        onClose={(e) => { setCopyConfirmationModal(false) }}
        onConfirm={handleCopy}
      >
        You are about to make a copy of this exercise. Are you sure?
      </ConfirmationModal>}

    </div>
  );
}

export default ExerciseAdd;
