import React, { useContext, useEffect, useRef, useState } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import _ from 'lodash';
import Paper from "@material-ui/core/Paper";
import { UserContext } from "../context/UserContext/context";
import { DataContext } from "../context/DataContext/context";
import Utils from "../utils/Utils";
import DataController from "../controllers/DataController"
import { Button, Card, CardActions, CardContent, Checkbox, Dialog, Divider, Fab, Grid, IconButton, List, ListItem, ListItemIcon, ListItemText, TextField, Typography } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import TagListItem from "./TagListItem";
import TagAdd from "./TagAdd";


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        width: '100%'
    },
    form: {
        width: '100%'
    },
    action: {
        padding: '10pt'
    },
    hidden: {
        display: 'none',
        position: 'absolute',
    },
}));

export default function PayFastForm(props) {
    const classes = useStyles();

    return (
        <div className={classes.root} >
            <form className={classes.form} action="https://sandbox.payfast.co.za​/eng/process" method="post" >
                <div className={classes.hidden}>
                    <TextField type="hidden" name="merchant_id" value="10000100" />
                    <TextField type="hidden" name="merchant_key" value="46f0cd694581a" />
                    {props.successRedirectURL && <TextField type="hidden" name="return_url" value={props.successRedirectURL} />}
                    {props.cancelRedirectURL && <TextField type="hidden" name="cancel_url" value={props.cancelRedirectURL} />}
                    {props.notifyRedirectURL && <TextField type="hidden" name="notify_url" value={props.notifyRedirectURL} />}

                    <TextField type="hidden" name="amount" value={props.amount ?? 0} />
                    <TextField type="hidden" name="item_name" value={props.clientId ?? ''} />
                </div>
                {/* <TextField id="filled-basic" label="Users" type={'number'} min={1} fullWidth variant="outlined" />
                    <TextField id="filled-basic" label="Filled" fullWidth variant="outlined" /> */}
                <br />
                <Button className={classes.action} variant="contained" fullWidth color={'secondary'} type="submit" disabled={!props.amount} >Subscribe Now</Button>
            </form>

        </div >
    );
}
