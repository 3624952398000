import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Navbar from "../components/Navbar";
import { BrandContext } from "../context/BrandContext/context";
import Sidebar from "../components/Sidebar";
import PortalContent from "../components/PortalContent";
import Exercises from "../containers/Exercises";
import { Route } from "react-router-dom";
import WebsiteContent from "../components/WebsiteContent";
import { SiteContext } from "../context/SiteContext/context";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: "100vh",
  },
}));

export default function WebsiteView() {
  const { brand } = useContext(BrandContext);
  const { settings } = useContext(SiteContext);

  const classes = useStyles();
  const [drawerOpen, seDrawertOpen] = React.useState(brand.sidebar.open);
  const [showLogin, setShowLogin] = React.useState(true);

  const handleDrawer = () => {
    seDrawertOpen(!drawerOpen);
  };

  const handleShowLogin = (e, value = !drawerOpen) => {
    setShowLogin(value);
  };

  return (
    <div className={classes.root}>
      {!brand.navbar.hidden && <Navbar routes={settings.openRoutes} />}
      <WebsiteContent showLogin={showLogin} handleShowLogin={handleShowLogin}/>
    </div>
  );
}
