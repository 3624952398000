import { getAuth, signInWithEmailAndPassword, signOut, setPersistence, browserLocalPersistence, browserSessionPersistence, sendPasswordResetEmail } from "firebase/auth";
import DataController from "./DataController";
import { functions } from "../services/firebase.config";
const axios = require('axios').default;

// import { getFunctions, httpsCallable } from "@firebase/functions";

const PaymentController = { }


PaymentController.VerifyPayment = async (client_id, reference) => {
    try {

        let headers = {
            'Content-Type': 'application/json',
        }
        let response = await axios.post(`${process.env.REACT_APP_API_URL}/__/paystack/verify/${reference}`, {client_id}, { headers: headers })

        if (response.data) {
            return {
                success: true
            }
        }
        return {
            success: false
        }
    } catch (error) {
        throw error
    }
}

PaymentController.CancelSubscription = async (client_id, token) => {
    if (!client_id) throw "Invalid Client"
    if (!token) throw "Invalid Token"

    try {
        let headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
        let response = await axios.post(`${process.env.REACT_APP_API_URL}/subscription/cancel/${client_id}`, { headers: headers })

        if (response.data) {
            return {
                success: true,
                data: response.data.products
            }
        }
        return {
            success: false
        }
    } catch (error) {
        throw error
    }
}

PaymentController.ValidateSubscription = async (client_id, product_id, token) => {
    if (!client_id) throw "Invalid Client"
    if (!product_id) throw "Invalid Product"
    if (!token) throw "Invalid Token"

    try {
        let headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
        let response = await axios.get(`${process.env.REACT_APP_API_URL}/subscription/validate/${product_id}/${client_id}`, { headers: headers })

        if (response.data) {
            return {
                success: true,
                message: response.data.message,
                valid: response.data.valid
            }
        }
        return {
            success: false
        }
    } catch (error) {
        throw error
    }
}

export default PaymentController