import React, { useContext, useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles, withTheme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
// import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
// import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { SiteContext } from '../context/SiteContext/context'
import { BrandContext } from '../context/BrandContext/context'
import { UserContext } from '../context/UserContext/context';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Icon from "@material-ui/core/Icon";
import { NavLink } from "react-router-dom";
import Typography from '@material-ui/core/Typography';
import { useHistory } from 'react-router-dom'

import {
    ChevronRight,
    ChevronLeft,
    Clear,
    AccountCircleOutlined,
    Edit

} from '@material-ui/icons';
import m from 'gm';
import { Button } from '@material-ui/core';


const drawerWidth = 240;


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    title: {
        flexGrow: 1,
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    togglePermanentMethod: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        position: 'absolute',
        bottom: '0px',
        width: '100%',
        borderTop: '1px solid rgba(0,0,0,0.1)'
    },
    toggleTemporaryMethod: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        position: 'absolute',
        width: '100%'
    },
    active: {
        textDecoration: 'none',
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.secondary.dark,
        "&,&:hover": {
            textDecoration: 'none',
            color: theme.palette.primary.contrastText,
            backgroundColor: theme.palette.secondary.dark,
        }
    },
    Sidebar: {
        textDecoration: 'none',
        ...theme.Sidebar,
    },
    routes: {
        height: 'calc(100% - 120px)',
        overflowX: 'hidden',
        overflowY: 'auto'
    },
    logo: {
        position: "relative",
        padding: "0px",
        paddingRight: "10px",
        zIndex: "4",
        "&:after": {
            content: '""',
            position: "absolute",
            bottom: "0",
            height: "1px",
            right: "15px",
            width: "calc(100% - 60px)",
        }
    },
    logoLink: {
        textTransform: "uppercase",
        padding: "5px 0",
        display: "block",
        fontSize: "16px",
        textAlign: "left",
        fontWeight: "400",
        lineHeight: "30px",
        textDecoration: "none",
        backgroundColor: "transparent"
    },
    userImageContainer: {
        // maxHeight: "80px",
        padding: '10pt'
    },
    logoImage: {
        width: "80px"
    },
    userContainer: {
        textAlign: 'center',
        ...theme.userContainer
    },
    defaultAccountIcon: {
        fontSize: '80px'
    },
    editProfileButton: {
        textDecoration: 'none',
    }
}));


function Sidebar(props) {
    const classes = useStyles();
    const { brand } = useContext(BrandContext)
    const { settings } = useContext(SiteContext)
    const { user } = useContext(UserContext)
    const history = useHistory();

    const handleDrawer = () => {
        if (props.setOpen) props.setOpen();
    };

    // verifies if routeName is the one active (in browser input)
    function activeRoute(routeName) {
        return window.location.pathname === routeName;
    }

    const hasAccess = (roles) => {
        if (!roles || roles.length === 0) return true

        return roles.some(r => user.roles[r])
    }

    return (
        <div className={classes.root}>
            <CssBaseline />
            <Drawer
                variant={brand.sidebar.method}
                classes={{
                    paper: clsx(classes.drawerPaper, !props.open && classes.drawerPaperClose),
                }}
                open={props.open}
                onClose={handleDrawer}
            >
                {brand.sidebar.method === "temporary" &&
                    <div className={classes.toggleTemporaryMethod}>
                        <IconButton onClick={handleDrawer}>
                            {!props.open ? <Clear /> : <Clear />}
                        </IconButton>
                    </div>
                }

                {!brand.navbar.hidden ?
                    !brand.sidebar.displayUserDetails ? <div className={classes.appBarSpacer} />
                        : <div className={classes.userContainer} >
                            <div className={classes.userImageContainer}>
                                {user.details.photoURL ? <img src={user.details.photoURL} alt="" className={classes.logoImage} />
                                    : <AccountCircleOutlined color="primary" className={classes.defaultAccountIcon} />}
                            </div>
                            <Typography noWrap variant="h6" color="primary">
                                {user.details.displayName}
                            </Typography>
                            {/* {user.details.clientName &&
                                <Typography variant="caption" color="primary" className={classes.caption}>
                                    {user.details.displayName}
                                    <br />
                                </Typography>
                            } */}
                            <NavLink
                                to={'/profile'}
                                className={classes.editProfileButton}
                                key={`edit_profile_item`}
                                title={'Edit profile'}
                                onClick={handleDrawer}
                            >
                                <Typography variant='caption' color='primary'>Edit profile</Typography>
                            </NavLink>
                            <Divider />
                        </div>

                    : <div className={classes.userContainer} >
                        {(brand.navbar.hidden && brand.logo) &&
                            <div className={classes.userImageContainer}>
                                <img src={brand.logo} alt="" className={classes.logoImage} />
                            </div>
                        }
                        {(brand.navbar.hidden && brand.title) &&
                            <Typography component="h1" variant="h6" color="inherit" className={classes.title}>
                                {brand.title}
                            </Typography>
                        }
                        <Divider />
                    </div>
                }
                <div className={classes.routes}>
                    <List>
                        {(settings && settings.routes && settings.routes.length > 0) &&
                            settings.routes.map((prop, key) => {
                                if (!prop.hide && hasAccess(prop.roles))
                                    return (
                                        <div key={`${key}_container_item`} >
                                            <NavLink
                                                // to={!activeRoute( prop.path) ?  prop.path : '?#'}
                                                to={prop.path}
                                                className={activeRoute(prop.path) ? classes.active : classes.Sidebar}
                                                activeClassName="active"
                                                key={`${key}_nav_item`}
                                                title={key}
                                                onClick={handleDrawer}
                                            >

                                                {!prop.hideInMenu && <ListItem button key={prop.name} className={activeRoute(prop.path) ? classes.active : ''} >
                                                    <ListItemIcon>
                                                        {typeof prop.icon === "string" ? (
                                                            <Icon className={activeRoute(prop.path) ? classes.active : ''}>
                                                                {prop.icon}
                                                            </Icon>
                                                        ) : (
                                                            <prop.icon
                                                                className={activeRoute(prop.path) ? classes.active : ''}
                                                            />
                                                        )}
                                                    </ListItemIcon>
                                                    <ListItemText primary={prop.name} />
                                                </ListItem>
                                                }
                                            </NavLink>
                                        </div>
                                    );
                                else return <></>
                            })
                        }</List>
                </div>
                {brand.sidebar.method === "permanent" &&
                    <div className={classes.togglePermanentMethod}>
                        <IconButton onClick={handleDrawer}>
                            {!props.open ? <ChevronRight /> : <ChevronLeft />}
                        </IconButton>
                    </div>
                }
            </Drawer>
        </div>
    );
}

export default Sidebar;