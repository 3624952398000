import React, { createContext, useReducer } from "react";
import { reducer } from "./reducer";
import routes from '../../routes/routes'
import OpenRoutes from "../../routes/open-routes";

export const SiteContext = createContext();

const SiteProvider = ({ children }) => {
    const [settings, dispatchSettings] = useReducer(reducer, {
         routes: routes,
         openRoutes: OpenRoutes,
         activeView: routes && routes.length > 0 ? routes[0].component : undefined
    });

    return (
        <SiteContext.Provider value={{ settings, dispatchSettings }}>
            {children}
        </SiteContext.Provider>
    );
};

export default SiteProvider;