import React, { useContext, useEffect, useRef, useState } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import _ from 'lodash';
import Paper from "@material-ui/core/Paper";
import { UserContext } from "../context/UserContext/context";
import { DataContext } from "../context/DataContext/context";
import Utils from "../utils/Utils";
import DataController from "../controllers/DataController"
import { Checkbox, Dialog, Fab, Grid, IconButton, List, ListItem, ListItemIcon, ListItemText, Typography } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import TagListItem from "../components/TagListItem";
import TagAdd from "../components/TagAdd";
import ExclusionListItem from "../components/ExclusionListItem";
import ExclusionAdd from "../components/ExclusionAdd";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexGrow: 1,
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
    fab: {
        position: "absolute",
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    gridList: {
        width: "100%",
        textAlign: "left",
    },
    gridListTile: {
        margin: theme.spacing(1),
    },
    paperContainerHeading: {
        width: "100%",
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
    },
    headingTitle: {
        padding: theme.spacing(1),
    },
    headingActionItems: {
        color: theme.palette.primary.contrastText,
        textAlign: "right",
    },
    paperContainer: {
        width: "100%",
        margin: "auto",
    },
    gridItem: {
        margin: theme.spacing(3),
    },
}));

export default function Exclusions({ props }) {
    const { user } = useContext(UserContext);
    // const { data, addExclusions, updateExclusion, deleteExclusion } = useContext(DataContext);

    const [exclusions, setExclusions] = useState([])
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const selectedExclusionsRef = useRef()


    useEffect(() => {
        async function init() {
            setExclusions(await DataController.GetExclusions())
        }
        init()
    }, [])

    const handleAdd = (e) => {
        selectedExclusionsRef.current = null;
        setOpen(true);
    };

    const addExclusion = async (exclusion) => {
        try {
            if (!exclusion) return

            let newExclusion = await DataController.AddExclusion(exclusion)

            let d = [...exclusions]
            d.push(newExclusion)
            setExclusions(d)
        } catch (error) {
            throw error
        }
    }

    const updateExclusion = async (exclusion) => {
        try {
            console.log('update exclusion: ', exclusion)
            let d = [...exclusions]
            let e = d.filter(e => e.id === exclusion.id)
            if (e.length === 0) return

            let data = await DataController.UpdateExclusion(exclusion)
            e[0] = data
            for (let t in d) {
                if (d[t].id === exclusion.id) {
                    d[t] = { ...exclusion }
                    break
                }
            }
            console.log('d', d)
            setExclusions(d)
        } catch (err) {
            console.log(err)
            throw err
        }
    }

    const deleteExclusion = async (exclusion) => {
        if (!exclusion || !exclusion.id) return
        try {
            let d = [...exclusions]
            let e = d.filter(e => e.id !== exclusion.id)
            if (e.length === 0) return

            await DataController.DeleteExclusion(exclusion)

            d = e
            setExclusions(d)
        } catch (err) {
            console.log(err)
            throw err
        }
    }

    const handleSave = (details) => {
        if (!details || !details.name) throw "Cannot save, invalid details provided"
        if (selectedExclusionsRef.current && _.isEqual(details, selectedExclusionsRef.current))
            return handleClose()

        try {
            if (!details.id) handleNewExclusion(details)
            else updateExclusion(details)
            setOpen(false);

            selectedExclusionsRef.current = null;
        } catch (error) {
            console.error(error)
        }
    };

    const handleClose = (e) => {
        selectedExclusionsRef.current = null;
        setOpen(false);
    };

    const handleExclusionCardClick = (exclusion) => {
        selectedExclusionsRef.current = exclusion
        setOpen(true);
    }

    const handleNewExclusion = (exclusion) => {
        if (!exclusion || !exclusion.name || exclusion.name.length === 0) return
        addExclusion(exclusion)
    }

    const handleOnEditClicked = (exclusion) => {
        if (!exclusion || !exclusion.id || !exclusion.name || exclusion.name.length === 0) return
        handleExclusionCardClick(exclusion)
    }

    const handleTagDelete = exclusion => {
        if (!exclusion || !exclusion.id || !exclusion.name || exclusion.name.length === 0) return
        deleteExclusion(exclusion)
    }

    return (
        <div className={classes.root}>
            <Paper className={classes.paperContainer}>
                <div className={classes.paperContainerHeading}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm container>
                            <Grid item xs container direction="column">
                                <Grid item xs>
                                    <Typography variant="h6" className={classes.headingTitle}>Exclusions</Typography>
                                </Grid>
                            </Grid>
                            {user.roles.editor&& <Grid item>
                                <IconButton
                                    aria-label="ListView"
                                    className={classes.headingActionItems}
                                    onClick={handleAdd}
                                >
                                    <Add />
                                </IconButton>
                            </Grid>}
                        </Grid>
                    </Grid>
                </div>
                <List>
                    {exclusions.map((item) => {
                        return (
                            <ListItem>
                                <ExclusionListItem editable={user.roles.editor} exclusion={item} onEdit={handleOnEditClicked} onDelete={handleTagDelete} />
                            </ListItem>
                        );
                    })}
                </List>
            </Paper>
            {user.roles.editor&& <Dialog
                onClose={handleClose}
                aria-labelledby="simple-dialog-title"
                open={open}
            >
                <ExclusionAdd
                    handleSave={handleSave}
                    handleCancel={handleClose}
                    editable={
                        user.roles.editor
                    }
                    exclusions={exclusions}
                    details={selectedExclusionsRef.current}
                />
            </Dialog>}
            {user.roles.editor&& <Fab
                color="secondary"
                aria-label="add"
                className={classes.fab}
                onClick={handleAdd}
            >
                <Add />
            </Fab>}
        </div>
    );
}
