import React, { useContext, useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { BrandContext } from '../context/BrandContext/context'
import { Button, Menu, MenuItem } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';

import { green } from '@material-ui/core/colors';
import { UserContext } from '../context/UserContext/context';
import AuthController from '../controllers/AuthController';
import { AccountCircle, More, ArrowDropDown, Person, PersonOutline, PersonPinCircle, PersonRounded } from '@material-ui/icons';
import { useTheme } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    // root: {
    //     display: 'flex',
    //     flexGrow: 1,
    // },
    // menuButton: {
    //     marginRight: theme.spacing(2),
    // },
    // toolbar: {
    //     paddingRight: 24, // keep right padding when drawer closed
    // },
    // toolbarIcon: {
    //     display: 'flex',
    //     alignItems: 'center',
    //     justifyContent: 'flex-end',
    //     padding: '0 8px',
    //     ...theme.mixins.toolbar,
    // },
    // appBar: {
    //     zIndex: theme.zIndex.drawer + 1,
    //     transition: theme.transitions.create(['width', 'margin'], {
    //         easing: theme.transitions.easing.sharp,
    //         duration: theme.transitions.duration.leavingScreen,
    //     }),
    // },
    // title: {
    //     // flexGrow: 1,
    //     display: 'none',
    //     [theme.breakpoints.up('sm')]: {
    //         display: 'block',
    //     },
    // },
    // appBarSpacer: theme.mixins.toolbar,

    // avatar: {
    //     margin: 10,
    // },
    // logo: {
    //     position: "relative",
    //     padding: "0px",
    //     paddingRight: "10px",
    //     zIndex: "4",
    //     "&:after": {
    //         content: '""',
    //         position: "absolute",
    //         bottom: "0",
    //         height: "1px",
    //         right: "15px",
    //         width: "calc(100% - 60px)",
    //     }
    // },
    // logoLink: {
    //     textTransform: "uppercase",
    //     padding: "5px 0",
    //     display: "block",
    //     fontSize: "16px",
    //     textAlign: "left",
    //     fontWeight: "400",
    //     lineHeight: "30px",
    //     textDecoration: "none",
    //     backgroundColor: "transparent"
    // },
    // logoImageContainer: {
    //     width: "50px",
    //     display: "flex",
    //     maxHeight: "50px",
    //     marginLeft: "5px",
    //     marginRight: "5px"
    // },
    // logoImage: {
    //     width: "50px"
    // },
    // toggleTemporaryMethod: {
    //     marginLeft: '-20px'
    // },
    // toggleTemporaryMethodIcon: {
    //     color: theme.palette.primary.contrastText,
    // },
    // accountsIcon: {
    //     color: theme.palette.primary.contrastText
    // },
    // sectionDesktop: {
    //     display: 'none',
    //     [theme.breakpoints.up('md')]: {
    //         display: 'flex',
    //     },
    // },
    // sectionMobile: {
    //     display: 'flex',
    //     [theme.breakpoints.up('md')]: {
    //         display: 'none',
    //     },
    // },
    // grow: {
    //     flexGrow: 1,
    // },
    root: {
        flexGrow: 1
    },
    grow: {
        flexGrow: 1
    },
    fullwidth: {
        width: '100%'
    },
    accountsIcon: {
        color: theme.palette.primary.contrastText
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'flex',
        },
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
    avatar: {
        margin: 10,
    },
    logo: {
        position: "relative",
        padding: "0px",
        paddingRight: "10px",
        zIndex: "4",
        "&:after": {
            content: '""',
            position: "absolute",
            bottom: "0",
            height: "1px",
            right: "15px",
            width: "calc(100% - 60px)",
        }
    },
    logoLink: {
        textTransform: "uppercase",
        padding: "5px 0",
        display: "block",
        fontSize: "16px",
        textAlign: "left",
        fontWeight: "400",
        lineHeight: "30px",
        textDecoration: "none",
        backgroundColor: "transparent"
    },
    logoImageContainer: {
        width: "50px",
        display: "flex",
        maxHeight: "50px",
        marginLeft: "5px",
        marginRight: "5px"
    },
    logoImage: {
        width: "50px"
    },
}));


export default function Navbar(props) {
    const { brand } = useContext(BrandContext)
    const { user } = useContext(UserContext)
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState()
    const [mobilseAnchorEl, setMobileAnchorEl] = useState()
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    const handleDrawer = () => {
        if (props.handleDrawer) props.handleDrawer()
    };

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const handleMobileMenuOpen = (event) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
        handleMobileMenuClose();
    }

    const handleSignOut = () => {
        try {
            AuthController.SignOutUser()
        } catch (error) {
            console.error(error)
        }
    }

    const renderRoutesMenu = (
        <>{props.routes &&
            props.routes.map(route => {
                if (!route.hide)
                    return (
                        <Button color="inherit" edge="end" href={route.path}>{route.name}</Button>
                    )
                else return <></>
            })
        }</>
    )

    const renderMobileMenu = (
        <Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            id={'menu-mobile'}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}
        >

            {props.routes &&
                props.routes.map(route => {
                    if (!route.hide)
                        return (
                            // <Button color="inherit" edge="end" href={route.path}>{route.name}</Button>
                            <MenuItem onClick={handleMobileMenuClose} href={route.path}>
                                {route.name}
                            </MenuItem>
                        )
                    else return <></>
                })
            }

        </Menu>
    );
    const menuId = 'primary-search-account-menu';
    const mobileMenuId = 'primary-search-account-menu-mobile';

    const renderNavbarButtons = (
        <>{(user && user.isAuthenticated && user.isAuthorized && user.roles && !user.disabled) &&
            <>
                <Button
                    color="inherit"
                    aria-controls="profile-menu"
                    aria-haspopup="true"
                    onClick={handleClick}
                    tile="Click to sign out"
                >
                    {/* <p>{user.details.email}</p> */}
                    <IconButton aria-label="account" className={classes.accountsIcon} >
                        <AccountCircle />
                        <ArrowDropDown />
                    </IconButton>
                </Button>
                <Menu
                    anchorEl={anchorEl}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    id={'profile-menu'}
                    keepMounted
                    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >

                    {user.details.displayName ?
                        <MenuItem>
                            {user.details.displayName}
                        </MenuItem> :
                        <MenuItem >
                            {user.details.email}
                        </MenuItem>
                    }
                    <MenuItem onClick={handleSignOut} tile="Click to sign out">
                        Sign out
                    </MenuItem>

                </Menu>
            </>
        }</>
    );

    return (
        <div className={classes.root}>
            <AppBar position="absolute">
                <Toolbar>
                    {(props.handleDrawer && brand.sidebar.method === "temporary") &&
                        <div className={classes.toggleTemporaryMethod}>
                            <IconButton
                                aria-label="sidebar-menu"
                                onClick={handleDrawer}
                                color="inherit"
                            >
                                <MenuIcon className={classes.toggleTemporaryMethodIcon} />
                            </IconButton>
                        </div>
                    }
                    <div className={classes.logoImageContainer}>
                        <img src={brand.logo} alt="" className={classes.logoImage} />
                    </div>
                    <Typography className={classes.title} variant="h6" noWrap>
                        {brand.title}
                    </Typography>
                    <div className={classes.grow} />
                    <div className={classes.sectionDesktop}>
                        {renderRoutesMenu}
                    </div>
                    {(!user || !user.isAuthenticated || !user.isAuthorized) &&
                        <div className={classes.sectionMobile}>
                            <IconButton
                                aria-label="show more"
                                aria-controls={mobileMenuId}
                                aria-haspopup="true"
                                onClick={handleMobileMenuOpen}
                                color="inherit"
                            >
                                <MenuIcon />
                            </IconButton>
                        </div>}

                    {renderNavbarButtons}
                </Toolbar>
            </AppBar>
            {renderMobileMenu}
        </div>
    );
}