import React, { useContext, useEffect, useRef, useState } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import _, { update } from 'lodash';
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import Box from "@material-ui/core/Box";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import Badge from "@material-ui/core/Badge";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Link from "@material-ui/core/Link";
import ExerciseCard from "../components/ExerciseCard";
import AddCard from "../components/AddCard";
import {
  Avatar,
  Dialog,
  Fab,
  GridList,
  GridListTile,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@material-ui/core";
import { Add, List as ListIcon, Apps } from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import ExerciseAdd from "../components/ExerciseAdd";
import userEvent from "@testing-library/user-event";
import { UserContext } from "../context/UserContext/context";
import { DataContext } from "../context/DataContext/context";
import ExerciseListItem from "../components/ExerciseListItem";
import Utils from "../utils/Utils";
import DataController from "../controllers/DataController"
import { initializeApp } from "firebase/app";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexGrow: 1,
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  fab: {
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  gridList: {
    width: "100%",
    textAlign: "left",
  },
  gridListTile: {
    margin: theme.spacing(1),
  },
  paperContainerHeading: {
    width: "100%",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  headingTitle: {
    padding: theme.spacing(1),
  },
  headingActionItems: {
    color: theme.palette.primary.contrastText,
    textAlign: "right",
  },
  paperContainer: {
    width: "100%",
    margin: "auto",
  },
  gridItem: {
    margin: theme.spacing(3),
  },
}));

export default function Exercises({ props }) {
  const { user } = useContext(UserContext);
  // const { data, exercises, getExercises, addExercise, updateExercise, addTags, addExclusions } = useContext(DataContext);
  const [exercises, setExercises] = useState([])
  const [tags, setTags] = useState([])
  const [exclusions, setExclusions] = useState([])
  const [uploadProgress, setUploadProgress] = useState()

  const classes = useStyles();
  // const [selectedExercise, setSelectedExercise] = React.useState(null);
  const selectedExerciseRef = React.useRef();
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState('');
  const [gridView, setGridView] = React.useState(true);

  useEffect(() => {
    async function init() {
      setExercises(await DataController.GetExercises())
      setTags(await DataController.GetTags())
      setExclusions(await DataController.GetExclusions())
    }
    init()
    setUploadProgress(null)
  }, [])

  const handleGridViewChange = (event) => {
    setGridView(!gridView);
    selectedExerciseRef.current = null;
  };

  const handleAdd = (details) => {
    console.log("add");
    selectedExerciseRef.current = null;
    setOpen(true);
  };

  const addExercise = async (exercise) => {
    try {
      if (!exercise) return

      let newExercise = await DataController.AddExercise(exercise)
      exercise.id = newExercise.id
      let d = [...exercises]
      d.push(exercise)
      setExercises(d)
      return exercise
    } catch (error) {
      throw error
    }
  }

  const updateExercise = async (exercise) => {
    try {
      console.log('update exercise: ', exercise)
      let d = [...exercises]
      let e = d.filter(e => e.id === exercise.id)

      let data = await DataController.UpdateExercise(exercise)

      if (e.length === 0) return
      e[0] = data
      for (let t in d) {
        if (d[t].id === exercise.id) {
          d[t] = { ...exercise }
          break
        }
      }
      console.log('d', d)
      setExercises(d)
    } catch (err) {
      console.log(err)
      throw err
    }
  }

  const deleteExercise = async (exercise) => {
    if (!exercise || !exercise.id) return
    try {
      let d = [...exercises]
      let e = d.filter(e => e.id !== exercise.id)
      if (e.length === 0) return

      await DataController.DeleteExercise(exercise)

      d = e
      setExercises(d)
    } catch (err) {
      console.log(err)
      throw err
    }
  }

  const handleSave = async (details) => {
    console.log("save", details);
    setLoading('save')
    if (!details || !details.name) throw "Cannot save, invalid details provided"
    if (selectedExerciseRef.current && _.isEqual(details, selectedExerciseRef.current))
      return handleClose()

    try {
      let t = await handleNewTags(details.tags)
      let e = await handleNewExclusions(details.exclusions)

      let exerciseId = details.id
      if (!details.id) {
        let exRef = await addExercise({...details})
        exerciseId = exRef.id
      }

      details.id = exerciseId
      let mediaPromises = []
      let mediaIndex = -1
      let thumbIndex = -1
      if (!selectedExerciseRef.current || details.media !== selectedExerciseRef.current.media) {
        // details.media = await handleMedia(details, { data: details.media.media, type: details.media.type })
        mediaPromises.push(handleMedia(details, { data: details.media.media, type: details.media.type }, (process) => {
          setUploadProgress()
        }))
        mediaIndex = 0
        // if (!details.media) throw 'Could not save GIF'
      }
      if (!selectedExerciseRef.current || details.thumbnail !== selectedExerciseRef.current.thumbnail) {
        // details.thumbnail = await handleMedia(details, { data: details.thumbnail, type: 'image/jpeg' })
        mediaPromises.push(handleMedia(details, { data: details.thumbnail, type: 'image/jpeg' }))
        thumbIndex = mediaIndex + 1
        // if (!details.thumbnail) throw 'Could not save Thumbnail'
      }

      if(mediaPromises.length > 0) {
        try{
          let promiseResponse = await Promise.allSettled(mediaPromises)
          if(mediaIndex > -1 && promiseResponse[mediaIndex].status === 'fulfilled') details.media = promiseResponse[mediaIndex].value
          if(thumbIndex > -1 && promiseResponse[thumbIndex].status === 'fulfilled') details.thumbnail = promiseResponse[thumbIndex].value
        } catch(err) {
          throw 'Could not save media'
        }
      }



      await updateExercise(details)
      setLoading(false)

      setOpen(false);
      selectedExerciseRef.current = null;
    } catch (error) {
      console.error(error)
      setLoading(false)
    }
  };

  const handleCopy = async (details) => {
    console.log("copy", details);
    setLoading('copy')

    if (!details || !details.id) return

    try {
      let newExercise = { ...details }
      delete newExercise.id
      newExercise.name = 'Copy Of ' + details.name
      await addExercise(newExercise)

      selectedExerciseRef.current = null;
      setOpen(false);
      setLoading(false)

    } catch (e) {
      console.error(e)
      setLoading(false)
    }
  };

  const handleDelete = async (details) => {
    console.log("delete", details);
    setLoading('delete')
    if (!details || !details.id) return

    try {
      await deleteExercise(details)
    } catch (e) {
      console.err(e)
      setLoading(false)
    }
    selectedExerciseRef.current = null;
    setOpen(false);
    setLoading(false)
  };

  const handleClose = (details) => {
    console.log("close");
    selectedExerciseRef.current = null;
    setOpen(false);
  };

  const handleExerciseCardClick = (exercise) => {
    console.log(exercise)
    selectedExerciseRef.current = exercise
    setOpen(true);
    setLoading(false)
  }

  const handleMedia = async (details, media) => {
    if (!details || !media) return
    try {
      let url = await DataController.UploadExerciseMedia(
        { id: details.id, name: details.name, media: media },
        (progress) => {
          console.log('Upload is ' + progress + '% done');
          setUploadProgress(progress)
        })
      console.log(`!! Uplaod DONE: ${url}`)
      return url
    } catch (err) {
      console.error('!!err', err)
    }
  }

  const handleNewTags = async (exerciseTags) => {
    try {
      if (!exerciseTags || exerciseTags.length === 0) return
      let newTags = exerciseTags.filter(t => t.inputValue)
      for (let tag in newTags) {
        newTags[tag].name = newTags[tag].inputValue
        delete newTags[tag].inputValue
        let id = await DataController.AddTag(newTags[tag])
        newTags[tag].id = id.id
      }
      if (newTags.length > 0) {
        let d = [...tags]
        d.push(...newTags)
        setTags(d)
      }
      return newTags

    } catch (error) {
      throw error
    }
  }

  const handleNewExclusions = async (exerciseExclusions) => {
    try {
      if (!exerciseExclusions || exerciseExclusions.length === 0) return
      let newExclusions = exerciseExclusions.filter(t => t.inputValue)
      for (let exclusion in newExclusions) {
        newExclusions[exclusion].name = newExclusions[exclusion].inputValue
        delete newExclusions[exclusion].inputValue
        let id = await DataController.AddExclusion(newExclusions[exclusion])
        newExclusions[exclusion].id = id.id
      }
      if (newExclusions.length > 0) {
        let d = [...exclusions]
        d.push(...newExclusions)
        setExclusions(d)
      }
      return newExclusions

    } catch (error) {
      throw error
    }
  }

  return (
    <div className={classes.root}>
      <Paper className={classes.paperContainer}>
        <div className={classes.paperContainerHeading}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm container>
              <Grid item xs container direction="column">
                <Grid item xs>
                  <Typography variant="h6" className={classes.headingTitle}>Exercises</Typography>
                </Grid>
              </Grid>
              <Grid item>
                {gridView ? (
                  <IconButton
                    aria-label="ListView"
                    className={classes.headingActionItems}
                    onClick={handleGridViewChange}
                  >
                    <ListIcon />
                  </IconButton>
                ) : (
                  <IconButton
                    aria-label="ListView"
                    className={classes.headingActionItems}
                    onClick={handleGridViewChange}
                  >
                    <Apps />
                  </IconButton>
                )}
                <IconButton
                  aria-label="ListView"
                  className={classes.headingActionItems}
                  onClick={handleAdd}
                >
                  <Add />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </div>
        {gridView ? (
          <Grid container>
            {exercises.map((item, i) => {
              return (
                <Grid
                  item xs={12} sm={6} md={4} lg={2} xl={2}
                  className={classes.gridItem}
                  key={`item_${i}`}
                >
                  <ExerciseCard
                    exercise={item}
                    onClick={handleExerciseCardClick}
                    handleCopy={handleCopy} />
                </Grid>
              );
            })}
          </Grid>
        ) : (
          <List>
            {exercises.map((item, i) => {
              return (
                <ListItem key={`item_${i}`}>
                  <ExerciseListItem
                    exercise={item}
                    onClick={handleExerciseCardClick}
                    handleCopy={handleCopy} />
                </ListItem>
              );
            })}
          </List>
        )}
      </Paper>

      {(selectedExerciseRef.current || open) &&
        <Dialog
          // onClose={handleClose}
          aria-labelledby="simple-dialog-title"
          open={open}
        >
          <ExerciseAdd
            uploadProgress={uploadProgress}
            loading={loading}
            handleSave={handleSave}
            handleCopy={handleCopy}
            handleCancel={handleClose}
            editable={
              user.roles.editor
            }
            makeCopy={selectedExerciseRef.current}
            tags={tags}
            exclusions={exclusions}
            details={selectedExerciseRef.current}
            handleDelete={handleDelete}
          />
        </Dialog>
      }
      {user.roles.editor&& <Fab
        color="secondary"
        aria-label="add"
        className={classes.fab}
        onClick={handleAdd}
      >
        <Add />
      </Fab>}
    </div>
  );
}
