import {
    Dashboard,
    People,
    Lock,
    Queue,
    AttachMoney,
    Layers,
    Category,
    StarHalf,
    Beenhere,
    Money,
    Person,
    Home,
    Add,
    Check
} from "@material-ui/icons";
import ExerciseAdd from "../components/ExerciseAdd";
import AcceptInvite from "../containers/AcceptInvite";
import Clients from "../containers/Clients";
import DashboardPage from "../containers/Dashboard";
import Exclusions from "../containers/Exclusions";

import Exercises from "../containers/Exercises";
import GettingStarted from "../containers/GettingStarted";
import Pricing from "../containers/Pricing";
import Questionnaire from "../containers/Questionnaire";
import Questionnaires from "../containers/Questionnaires";
import SignInContainer from "../containers/SignInContainer";
import SignUpContainer from "../containers/SignUpContainer";
import Tags from "../containers/Tags";
import TeamMembers from "../containers/TeamMembers";
import VerifyEmail from "../containers/VerifyEmail";

const OpenRoutes = [
    // {
    //     path: "/",
    //     name: "Home",
    //     icon: Home,
    //     component: GettingStarted,
    //     hide: true
    // },
    {
        path: "/signin",
        name: "Sign In",
        icon: Person,
        component: SignInContainer,
        // hide: true,
        props: {
            showLogin: true
        }
    },
    {
        path: "/signup",
        name: "Sign Up",
        icon: Add,
        component: SignUpContainer,
        // hide: true,
        props: {
            showLogin: false
        }
    },
    {
        path: "/pricing",
        name: "Pricing",
        icon: Money,
        component: Pricing,
        // hide: true
    },
    {
        path: "/__/email/verify/:uid",
        name: "Verify Email",
        icon: Check,
        component: VerifyEmail,
        hide: true
    },
    {
        path: "/__/invite/accept/:uid",
        name: "Accept Invite",
        icon: Check,
        component: AcceptInvite,
        hide: true
    },

];

export default OpenRoutes;