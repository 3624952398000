import React, { createRef, useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        minWidth: '250px'
    },
}));

export default function FollowupAdd(props) {
    const classes = useStyles();
    const [details, setDetails] = useState(props.details ? props.details : {})
    const [when, setWhen] = useState('None')

    useEffect(() => {
        if (props.details && props.details.when !== undefined) {
            if (props.details.when) setWhen('True')
            else setWhen('False')
        }
    }, [])

    useEffect(() => {
        if (props.onChange) props.onChange(details)
    }, [details])

    return (
        <div className={classes.root}>
            <div className={classes.header}>
                {props.type === 'boolean' &&
                    <FormControl variant="outlined" className={classes.formControl} fullWidth>
                        <InputLabel htmlFor="typeSelect">When</InputLabel>
                        <Select
                            fullWidth
                            labelId={'typeSelect'}
                            value={when}
                            className={classes.selectField}
                            onChange={(e) => {
                                let d = JSON.parse(JSON.stringify(details))
                                let value = e.target.value === 'True'
                                if (e.target.value !== 'None') d.when = value
                                else d = {}
                                setDetails(d)
                                setWhen(e.target.value)
                            }}
                            label="When"
                            renderValue={(value) => { return value }}
                        >
                            <MenuItem key={"when_None_item"} value={'None'}>None</MenuItem>
                            <MenuItem key={"when_true_item"} value={'True'}>True</MenuItem>
                            <MenuItem key={"when_false_item"} value={'False'}>False</MenuItem>
                        </Select>
                    </FormControl>
                }
            </div>
            {(when !== '' && when !== 'None') &&
                <div className={classes.content}>
                    <TextField
                        id="question"
                        label="Question"
                        placeholder="Question"
                        // helperText="Enter the question"
                        margin="normal"
                        variant="outlined"
                        fullWidth
                        // style={{ width: "97%", margin: "8pt" }}
                        className={classes.textField}
                        defaultValue={details.question}
                        onChange={(e) => {
                            let d = JSON.parse(JSON.stringify(details))
                            d.question = e.target.value
                            setDetails(d)
                        }}
                    />
                    {/* <FormControl variant="outlined" className={classes.formControl} fullWidth>
                        <InputLabel htmlFor="typeSelect">Followup Question Type</InputLabel>
                        <Select
                            fullWidth
                            labelId={'typeSelect'}
                            value={type}
                            className={classes.selectField}
                            onChange={(e) => {
                                let d = details
                                d.type = e.target.value === 'True'
                                setDetails(d)
                                setType(e.target.value)
                            }}
                            label="Follow Up Question Type"
                            renderValue={(value) => { return value }}
                        >
                            <MenuItem key={"when_true_item"} value={'True'}>True</MenuItem>
                            <MenuItem key={"when_false_item"} value={'False'}>False</MenuItem>
                        </Select>
                    </FormControl> */}
                </div>
            }
        </div>
    )
}
