import React, { createContext, useReducer } from "react";
import { reducer } from "./reducer";

export const BrandContext = createContext();

const BrandProvider = ({ children }) => {
    const [brand, dispatchBrand] = useReducer(reducer, {
        logo: "/logo_white.png",
        title: "ErgoMOVE",
        color: {
            primary: {
                light: '#33a1e0',
                main: '#4f648a',
                dark: '#1e6c96',
                contrastText: '#fff',
            },
            secondary: {
                light: '#f79365',
                main: '#f27537',
                dark: '#d85315',
                contrastText: '#fff',
            }
        },
        navbar: {
            hidden: false
        },
        sidebar: {
            hidden: false,
            method: 'temporary', // available methods: 'permanent', 'temporary'
            open: false,
            displayUserDetails: true
        },
        css: {}
    });

    return (
        <BrandContext.Provider value={{ brand, dispatchBrand }}>
            {children}
        </BrandContext.Provider>
    );
};

export default BrandProvider;
