import React, { useContext, useEffect, useRef, useState } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import _ from 'lodash';
import Paper from "@material-ui/core/Paper";
import { UserContext } from "../context/UserContext/context";
import { DataContext } from "../context/DataContext/context";
import Utils from "../utils/Utils";
import DataController from "../controllers/DataController"
import { Button, Checkbox, Collapse, Dialog, Fab, Grid, IconButton, List, ListItem, ListItemIcon, ListItemText, Typography } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import QuestionListItem from "../components/QuestionListItem";
import QuestionAdd from "../components/QuestionAdd";
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { NavLink, useParams } from "react-router-dom";
import AuthController from "../controllers/AuthController";


const useStyles = makeStyles((theme) => ({
    root: {
        justifyContent: 'center'
    },
    paper: {
        width: 300,
        padding: '10pt',
        margin: '10pt'
    },
}));

export default function VerifyEmail({ props }) {
    let { uid } = useParams();
    const [valid, setValid] = useState(uid ? true : false)
    const [loading, setLoading] = useState(uid ? true : false)

    const classes = useStyles();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(async () => {
        try {
            if(!uid) throw new Error('Invalid request')
            setLoading(true)
            await AuthController.ActivateUser(uid)
            setValid(true)
            setLoading(false)
        } catch (err) {
            console.log(err)
            setValid(false)
            setLoading(false)
        }
    }, [])

    return (
        <Grid container justify={'center'} className={classes.root}>
            <Paper className={classes.paper}>
                {loading ? <>
                    <Typography variant="h6">Loading</Typography>
                    <Typography variant="body">Please wait while we are getting things ready</Typography>
                </> : <>
                    {valid ? <>
                        <Typography variant="h6">Email Verified</Typography>
                        <Typography variant="body">You are good to go! Please <a href='/signin'>Signin</a></Typography>
                    </> : <>
                        <Typography variant="h6">Oops!</Typography>
                        <Typography variant="body">Looks like landed here by mistake</Typography>
                    </>}
                </>}
            </Paper>
        </Grid>
    );
}
