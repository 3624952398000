import React, { useContext, useEffect, useRef, useState } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import _ from 'lodash';
import Paper from "@material-ui/core/Paper";
import { UserContext } from "../context/UserContext/context";
import { DataContext } from "../context/DataContext/context";
import Utils from "../utils/Utils";
import DataController from "../controllers/DataController"
import { Checkbox, Dialog, Fab, Grid, IconButton, List, ListItem, ListItemIcon, ListItemText, Typography } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import QuestionTypeListItem from "../components/QuestionTypeListItem";
import TypeAdd from "../components/TypeAdd";


const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexGrow: 1,
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
    fab: {
        position: "absolute",
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    gridList: {
        width: "100%",
        textAlign: "left",
    },
    gridListTile: {
        margin: theme.spacing(1),
    },
    paperContainerHeading: {
        width: "100%",
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
    },
    headingTitle: {
        padding: theme.spacing(1),
    },
    headingActionItems: {
        color: theme.palette.primary.contrastText,
        textAlign: "right",
    },
    paperContainer: {
        width: "100%",
        margin: "auto",
    },
    gridItem: {
        margin: theme.spacing(3),
    },
}));

export default function Questionnaires({ props }) {
    // const { user } = useContext(UserContext);
    // const { data, addTypes, updateType, deleteType } = useContext(DataContext);
    const [types, setTypes] = useState([])

    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const selectedTypeRef = useRef()

    useEffect(() => {
        async function init() {
            setTypes(await DataController.GetQuestionTypes())
        }
        init()
    }, [])

    const handleAdd = (e) => {
        selectedTypeRef.current = null;
        setOpen(true);
    };

    const addType = async (type) => {
        try {
            if (!type) return

            let newType = await DataController.AddQuestionType(type)

            let d = [...types]
            d.push(newType)
            setTypes(d)
        } catch (error) {
            throw error
        }
    }

    const updateType = async (type) => {
        try {
            console.log('update type: ', type)
            let d = [...types]
            let e = d.filter(e => e.id === type.id)
            if (e.length === 0) return

            let data = await DataController.UpdateQuestionType(type)
            e[0] = data
            for (let t in d) {
                if (d[t].id === type.id) {
                    d[t] = { ...type }
                    break
                }
            }
            console.log('d', d)
            setTypes(d)
        } catch (err) {
            console.log(err)
            throw err
        }
    }

    const deleteType = async (type) => {
        if (!type || !type.id) return
        try {
            let d = [...types]
            let e = d.filter(e => e.id !== type.id)
            if (e.length === 0) return

            await DataController.DeleteQuestionType(type)

            d = e
            setTypes(d)
        } catch (err) {
            console.log(err)
            throw err
        }
    }

    const handleSave = (details) => {
        if (!details) throw "Cannot save, invalid details provided"
        if (selectedTypeRef.current && _.isEqual(details, selectedTypeRef.current))
            return handleClose()

        try {
            if (!details.id) handleNewType(details)
            else updateType(details)
            setOpen(false);

            selectedTypeRef.current = null;
        } catch (error) {
            console.error(error)
        }
    };

    const handleClose = (details) => {
        selectedTypeRef.current = null;
        setOpen(false);
    };

    const handleTypesCardClick = (type) => {
        selectedTypeRef.current = type
        setOpen(true);
    }

    const handleNewType = (type) => {
        if (!type || !type.name || type.name.length === 0) return
        addType(type)
    }

    const handleOnEditClicked = (type) => {
        if (!type ) return
        handleTypesCardClick(type)
    }

    const handleTypeDelete = type => {
        if (!type || !type.id || !type.name || type.name.length === 0) return
        deleteType(type)
    }

    return (
        <div className={classes.root}>
            <Paper className={classes.paperContainer}>
                <div className={classes.paperContainerHeading}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm container>
                            <Grid item xs container direction="column">
                                <Grid item xs>
                                    <Typography variant="h6" className={classes.headingTitle}>Types</Typography>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <IconButton
                                    aria-label="ListView"
                                    className={classes.headingActionItems}
                                    onClick={handleAdd}
                                >
                                    <Add />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
                <List>
                    {types.map((item) => {
                        return (
                            <QuestionTypeListItem type={item} onEdit={handleOnEditClicked} onDelete={handleTypeDelete}/>
                        );
                    })}
                </List>
            </Paper>
            <Dialog
                onClose={handleClose}
                aria-labelledby="simple-dialog-title"
                open={open}
            >
                <TypeAdd
                    handleSave={handleSave}
                    handleCancel={handleClose}
                    editable={
                        true
                    }
                    // types={types}
                    details={selectedTypeRef.current}
                />
            </Dialog>
            <Fab
                color="secondary"
                aria-label="add"
                className={classes.fab}
                onClick={handleAdd}
            >
                <Add />
            </Fab>
        </div>
    );
}
