import React, { createContext, useEffect, useReducer } from "react";
import { reducer } from "./reducer";
import DataController from "../../controllers/DataController"
import Utils from "../../utils/Utils";

export const DataContext = createContext();
// const DataController =

const DataProvider = ({ children }) => {
  const [data, dispatchData] = useReducer(reducer, {
    clients: [
      {
        id: 1,
        name: 'client1',
        taxNumber: '1234',
        numberUsers: 10,
        members: [1, 2, 3, 4, 5, 6, 7]
      }, { id: 2, name: 'client2', taxNumber: '1234', numberUsers: 10 }, { id: 3, name: 'client3', taxNumber: '1234', numberUsers: 10 }, { id: 4, name: 'client4', taxNumber: '1234', numberUsers: 10 }, { id: 5, name: 'client5', taxNumber: '1234', numberUsers: 10 }, { id: 6, name: 'client6', taxNumber: '1234', numberUsers: 10 }, { id: 7, name: 'client7', taxNumber: '1234', numberUsers: 10 }, { id: 8, name: 'client8', taxNumber: '1234', numberUsers: 10 }, { id: 9, name: 'client9', taxNumber: '1234', numberUsers: 10 }, { id: 10, name: 'client10', taxNumber: '1234', numberUsers: 10 }],
    teamMembers: [
      {
        id: 1,
        name: 'Member Member',
        role: 'user',
        email: 'member@member.com',
        clientId: 1
      }, { id: 2, name: 'Member2 Member', role: 'admin', email: 'member2@member.com', clientId: 1 }, { id: 3, name: 'Member3 Member', role: 'user', email: 'member3@member.com', clientId: 1 }, { id: 4, name: 'Member4 Member', role: 'user', email: 'member4@member.com', clientId: 1 }, { id: 5, name: 'Member5 Member', role: 'user', email: 'member5@member.com', clientId: 1 }, { id: 6, name: 'Member6 Member', role: 'user', email: 'member6@member.com', clientId: 1 }, { id: 7, name: 'Member7 Member', role: 'user', email: 'member7@member.com', clientId: 1 }],
    tags: [{ id: 1, name: 'tag1' }, { id: 2, name: 'tag2' }, { id: 3, name: 'tag3' }, { id: 4, name: 'tag4' }, { id: 5, name: 'tag5' }, { id: 6, name: 'tag6' }, { id: 7, name: 'tag7' }, { id: 8, name: 'tag8' }, { id: 9, name: 'tag9' }, { id: 10, name: 'tag10' }],
    exclusions: [
      {
        id: 1, name: 'ex'
      }, { id: 2, name: 'ex2' }, { id: 3, name: 'ex3' }, { id: 4, name: 'ex4' }, { id: 5, name: 'ex5' }, { id: 6, name: 'ex6' }, { id: 7, name: 'ex7' }, { id: 8, name: 'ex8' }, { id: 9, name: 'ex9' }, { id: 10, name: 'ex10' }],
    exercises: [{
      name: "Lizard",
      description: 'Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging across all continents except Antarctica',
      tags: [{ id: 1, name: 'tag1' }],
      exclusions: [{ id: 1, name: 'ex1' }],
      views: 12,
      thumbnail: '/placeholder.jpg',
      media: '/placeholder_gif.gif',
      id: 1,
    }, {
      name: "Lizard2",
      description: 'Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging across all continents except Antarctica',
      tags: [{ id: 2, name: 'tag2' }],
      exclusions: [{ id: 3, name: 'ex3' }],
      views: 0,
      thumbnail: '/placeholder.jpg',
      media: '/placeholder_gif.gif',
      id: 2,
    }, {
      name: "Lizard3",
      description: 'Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging across all continents except Antarctica',
      tags: [{ id: 1, name: 'tag1' }, { id: 2, name: 'tag2' }, { id: 3, name: 'tag3' }, { id: 4, name: 'tag4' }, { id: 5, name: 'tag5' }, { id: 6, name: 'tag6' }, { id: 7, name: 'tag7' }, { id: 8, name: 'tag8' }, { id: 9, name: 'tag9' }, { id: 10, name: 'tag10' }],
      exclusions: [],
      views: 3,
      thumbnail: '/placeholder.jpg',
      media: '/placeholder_gif.gif',
      id: 2,
    }, {
      name: "Lizard3",
      description: 'Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging across all continents except Antarctica',
      tags: [{ id: 1, name: 'tag1' }, { id: 2, name: 'tag2' }, { id: 3, name: 'tag3' }, { id: 4, name: 'tag4' }, { id: 5, name: 'tag5' }, { id: 6, name: 'tag6' }, { id: 7, name: 'tag7' }, { id: 8, name: 'tag8' }, { id: 9, name: 'tag9' }, { id: 10, name: 'tag10' }],
      exclusions: [{ id: 10, name: 'ex10' }],
      views: 3,
      thumbnail: '/placeholder.jpg',
      media: '/placeholder_gif.gif',
      id: 3,
    }, {
      name: "Lizard3",
      description: 'Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging across all continents except Antarctica',
      tags: [{ id: 1, name: 'tag1' }, { id: 2, name: 'tag2' }, { id: 3, name: 'tag3' }, { id: 4, name: 'tag4' }, { id: 5, name: 'tag5' }, { id: 6, name: 'tag6' }, { id: 7, name: 'tag7' }, { id: 8, name: 'tag8' }, { id: 9, name: 'tag9' }, { id: 10, name: 'tag10' }],
      views: 3,
      thumbnail: '/placeholder.jpg',
      media: '/placeholder_gif.gif',
      id: 4,
    }, {
      name: "Lizard3",
      description: 'Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging across all continents except Antarctica',
      tags: [{ id: 1, name: 'tag1' }, { id: 2, name: 'tag2' }, { id: 3, name: 'tag3' }, { id: 4, name: 'tag4' }, { id: 5, name: 'tag5' }, { id: 6, name: 'tag6' }, { id: 7, name: 'tag7' }, { id: 8, name: 'tag8' }, { id: 9, name: 'tag9' }, { id: 10, name: 'tag10' }],
      views: 3,
      thumbnail: '/placeholder.jpg',
      media: '/placeholder_gif.gif',
      id: 5,
    }, {
      name: "Lizard3",
      description: 'Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging across all continents except Antarctica',
      tags: [{ id: 1, name: 'tag1' }, { id: 2, name: 'tag2' }, { id: 3, name: 'tag3' }, { id: 4, name: 'tag4' }, { id: 5, name: 'tag5' }, { id: 6, name: 'tag6' }, { id: 7, name: 'tag7' }, { id: 8, name: 'tag8' }, { id: 9, name: 'tag9' }, { id: 10, name: 'tag10' }],
      views: 3,
      thumbnail: '/placeholder.jpg',
      media: '/placeholder_gif.gif',
      id: 6,
    }, {
      name: "Lizard3",
      description: 'Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging across all continents except Antarctica',
      tags: [{ id: 1, name: 'tag1' }, { id: 2, name: 'tag2' }, { id: 3, name: 'tag3' }, { id: 4, name: 'tag4' }, { id: 5, name: 'tag5' }, { id: 6, name: 'tag6' }, { id: 7, name: 'tag7' }, { id: 8, name: 'tag8' }, { id: 9, name: 'tag9' }, { id: 10, name: 'tag10' }],
      views: 3,
      thumbnail: '/placeholder.jpg',
      media: '/placeholder_gif.gif',
      id: 7,
    }, {
      name: "Lizard3",
      description: 'Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging across all continents except Antarctica',
      tags: [{ id: 1, name: 'tag1' }, { id: 2, name: 'tag2' }, { id: 3, name: 'tag3' }, { id: 4, name: 'tag4' }, { id: 5, name: 'tag5' }, { id: 6, name: 'tag6' }, { id: 7, name: 'tag7' }, { id: 8, name: 'tag8' }, { id: 9, name: 'tag9' }, { id: 10, name: 'tag10' }],
      views: 3,
      thumbnail: '/placeholder.jpg',
      media: '/placeholder_gif.gif',
      id: 8,
    }, {
      name: "Lizard3",
      description: 'Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging across all continents except Antarctica',
      tags: [{ id: 1, name: 'tag1' }, { id: 2, name: 'tag2' }, { id: 3, name: 'tag3' }, { id: 4, name: 'tag4' }, { id: 5, name: 'tag5' }, { id: 6, name: 'tag6' }, { id: 7, name: 'tag7' }, { id: 8, name: 'tag8' }, { id: 9, name: 'tag9' }, { id: 10, name: 'tag10' }],
      views: 3,
      thumbnail: '/placeholder.jpg',
      media: '/placeholder_gif.gif',
      id: 8,
    }, {
      name: "Lizard3",
      description: 'Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging across all continents except Antarctica',
      tags: [{ id: 1, name: 'tag1' }, { id: 2, name: 'tag2' }, { id: 3, name: 'tag3' }, { id: 4, name: 'tag4' }, { id: 5, name: 'tag5' }, { id: 6, name: 'tag6' }, { id: 7, name: 'tag7' }, { id: 8, name: 'tag8' }, { id: 9, name: 'tag9' }, { id: 10, name: 'tag10' }],
      views: 3,
      thumbnail: '/placeholder.jpg',
      media: '/placeholder_gif.gif',
      id: 10,
    }, {
      name: "Lizard3",
      description: 'Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging across all continents except Antarctica',
      tags: [{ id: 1, name: 'tag1' }, { id: 2, name: 'tag2' }, { id: 3, name: 'tag3' }, { id: 4, name: 'tag4' }, { id: 5, name: 'tag5' }, { id: 6, name: 'tag6' }, { id: 7, name: 'tag7' }, { id: 8, name: 'tag8' }, { id: 9, name: 'tag9' }, { id: 10, name: 'tag10' }],
      views: 3,
      thumbnail: '/placeholder.jpg',
      media: '/placeholder_gif.gif',
      id: 11,
    }, {
      name: "Lizard3",
      description: 'Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging across all continents except Antarctica',
      tags: [{ id: 1, name: 'tag1' }, { id: 2, name: 'tag2' }, { id: 3, name: 'tag3' }, { id: 4, name: 'tag4' }, { id: 5, name: 'tag5' }, { id: 6, name: 'tag6' }, { id: 7, name: 'tag7' }, { id: 8, name: 'tag8' }, { id: 9, name: 'tag9' }, { id: 10, name: 'tag10' }],
      views: 3,
      thumbnail: '/placeholder.jpg',
      media: '/placeholder_gif.gif',
      id: 12,
    }],
    questions: [
      {
        name: 'Inception',
        questions: [
          {
            id: 1,
            question: 'Do you use prescription medication for any of the following? (tick all applicable boxes)',
            type: 'multiplechoice',
            answers: {
              'Yes': true,
              'No': false
            },
            subquestions: [
              'High Blood Pressure',
              'High Cholesterol',
              'Diabetes',
              'Insulin Resistance',
              'Pulmonary (Lung) Disease',
              'Cardiac (Heart) Disease',
              'Thyroid Gland Disease',
              'Osteopenia/Osteoporosis',
              'Arthritis'
            ],
            followup: {
              when: true,
              question: 'If yes, is it under control?',
              answers: {
                'Yes': true,
                'No': false
              },
            },
            condition: {
              when: false,
              action: 'cannot_proceed',
              title: 'Cannot Proceed',
              text: 'If not, then doctors clearance required to join program',
            }
          }, {
            id: 2,
            question: 'On a scale of 1 - 10, what is your current level of physical activity?',
            type: 'linearscale',
            stems: 1,
            min: { 'Nothing': 1 },
            max: { 'Elite': 10 },
            answer: 5,
            help: {
              'I don\'t know, I need your help': false
            }
          }, {
            id: 3,
            question: 'What are your barriers to achieving success in your physical fitness? (tick all applicable boxes)',
            type: 'checkboxes',
            answers: {
              'Lack of Knowledge': false,
              'Lack of Motivation': false,
              'Lack of Time': false,
              'Physical Disability': false,
              'Other': ''
            },
          }, {
            id: 4,
            question: 'Do you understand the risks involved in sitting for too long, everyday, for more than 4-5 hours at a time?',
            type: 'multiplechoice',
            answers: {
              'Yes': true,
              'No': false
            }, followup: {
              when: false,
              question: 'If yes, is it under control?',
              answers: {
                'Yes': true,
                'No': false
              },
            },
            condition: {
              when: false,
              action: 'cannot_proceed',
              title: 'Cannot Proceed',
              text: 'If not, then doctors clearance required to join program',
            }
          }
        ]
      }
    ],
    questionTypes: ['inception']

  });

  const [exercises, dispatchExercises] = useReducer(reducer, { exercises: [] })
  const [clients, dispatchClients] = useReducer(reducer, { data: [] })

  const getExercises = async () => {
    try {
      let results = await DataController.GetExercises()

      let d = { ...exercises }
      d.exercises = results
      dispatchExercises(d)
      return results
    } catch (err) {
      console.log(err)
      throw err
    }
  }

  const addExercise = async (exercise) => {
    try {
      let newExercise = await DataController.AddExercise(exercise)

      let d = exercises
      d.exercises.push(newExercise)
      dispatchExercises(d)
    } catch (err) {
      console.log(err)
      throw err
    }
  }

  const addExercises = async (exercises) => {
    try {
      let newExercises = await DataController.AddExercise(exercises)

      let d = exercises
      d.exercises.push(...newExercises)
      dispatchExercises(d)
    } catch (err) {
      console.log(err)
      throw err
    }
  }

  const updateExercise = async (exercise) => {
    try {
      let d = exercises
      let e = d.exercises.filter(e => e.id === exercise.id)
      if (e.length === 0) return

      let newExercise = await DataController.UpdateExercise(exercise)
      e[0] = { ...newExercise }

      // d.exercises.push(newExercise)
      dispatchData(d)
    } catch (err) {
      console.log(err)
      throw err
    }
  }

  const addTags = async (tags) => {
    try {
      if (!tags || tags.length === 0) return

      let newtags = await DataController.AddTags(tags)

      let d = data
      d.tags.push(...newtags)
      dispatchData(d)
    } catch (error) {
      throw error
    }
  }

  const updateTag = async (tag) => {
    try {
      console.log('update tag: ', tag)
      let d = data
      let e = d.tags.filter(e => e.id === tag.id)
      if (e.length === 0) return

      let newTag = await DataController.UpdateTag(tag)
      e[0] = newTag
      for (let t in d.tags) {
        if (d.tags[t].id === tag.id) {
          d.tags[t] = { ...tag }
          break
        }
      }
      console.log('d', d)

      dispatchData(d)
    } catch (err) {
      console.log(err)
      throw err
    }
  }

  const deleteTag = async (tag) => {
    if (!tag || !tag.id) return
    try {
      let d = data
      let e = d.tags.filter(e => e.id !== tag.id)
      if (e.length === 0) return

      await DataController.DeleteTag(tag)

      d.tags = e
      dispatchData(d)
    } catch (err) {
      console.log(err)
      throw err
    }
  }

  const addExclusions = async (exclusions) => {
    try {
      if (!exclusions || exclusions.length === 0) return

      let newexclusions = await DataController.AddExclusions(exclusions)

      let d = data
      d.exclusions.push(...newexclusions)
      dispatchData(d)
    } catch (error) {
      throw error
    }
  }

  const updateExclusion = async (exclusion) => {
    try {
      console.log('update tag: ', exclusion)
      let d = data
      let e = d.exclusions.filter(e => e.id === exclusion.id)
      if (e.length === 0) return

      let newValue = await DataController.UpdateExclusion(exclusion)
      e[0] = newValue
      for (let t in d.exclusions) {
        if (d.exclusions[t].id === exclusion.id) {
          d.exclusions[t] = { ...exclusion }
          break
        }
      }
      dispatchData(d)
    } catch (err) {
      console.log(err)
      throw err
    }
  }

  const deleteExclusion = async (exclusion) => {
    console.log('delete exclusion:', exclusion)
    if (!exclusion || !exclusion.id) return
    try {
      let d = data
      let e = d.exclusions.filter(e => e.id !== exclusion.id)
      if (e.length === 0) return

      await DataController.DeleteExclusion(exclusion)

      d.exclusions = e
      dispatchData(d)
    } catch (err) {
      console.log(err)
      throw err
    }
  }

  useEffect(() => {
    console.log('@@@@@@@', clients)
  }, [clients])

  const getClients = async () => {
    try {
      let results = await DataController.GetClients()

      let d = { ...clients }
      d.data = [...results]
      console.log('SET_DATA', d)
      dispatchClients(d)
      return results
    } catch (err) {
      console.log(err)
      throw err
    }
  }

  const addClient = async (client) => {
    try {
      if (!clients) return

      let newclient = await DataController.AddClient(client)

      let d = [...clients]
      d.data.push(newclient)
      dispatchClients(d)
    } catch (error) {
      throw error
    }
  }


  const updateClient = async (client) => {
    try {
      console.log('update client: ', client)
      let d = clients
      let e = d.data.filter(e => e.id === client.id)
      if (e.length === 0) return

      let newClient = await DataController.UpdateClient(client)
      e[0] = newClient
      for (let t in d.clients) {
        if (d.clients[t].id === client.id) {
          d.clients[t] = { ...client }
          break
        }
      }
      console.log('d', d)

      dispatchClients(d)
    } catch (err) {
      console.log(err)
      throw err
    }
  }

  const deleteClient = async (client) => {
    if (!client || !client.id) return
    try {
      let d = data
      let e = d.clients.filter(e => e.id !== client.id)
      if (e.length === 0) return

      await DataController.DeleteClient(client)

      d.clients = e
      dispatchClients(d)
    } catch (err) {
      console.log(err)
      throw err
    }
  }

  // const addTeamMembers = async (members) => {
  //   try {
  //     if (!members || members.length === 0) return

  //     let newMembers = await DataController.AddMember(members)

  //     let d = data
  //     d.teamMembers.push(...newMembers)
  //     dispatchData(d)
  //   } catch (error) {
  //     throw error
  //   }
  // }

  // const updateTeamMember = async (member) => {
  //   try {
  //     console.log('update TeamMember: ', member)
  //     let d = data
  //     let e = d.teamMembers.filter(e => e.id === member.id)
  //     if (e.length === 0) return

  //     let newMember = await DataController.UpdateMember(member)
  //     e[0] = newMember
  //     for (let t in d.teamMembers) {
  //       if (d.teamMembers[t].id === member.id) {
  //         d.teamMembers[t] = { ...member }
  //         break
  //       }
  //     }
  //     console.log('d', d)

  //     dispatchData(d)
  //   } catch (err) {
  //     console.log(err)
  //     throw err
  //   }
  // }

  // const deleteTeamMember = async (member) => {
  //   if (!member || !member.id) return
  //   try {
  //     let d = data
  //     let e = d.teamMembers.filter(e => e.id !== member.id)
  //     if (e.length === 0) return

  //     await DataController.DeleteMember(member)

  //     d.teamMembers = e
  //     dispatchData(d)
  //   } catch (err) {
  //     console.log(err)
  //     throw err
  //   }
  // }


  const addQuestion = async (question) => {
    try {
      if (!question) return

      let newQuestion = await DataController.AddQuestion(question)

      let d = data
      d.questions.push(...newQuestion)
      dispatchData(d)
    } catch (error) {
      throw error
    }
  }

  const updateQuestion = async (question) => {
    try {
      console.log('update question: ', question)
      let d = data
      let e = d.questions.filter(e => e.id === question.id)
      if (e.length === 0) return

      let newValue = await DataController.UpdateQuestion(question)
      e[0] = newValue
      for (let t in d.questions) {
        if (d.questions[t].id === question.id) {
          d.questions[t] = { ...question }
          break
        }
      }
      console.log('d', d)

      dispatchData(d)
    } catch (err) {
      console.log(err)
      throw err
    }
  }

  const deleteQuestion = async (question) => {
    if (!question || !question.id) return
    try {
      let d = data
      let e = d.questions.filter(e => e.id !== question.id)
      if (e.length === 0) return

      await DataController.DeleteQuestion(question)

      d.questions = e
      dispatchData(d)
    } catch (err) {
      console.log(err)
      throw err
    }
  }

  const addQuestionType = async (type) => {
    try {
      if (!type) return

      let newType = await DataController.AddQuestionType(type)

      let d = data
      d.questionTypes.push(...newType)
      dispatchData(d)
    } catch (error) {
      throw error
    }
  }

  const updateQuestionType = async (type) => {
    try {
      let d = data
      let e = d.questionTypes.filter(e => e.id === type.id)
      if (e.length === 0) return

      let newValue = await DataController.UpdateQuestionType(type)
      e[0] = newValue
      for (let t in d.questionTypes) {
        if (d.questionTypes[t].id === type.id) {
          d.questionTypes[t] = { ...type }
          break
        }
      }

      dispatchData(d)
    } catch (err) {
      console.log(err)
      throw err
    }
  }

  const deleteQuestionType = async (type) => {
    if (!type || !type.id) return
    try {
      let d = data
      let e = d.questionTypes.filter(e => e.id !== type.id)
      if (e.length === 0) return

      await DataController.DeleteQuestionType(type)

      d.questionTypes = e
      dispatchData(d)
    } catch (err) {
      console.log(err)
      throw err
    }
  }

  return (
    <DataContext.Provider value={{
      data,
      exercises,
      clients,
      dispatchData,
      getExercises,
      addExercise,
      addExercises,
      updateExercise,
      addTags,
      updateTag,
      deleteTag,
      addExclusions,
      updateExclusion,
      deleteExclusion,
      getClients,
      addClient,
      updateClient,
      deleteClient,
      addQuestion,
      updateQuestion,
      deleteQuestion,
      dispatchClients
    }}>
      {children}
    </DataContext.Provider>
  );
};

export default DataProvider;