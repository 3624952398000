import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import EditIcon from '@material-ui/icons/Edit';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

import {
  Card,
  CardContent,
  Checkbox,
  GridList,
  GridListTile,
  IconButton,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  Radio,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import MultipleChoice from "./MultipleChoice";
import LinearScale from "./LinearScale";
import Checkboxes from "./Checkboxes";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexGrow: 1,
    margin: 10
  },
  multipleChoiceCell: {
    display: "flex",
    // flexDirection: "column",
    flexGrow: 1,
    margin: 10
  },
  details: {
    display: "flex",
    flexDirection: "column",
  },
  content: {
    flex: "1 0 auto",
  },
  cover: {
    width: '20%',
    minWidth: '100px'
  },
  questions: {
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.spacing(2),
  },
  controls: {
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(2),
  },
  questionHeading: {
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.spacing(2),
  },
  questionsTitle: {
    paddingTop: "10pt",
  },
  chip: {
    position: 'absolute',
    borderRadius: '0pt',
    backgroundColor: '#fff'
  },
  questionChip: {
    borderRadius: '0pt',
    margin: '1px'
  },
  followup: {
    paddingLeft: theme.spacing(4)
  },
  actionButtons: {
    marginTop: -theme.spacing(1)
  },
  header: {
    display: 'flex'
  },
  headerText: {
    flexGrow: 1
  }
}));

function QuestionItem(props) {
  const classes = useStyles();

  return (
    <div>
      <div className={classes.header}>
        <Typography variant="h5" className={classes.headerText}>{props.details.question}</Typography>
        {props.editable &&
          <div className={classes.actionButtons}>
            {props.onEdit && <IconButton edge="end" aria-label="edit" onClick={(e) => {
              e.preventDefault()
              props.onEdit(props.details)
            }}>
              <EditIcon />
            </IconButton>}
            {props.onDelete && <IconButton edge="end" aria-label="delete" onClick={(e) => {
              e.preventDefault()
              props.onDelete(props.details)
            }}>
              <Delete />
            </IconButton>}
          </div>
        }
      </div>
      <div className={classes.content}>
        {props.details.type === 'multiplechoice' && <MultipleChoice editable={props.editable} details={props.details} onChange={props.onChange} onCondition={props.onCondition} onComplete={props.onComplete}/>}
        {props.details.type === 'linearscale' && <LinearScale editable={props.editable} details={props.details} onChange={props.onChange} onCondition={props.onCondition} onComplete={props.onComplete} />}
        {props.details.type === 'checkboxes' && <Checkboxes editable={props.editable} details={props.details} onChange={props.onChange} onCondition={props.onCondition} onComplete={props.onComplete} />}
      </div>
    </div>
  )
}

export default function QuestionListItem(props) {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Card className={classes.root}>
      <CardContent className={classes.content}>
        <QuestionItem details={props.details} onCondition={props.onCondition} onComplete={props.onComplete} editable={props.editable} onDelete={props.onDelete} onEdit={props.onEdit} />
      </CardContent>
    </Card>
  );
}
