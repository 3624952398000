import React, { useContext, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import { PaystackButton, usePaystackPayment } from 'react-paystack';
import PaymentController from "../controllers/PaymentController";
import { UserContext } from "../context/UserContext/context";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        width: '100%'
    },
    form: {
        width: '100%'
    },
    action: {
        padding: '10pt'
    },
    hidden: {
        display: 'none',
        position: 'absolute',
    },
}));

export default function PayStackForm(props) {
    const classes = useStyles();
    const { user } = useContext(UserContext)

    const initializePayment = usePaystackPayment(props.config);

    const validateSubscription = async () => {
        try {

            let validProduct = await PaymentController.ValidateSubscription(user.details.clientId, props.config.id, user.details.accessToken)
            if (!validProduct.valid) {
                alert(validProduct.message)
                return
            }
            initializePayment(props.config.onSuccess, props.config.onClose)
        } catch (e) {
            console.log(e)
        }
    }

    return (
        <div className={classes.root} >
            {props.config && <Button
                className={classes.action}
                variant="contained"
                fullWidth
                color={'secondary'}
                type="submit"
                disabled={!props.config.amount}
                onClick={(e) => validateSubscription()}>
                Subscribe Now
            </Button>}
            {/* {props.config && <PaystackButton
                className={classes.action}
                variant="contained"
                fullWidth
                color={'secondary'}
                type="submit"
                disabled={!props.amount}
                {...props.config} />
            } */}
        </div >
    );
}
