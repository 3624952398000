import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import {
  Radio,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from "@material-ui/core";
import { Delete } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexGrow: 1,
    margin: 10
  },
  multipleChoiceCell: {
    display: "flex",
    // flexDirection: "column",
    flexGrow: 1,
    margin: 10
  },
  questions: {
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.spacing(2),
  },
  questionHeading: {
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.spacing(2),
  },
  questionsTitle: {
    paddingTop: "10pt",
  },
  chip: {
    position: 'absolute',
    borderRadius: '0pt',
    backgroundColor: '#fff'
  },
  questionChip: {
    borderRadius: '0pt',
    margin: '1px'
  },
  followup: {
    paddingLeft: theme.spacing(4)
  }
}));

export default function MultipleChoice(props) {
  const classes = useStyles();
  const [questions, setQuestions] = useState([])
  const [answer, setAnswer] = useState({})
  const [columns, setColumns] = useState([])
  const [checked, setChecked] = useState([])

  useEffect(() => {
    console.log('props', props)
    let parsedQuestions = []
    let cols = []
    let fCols = []

    let vals = props.details.acceptableAnswers?? props.details.answers
    Object.keys(vals).map(key => {
      cols.push({
        key: key,
        value: vals[key]
      })
    })

    let a = {
    }

    if (props.details.subquestions) {
      props.details.subquestions.map(s => a[s] = {})
      let tmp = []
      // props.details.subquestions.forEach(s => {
      //   if(typeof s === 'object')
      //     tmp.push(s.key)
      // })
      // if(tmp.length === 0) tmp = props.details.subquestions
      setQuestions(props.details.subquestions)
    }

    setAnswer(a)
    setColumns(cols)
  }, [])

  const handleChange = (question, column, followup = false) => {
    let a = { ...answer }
    let hasValue = false
    let questionFollowups = []

    if (followup) {
      if (a[question] && a[question].answer) {
        hasValue = { ...a[question].followup }
      }
      a[question].followup = {
        question: question,
        answer: {
          key: column.key,
          value: column.value
        },
      }
    } else {
      if (a[question] && a[question].answer) {
        hasValue = { ...a[question] }
      }

      a[question] = {
        answer: {
          key: column.key,
          value: column.value
        },
        followup: props.details.followup && column.value === props.details.followup.when
      }
    }

    setAnswer(a)

    let newChecked = [...checked]
    let q = question
    if (followup) q += "_" + followup

    if (hasValue && hasValue.answer) {
      let oldValue = newChecked.indexOf(q + '_' + hasValue.answer.key)
      if (oldValue >= 0) newChecked.splice(oldValue, 1)

      newChecked.map(key => {
        if (key.includes(question + '_' + props.details.followup.question)) {
          questionFollowups.push(key)
        }
      })
    }

    let alreadyChecked = newChecked.indexOf(q + '_' + column.key)
    if (alreadyChecked < 0) newChecked.push(q + '_' + column.key)
    else newChecked.splice(alreadyChecked, 1)

    if (questionFollowups.length > 0) {
      questionFollowups.map(key => {
        let a = newChecked.indexOf(key)
        if (a >= 0) newChecked.splice(a, 1)
      })
    }

    setChecked(newChecked)

    if (checkConditions(a[question], question) && props.onCondition) {
      props.onCondition(a[question], question, props.details.condition)
      return
    }

    console.log('newChecked', newChecked)
    console.log('a', a)

    if(props.onComplete) {
      let valid = true
      Object.keys(a).map(key => {
        if(!a[key].answer) valid = false
        else if((a[key].answer.followup && !a[key].answer.followup.answer)) valid  = false
      })
      if(valid) props.onComplete(a)
    }
  }

  const checkConditions = (newAnswer, question) => {
    if (!props.details || !props.details.condition) return false

    // let conditions = Object.keys(props.details.condition.when)
    // if (!conditions || conditions.length === 0 || !newAnswer[conditions[0]] || !newAnswer[conditions[0]].answer || newAnswer[conditions[0]].answer.value === undefined || newAnswer[conditions[0]].answer.value === null) return false

    if (!newAnswer.followup || !newAnswer.followup.answer || newAnswer.followup.answer.value === undefined || newAnswer.followup.answer.value === null) return false

    // if (newAnswer[conditions[0]].answer.value === props.details.condition.when[conditions[0]])
    if (newAnswer.followup.answer.value+'' === props.details.condition.when+'')
      return true

    return false
  }

  return (
    <>
      <TableContainer >
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              {columns.map(column => {
                return <TableCell align="center">{column.key}</TableCell>
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {(questions && questions.length > 0) ?
              questions.map((q, i) => {
                return (
                  <>
                    <TableRow key={`q_${i}`}>
                      <TableCell component="th" scope="row">
                        {q}
                      </TableCell>
                      {columns.map(column => {
                        return <TableCell align="center">
                          <Radio
                            checked={checked.includes(q + '_' + column.key)}
                            onChange={() => handleChange(q, column)}
                            value={column.value}
                            name={column.key + '_rb'}
                          />
                        </TableCell>
                      })}
                    </TableRow>
                    {(answer[q] && answer[q].followup && props.details.followup.answers) &&
                      <TableRow key={`q_${i}_f`}>
                        <TableCell component="th" scope="row" className={classes.followup}>
                          {props.details.followup.question}
                        </TableCell>
                        {/* {Object.keys(props.details.followup.answers).map(c => { */}
                        {columns.map(c => {
                          return <TableCell align="center">
                            <Radio
                              checked={checked.includes(q + '_' + props.details.followup.question + '_' + c.key)}
                              onChange={() => handleChange(q, { key: c.key, value: props.details.followup.answers[c.key] }, props.details.followup.question)}
                              value={props.details.followup.answers[c.key].value}
                              name={props.details.followup.answers[c.key].key + '_rb_f'}
                            />
                          </TableCell>
                        })}
                      </TableRow>
                    }
                  </>
                )
              }) :
              <>
                <TableRow key={`q`}>
                  <TableCell component="th" scope="row">

                  </TableCell>
                  {columns.map(column => {
                    return <TableCell align="center">
                      <Radio
                        checked={checked.includes(props.details.question + '_' + column.key)}
                        onChange={() => handleChange(props.details.question, column)}
                        value={column.value}
                        name={column.key + '_rb'}
                      />
                    </TableCell>
                  })}
                </TableRow>
                {answer[props.details.question] &&
                  answer[props.details.question].followup &&
                  <TableRow key={`q_f`}>
                    <TableCell component="th" scope="row" className={classes.followup}>
                      {props.details.followup.question}
                    </TableCell>
                    {Object.keys(props.details.followup.answers).map(c => {
                      return <TableCell align="center">
                        <Radio
                          checked={checked.includes(props.details.question + '_' + props.details.followup.question + '_' + c)}
                          onChange={() => handleChange(props.details.question, { key: c, value: props.details.followup.answers[c] }, props.details.followup.question)}
                          value={props.details.followup.answers[c].value}
                          name={props.details.followup.answers[c].key + '_rb_f'}
                        />
                      </TableCell>
                    })}
                  </TableRow>
                }
              </>
            }
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}