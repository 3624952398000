import { getAuth, signInWithEmailAndPassword, signOut, setPersistence, browserLocalPersistence, browserSessionPersistence, sendPasswordResetEmail } from "firebase/auth";
import DataController from "./DataController";
import { functions } from "../services/firebase.config";
const axios = require('axios').default;

// import { getFunctions, httpsCallable } from "@firebase/functions";

const AuthController = {}

AuthController.SignUpClient = async (client) => {
    if (!client) throw "Invalid Client"
    if (!client.name) throw "Invalid Client Name"
    if (!client.user || !client.user.email || !client.user.firstName || !client.user.password || !client.user.contactNumber) throw "Invalid contact person"

    try {
        let data = client
        let headers = {
            'Content-Type': 'application/json',
        }
        let response = await axios.post(`${process.env.REACT_APP_API_URL}/clients/signup`, data, { headers: headers })

        if (response.data) {
            return {
                success: true,
                data: response.data
            }
        }

        return {
            success: false
        }
    } catch (error) {
        if (error.response) {
            throw {
                code: error.response.status,
                message: error.response.data.message
            }
        }
        else throw error
    }
}

AuthController.UpdateUser = async (user, token) => {
    if (!token) throw "Invalid token"
    if (!user) throw "Invalid User"
    if (!user.uid) throw "Invalid User uid"
    // if (!user.displayName) throw "Invalid User displayName"

    try {
        let data = { ...user }
        let headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
        let response = await axios.patch(`${process.env.REACT_APP_API_URL}/users/${user.uid}`, data, { headers: headers })

        if (response.data) {
            return {
                success: true,
                data: response.data
            }
        }

        return {
            success: false
        }
    } catch (error) {
        if (error.response) {
            throw {
                code: error.response.status,
                message: error.response.data.message
            }
        }
        else throw error
    }
}

AuthController.SignUpUser = async (newUser) => {

    // if (!newUser || !newUser.email || !newUser.password)
    //     return {
    //         code: 403,
    //         message: 'Invalid user credentials'
    //     }

    // try {
    //     const auth = getAuth();
    //     let userCredential = await createUserWithEmailAndPassword(auth, newUser.email, newUser.password)
    //     return {
    //         user: userCredential.user
    //     }

    // } catch (error) {
    //     return {
    //         code: error.code,
    //         message: error.message
    //     }
    // }

}

AuthController.ProductPaymentSignature = async (client, token) => {
    if (!client) throw "Invalid Client"
    if (!client.id) throw "Invalid Client"
    if (!token) throw "Invalid Token"

    try {
        let data = {
            id: client.id,
            productId: 'pro'
        }
        let headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
        let response = await axios.post(`${process.env.REACT_APP_API_URL}/products/signature`, data, { headers: headers })

        if (response.data) {
            return {
                success: true,
                data: response.data
            }
        }
        return {
            success: false
        }
    } catch (error) {
        throw error
    }
}

AuthController.GetUserProducts = async (client, token) => {
    if (!client) throw "Invalid Client"
    if (!client.id) throw "Invalid Client"
    if (!token) throw "Invalid Token"

    try {
        let data = {
            id: client.id
        }
        let headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
        let response = await axios.post(`${process.env.REACT_APP_API_URL}/products/user`, data, { headers: headers })

        if (response.data) {
            return {
                success: true,
                data: response.data.products
            }
        }
        return {
            success: false
        }
    } catch (error) {
        throw error
    }
}


AuthController.GetProducts = async () => {
    try {
        let response = await axios.get(`${process.env.REACT_APP_API_URL}/products`)

        if (response.data) {
            return {
                success: true,
                data: response.data.products
            }
        }
        return {
            success: false
        }
    } catch (error) {
        throw error
    }
}

AuthController.SignInUserNameAndPassword = async (details) => {
    if (!details.email || !details.password) throw new Error('Invalid email or password')
    try {
        if (details.rememberMe) {
            setPersistence(getAuth(), browserSessionPersistence)
        } else {
            setPersistence(getAuth(), browserLocalPersistence)
        }

        let userCredentials = await signInWithEmailAndPassword(getAuth(), details.email, details.password)
        return userCredentials.user
    } catch (error) {
        throw new Error('Invalid Email or Password')
    }
}

AuthController.SignOutUser = () => {
    try {
        signOut(getAuth())
    } catch (error) {
        throw new Error('Invalid Email or Password')
    }
}

AuthController.GetUserClaims = async (user) => {
    try {
        if (!user) throw Error('Unauthorized')
        let token = await user.getIdTokenResult()
        return token.claims
    } catch (error) {
        throw new Error('Unauthorized')
    }
}

AuthController.ResetPassword = async (email) => {
    try {
        await sendPasswordResetEmail(getAuth(), email)
    } catch (error) {
        console.log('password reset error', error)
        throw new Error('Invalid User')
    }
}

AuthController.ActivateUser = async (uid) => {
    if (!uid) throw "Invalid data"

    try {
        let data = { uid: uid }
        let headers = {
            'Content-Type': 'application/json'
        }
        let response = await axios.post(`${process.env.REACT_APP_API_URL}/users/activate`, data, { headers: headers })

        if (response.data) {
            return {
                success: true,
                data: response.data
            }
        }

        return {
            success: false
        }
    } catch (error) {
        if (error.response) {
            throw {
                code: error.response.status,
                message: error.response.data.message
            }
        }
        else throw error
    }
}

AuthController.GetUserRequiredQuestionnaire = async (uid, token) => {
    if (!uid) throw "Invalid data"

    try {
        let headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
        let response = await axios.get(`${process.env.REACT_APP_API_URL}/users/${uid}/questionnaires`, { headers: headers })

        if (response.data) {
            return {
                success: true,
                data: response.data.data
            }
        }

        return {
            success: false
        }
    } catch (error) {
        if (error.response) {
            throw {
                code: error.response.status,
                message: error.response.data.message
            }
        }
        else throw error
    }
}

AuthController.UpdateUserAnsweredQuestionnaire = async (uid, question_type, questions, token) => {
    if (!uid || !questions || questions.length === 0 || !question_type) throw "Invalid data"

    try {
        let headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
        let response = await axios.post(`${process.env.REACT_APP_API_URL}/users/${uid}/questionnaires/answer`, { question_type, questions }, { headers: headers })

        return {
            success: response.data && response.data.success
        }
    } catch (error) {
        if (error.response) {
            throw {
                code: error.response.status,
                message: error.response.data.message
            }
        }
        else throw error
    }
}

export default AuthController