import React, { useContext, useState } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Button, Checkbox, CircularProgress, FormControlLabel, Grid, TextField } from "@material-ui/core";
import AuthController from "../controllers/AuthController";
import UserContext from "../context/UserContext/context";
import { Alert } from "@material-ui/lab";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: '550px'
    },
    paper: {
        margin: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    title: {
        textAlign: 'center'
    },
    loading: {
        width: '20px !important',
        height: '20px !important',
        marginLeft: '10px',
    }
}));

export default function SignIn(props) {
    const classes = useStyles();

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [rememberMe, setRememberMe] = useState(false)
    const [error, setError] = useState(false)
    const [warning, setWarning] = useState(false)
    const [feedback, setFeedback] = useState(false)
    const [loading, setLoading] = useState(false)

    const handleSubmit = async (e) => {
        try {
            e.preventDefault()
            setError(false)
            setWarning(false)
            setFeedback(false)
            setLoading(true)
            await AuthController.SignInUserNameAndPassword({ email: email, password: password, rememberMe: rememberMe })
            setLoading(false)
        } catch (err) {
            console.error('error', err)
            setError(err.message)
            setLoading(false)
        }
    };

    const forgotPassword = async () => {
        try {
            if (email){
                await AuthController.ResetPassword(email)
                setWarning(false)
                setError(false)
                setFeedback('Reset password email has been send')
            }
            else {
                setFeedback(false)
                setError(false)
                setWarning('Please specify an email')
            }
        } catch (err) {
            setError('Could not reset password')
            setWarning(false)
            console.error(err)
        }
    };
    return (
        <div container component="main" className={classes.root}>
            <Typography variant="h2" className={classes.title}> Sign In</Typography>
            {error && <Alert severity="error"> {error} </Alert>}
            {warning && <Alert severity="warning"> {warning} </Alert>}
            {feedback && <Alert severity="success"> {feedback} </Alert>}
            <div>
                <TextField
                    disabled={loading}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    autoFocus
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <TextField
                    disabled={loading}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
                <FormControlLabel
                    control={<Checkbox checked={rememberMe} disabled={loading} onChange={(e) => setRememberMe(e.target.checked)} value="rememberMe" color="primary" />}
                    label="Remember me"
                />
                <Button
                    disabled={loading}
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    onClick={handleSubmit}
                >
                    Sign In
                    {loading && <CircularProgress className={classes.loading} />}
                </Button>
            </div>
            <Grid container>
                <Grid item xs>
                    <Button size="small" onClick={forgotPassword}>
                        Forgot password?
                    </Button>
                </Grid>
                <Grid item>
                    <Button size="small" href="/signup">
                        {"Don't have an account? Sign Up"}
                    </Button>
                </Grid>
            </Grid>
        </div>
    );
}
