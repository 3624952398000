import React, { createRef, useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
    Checkbox,
    Divider,
    InputLabel,
    TextField,
    FormControl,
    Select,
    MenuItem,
} from "@material-ui/core";
import FollowupAdd from "./FollowupAdd";
import ConditionAdd from "./ConditionAdd";
import { range } from "lodash";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%'
    },
    fullWidth: {
        width: '100%'
    },
    smallIcon: {
        minWidth: '0px'
    },
    addItem: {
        color: theme.palette.grey
    },
    contentContainer: {
        display: 'flex',
        flexGrow: 1
    },
    followup: {
        margin: theme.spacing(2)
    },
    condition: {
        margin: theme.spacing(2)
    },
    textField: {
        marginLeft: theme.spacing(1)
    },
    selectValues: {
        display: 'flex',
        // padding: theme.spacing(1)
    },
    formControl: {
        margin: theme.spacing(1)
    },
    textField: {
        margin: theme.spacing(1)
    },
    help: {
        display: 'flex',
        margin: theme.spacing(1)
    }
}));

export default function LinearScaleAdd(props) {
    const classes = useStyles();
    const [options, setOptions] = useState(props.options ? props.options : [])
    const [optionCounter, setOptionCounter] = useState(1)
    const [help, setHelp] = useState('I don\'t know, I need your help')
    const [helpChecked, setHelpChecked] = useState(props.details && props.details.help)
    const [minValue, setMinValue] = useState(1)
    const [minTitle, setMinTitle] = useState('0')
    const [maxValue, setMaxValue] = useState(1)
    const [maxTitle, setMaxTitle] = useState('1')
    const [details, setDetails] = useState({
        ...props.details,
        // question: props.details && props.details.question ? props.details.question : '',
        type: props.details && props.details.type ? props.details.type : 'linearscale',
        // answers: props.details && props.details.answers ? props.details.answers : {},
        // subquestions: props.details && props.details.subquestions ? props.details.subquestions : [],
        min: props.details && props.details.min ? props.details.min : { '1': 1 },
        max: props.details && props.details.max ? props.details.max : { '5': 5 },
        // help: props.details && props.details.help ? props.details.help : undefined,
    })

    useEffect(() => {
        console.log('props.details', props.details)
        updateLocalState()
    }, [])

    useEffect(() => {
        updateLocalState()
        if (props.onChange) props.onChange(details)
    }, [details])

    const updateLocalState = () => {
        setMinTitle(Object.keys(details.min)[0] !== '' + details.min[Object.keys(details.min)[0]] ? Object.keys(details.min)[0] : '')
        setMaxTitle(Object.keys(details.max)[0] !== '' + details.max[Object.keys(details.max)[0]] ? Object.keys(details.max)[0] : '')

        setMinValue(details.min[Object.keys(details.min)[0]])
        setMaxValue(details.max[Object.keys(details.max)[0]])

    }

    const handleSave = (event) => {
        console.log('details', details)
        if (props.handleSave) props.handleSave(details)
    }

    const handleCancel = (event) => {
        if (props.handleCancel) props.handleCancel();
    }

    const handleAddClick = (e) => {
        let currOptions = [...options]
        currOptions.push('Option ' + optionCounter)
        setOptions(currOptions)
        setOptionCounter(optionCounter + 1)
    }

    const handleAddOther = (e) => {
        let currOptions = [...options]
        currOptions.push('Other')
        setOptions(currOptions)
    }

    const handleDelete = (option) => {
        if (!option) return
        let currOptions = options.filter(o => o !== option)
        setOptions(currOptions)
    }

    return (
        <div className={classes.root}>
            <Divider />
            <div className={classes.selectValues}>
                <TextField
                    id="min_title"
                    label="Min Title (Optional)"
                    placeholder="Min Title (Optional)"
                    value={minTitle}
                    margin="small"
                    variant="outlined"
                    fullWidth
                    // style={{ width: "97%", margin: "8pt" }}
                    className={classes.textField}
                    defaultValue={minTitle}
                    onChange={(e) => {
                        let d = JSON.parse(JSON.stringify(details))
                        if (!Object.keys(d.min) || Object.keys(d.min).length === 0) d.min[e.target.value] = 0
                        let value = d.min[Object.keys(d.min)[0]]
                        d.min = {}
                        d.min[e.target.value] = value
                        setDetails(d)
                    }}
                />
                <TextField
                    id="max_title"
                    label="Max Title (Optional)"
                    placeholder="Max Title (Optional)"
                    value={maxTitle}
                    margin="small"
                    variant="outlined"
                    fullWidth
                    // style={{ width: "97%", margin: "8pt" }}
                    className={classes.textField}
                    defaultValue={maxTitle}
                    onChange={(e) => {
                        let d = JSON.parse(JSON.stringify(details))
                        if (!Object.keys(d.max) || Object.keys(d.max).length === 0) d.max[e.target.value] = 0
                        let value = d.max[Object.keys(d.max)[0]]
                        d.max = {}
                        d.max[e.target.value] = value
                        setDetails(d)
                    }}
                />
            </div>
            <div className={classes.selectValues}>
                <FormControl variant="outlined" className={classes.formControl} fullWidth>
                    <InputLabel htmlFor="min">Min</InputLabel>
                    <Select
                        fullWidth
                        labelId={'min'}
                        value={minValue}
                        className={classes.selectField}
                        onChange={(e) => {
                            let d = JSON.parse(JSON.stringify(details))
                            if (!Object.keys(d.min) || Object.keys(d.min).length === 0) d.min = { 'label': 0 }
                            d.min[Object.keys(d.min)[0]] = e.target.value
                            setDetails(d)
                        }}
                        label="Min"
                        renderValue={(value) => { return value }}
                    >
                        {[0, 1].map((r) => <MenuItem key={r + "_min_item"} value={r}>{r}</MenuItem>)}
                    </Select>
                </FormControl>
                <FormControl variant="outlined" className={classes.formControl} fullWidth>
                    <InputLabel htmlFor="max">Max</InputLabel>
                    <Select
                        fullWidth
                        labelId={'max'}
                        value={maxValue}
                        className={classes.selectField}
                        onChange={(e) => {
                            let d = JSON.parse(JSON.stringify(details))
                            if (!Object.keys(d.max) || Object.keys(d.max).length === 0) d.max = { 'label': 5 }
                            d.max[Object.keys(d.max)[0]] = e.target.value
                            setDetails(d)
                        }}
                        label="Max"
                        renderValue={(value) => { return value }}
                    >
                        {[...range(1, 11)].map((r) => <MenuItem key={r + "_max_item"} value={r}>{r}</MenuItem>)}
                    </Select>
                </FormControl>
            </div>
            <div className={classes.help}>
                <Checkbox
                    size="small"
                    edge="start"
                    checked={helpChecked}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ 'aria-labelledby': 'add' }}
                    onChange={(e) => {
                        let d = JSON.parse(JSON.stringify(details))
                        if (!helpChecked === true) {
                            d.help = {}
                            d.help[help] = true
                        }
                        else d.help = false
                        setDetails(d)
                        setHelpChecked(!helpChecked)
                    }}
                />
                <TextField
                    id="help"
                    // label="Other"
                    disabled={!helpChecked}
                    value={help}
                    placeholder="Other"
                    margin="small"
                    // variant="outlined"
                    fullWidth
                    className={classes.textField}
                    onChange={(e) => {
                        let d = JSON.parse(JSON.stringify(details))
                        if (!Object.keys(d.help) || Object.keys(d.help).length === 0)
                            d.help[e.target.value] = helpChecked
                        d.help = {}
                        d.help[e.target.value] = helpChecked
                        if (helpChecked) setDetails(d)
                        setHelp(Object.keys(d.help)[0])
                        setHelpChecked(d.help[Object.keys(d.help)[0]])
                    }}
                />
            </div>
            {/* <Divider /> */}
            {/* <div className={classes.contentContainer}>
                <div className={classes.followup}>
                    <Typography> Follow up Question</Typography>
                    <Followup details={details.followup} type={"boolean"} />
                </div>
                <div className={classes.condition}>
                    <Typography> Condition </Typography>
                    <Condition details={details.condition} type={"boolean"} />
                </div>
            </div> */}
        </div>
    )
}
