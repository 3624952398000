import { initializeApp } from "firebase/app"
import { getFirestore } from "firebase/firestore"
import { getStorage } from "firebase/storage"
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';

export const firebaseConfig = {
  apiKey: "AIzaSyCqkK3M7W6v-5KRiEzPvbSJg18KFYX59WY",
  authDomain: "ergomove.firebaseapp.com",
  projectId: "ergomove",
  storageBucket: "ergomove.appspot.com",
  messagingSenderId: "552458083058",
  appId: "1:552458083058:web:6b9fea575a3e942bc3ce6b",
  measurementId: "G-BLYGDDHF6Y"
};

export const firebaseApp = initializeApp(firebaseConfig);
export const db = getFirestore();
export const storage = getStorage();
export const functions = getFunctions();
// functions.useFunctionsEmulator("http://localhost:5001");
connectFunctionsEmulator(functions, 5002)
