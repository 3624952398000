import React, { useContext, useEffect, useRef, useState } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import _ from 'lodash';
import Paper from "@material-ui/core/Paper";
import { UserContext } from "../context/UserContext/context";
import { DataContext } from "../context/DataContext/context";
import AuthController from "../controllers/AuthController";
import Utils from "../utils/Utils";
import DataController from "../controllers/DataController"
import { Button, Checkbox, Collapse, Dialog, Fab, Grid, IconButton, List, ListItem, ListItemIcon, ListItemText, Step, StepConnector, StepLabel, Stepper, Typography } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import QuestionListItem from "../components/QuestionListItem";
import QuestionAdd from "../components/QuestionAdd";
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { useParams } from "react-router-dom";
import { withStyles } from "@material-ui/styles";


const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexGrow: 1,
        justifyContent: "center",
        verticalAlign: "middle"
    },
    questionContainer: {
        width: '80%'
    },
    questionStepper: {
        background: 'none'
    },
    questionBtn: {
        display: 'flex',
        flexGrow: 1,
        width: '100%'
    },
    questionNextBtn: {
        justifyContent: 'end',
        display: 'flex',
        flexGrow: 1,
    },
    questionBackBtn: {
        justifyContent: 'start',
        display: 'flex',
        flexGrow: 1,
    }
}));

export default function QuestionnaireAnswer({ props }) {
    const { user } = useContext(UserContext);
    const { data } = useContext(DataContext)
    let { type } = useParams();
    const [questions, setQuestions] = useState([])
    const [questionIndex, setQuestionIndex] = useState(0)
    // const { data, addQuestion, updateQuestion, deleteQuestion } = useContext(DataContext);

    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [openQuestions, setOpenQuestions] = React.useState([]);
    const selectedQuestionRef = useRef()
    const [endCondition, setEndCondition] = useState()
    const [endConditionOpen, setEndConditionOpen] = useState(false)
    const [questionType, setQuestionType] = useState('inception')
    const [exclusions, setExclusions] = useState([])
    const [activeStep, setActiveStep] = useState(0);
    const [finished, setFinished] = useState(false);
    const [nextBtnDisabled, setNextBtnDisabled] = useState(true);

    useEffect(() => {
        async function init() {
            setQuestions(await DataController.GetQuestions(questionType))
        }
        init()
    }, [])

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setNextBtnDisabled(true)
    };

    const handleBack = () => {
        if (activeStep > 0)
            setActiveStep((prevActiveStep) => prevActiveStep - 1);
        setFinished(false)
    };

    const handleSave = async (details) => {
        if (!details || !details.question || !details.type) throw "Cannot save, invalid details provided"
        if (selectedQuestionRef.current && _.isEqual(details, selectedQuestionRef.current))
            return handleClose()

        try {
            let e = await handleNewExclusions(details.exclusions)


            setOpen(false);

            selectedQuestionRef.current = null;
        } catch (error) {
            console.error(error)
        }
    };

    const handleClose = (details) => {
        selectedQuestionRef.current = null;
        setOpen(false);
    };

    const handleQuestionCardClick = (question) => {
        selectedQuestionRef.current = question
        setOpen(true);
    }

    const onCondition = (answer, question, condition) => {
        setEndCondition(condition)
        if (condition && condition.action === 'cannot_proceed') {
            setEndConditionOpen(true)
            setNextBtnDisabled(true)
        } else {
            setEndConditionOpen(false)
            setNextBtnDisabled(false)
        }
    }

    const onComplete = (answer) => {
        questions[activeStep].acceptableAnswers = Object.assign({}, questions[activeStep].answers)
        questions[activeStep].answers = answer
        // setNextBtnDisabled(false)
        if (checkConditions())
            return setEndConditionOpen(true)
        else
            setNextBtnDisabled(false)
    }

    const onFinish = async (e) => {
        if (checkConditions())
            return setEndConditionOpen(true)
        else
            setNextBtnDisabled(false)

        for(let i in questions){
            if(questions[i].acceptableAnswers) delete questions[i].acceptableAnswers
        }

        try {
            let a = await AuthController.UpdateUserAnsweredQuestionnaire(user.details.uid, questionType, questions, user.details.accessToken)
            console.log('DONE!!', a)
        } catch (e) {
            console.log(e)
            setNextBtnDisabled(false)
        }

    }
    const checkConditions = () => {
        if (!questions || !questions[activeStep].answers) return false

        for (let i in questions[activeStep].answers) {
            let answer = questions[activeStep].answers[i]
            if (!answer.followup ||
                !answer.followup.answer ||
                answer.followup.answer.value === undefined ||
                answer.followup.answer.value === null) { }
            else if (answer.followup.answer.value + '' === questions[activeStep]?.condition.when + '')
                return true
        }
        return false
    }

    const handleNewExclusions = async (qExclusions) => {
        try {
            if (!qExclusions || qExclusions.length === 0) return
            let newExclusions = qExclusions.filter(t => t.inputValue)
            for (let exclusion in newExclusions) {
                newExclusions[exclusion].name = newExclusions[exclusion].inputValue
                delete newExclusions[exclusion].inputValue
                let id = await DataController.AddExclusion(newExclusions[exclusion])
                newExclusions[exclusion].id = id.id
            }
            if (newExclusions.length > 0) {
                let d = [...exclusions]
                d.push(...newExclusions)
                setExclusions(d)
            }
            return newExclusions

        } catch (error) {
            throw error
        }
    }

    const handleExpandMoreClick = (item) => {
        if (openQuestions.filter(q => q.name === item.name).length > 0) handleExpandLessClick(item)
        let currOpens = openQuestions
        currOpens.push(item)
        setOpenQuestions(currOpens)
    }

    const handleExpandLessClick = (item) => {
        let currOpens = openQuestions.filter(q => q.name !== item.name)
        if (!currOpens || currOpens.length === 0) return
        setOpenQuestions(currOpens)
    }

    const dialogClose = () => {
        setEndConditionOpen(false)
    }

    return (
        <div className={classes.root}>
            {!finished ? <div className={classes.questionContainer}>
                <div className={classes.questionStepper}>
                    <Stepper alternativeLabel activeStep={activeStep}>
                        {questions.map((q, i) => (
                            <Step key={'step_' + i}>
                                <StepLabel></StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                </div>
                {activeStep < questions.length &&
                    <div className={classes.question}>
                        <QuestionListItem details={questions[activeStep]} onCondition={onCondition} onComplete={onComplete} />
                    </div>
                }
                <div className={classes.questionBtn}>
                    {activeStep < questions.length - 1 ?
                        <>
                            <div className={classes.questionBackBtn}>
                                <Button disabled={activeStep === 0} variant="contained" color="default" onClick={handleBack}>Previous Question</Button>
                            </div>
                            <div className={classes.questionNextBtn}>
                                <Button disabled={nextBtnDisabled} variant="contained" color="primary" onClick={handleNext}>Next Question</Button>
                            </div>
                        </>
                        :
                        <>
                            <div className={classes.questionBackBtn}>
                                <Button disabled={activeStep === 0} variant="contained" color="default" onClick={handleBack}>Previous Question</Button>
                            </div>
                            <div className={classes.questionNextBtn}>
                                <Button disabled={nextBtnDisabled} variant="contained" color="primary" onClick={onFinish}>Finish</Button>
                            </div>

                        </>
                    }
                </div>
            </div> : <div>
                Thank you for completing the questionnaire, you will now be able to start the exercises.
            </div>}
            {endCondition &&
                <Dialog
                    open={endConditionOpen}
                    onClose={dialogClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{endCondition.title ? endCondition.title : ''}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {endCondition.text}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={dialogClose} color="primary">
                            Ok
                        </Button>
                    </DialogActions>
                </Dialog>
            }
        </div>
    );
}
