import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip"; 
import { Add, Visibility } from "@material-ui/icons";
import { Fab, Grid } from "@material-ui/core";
import Icon from '@material-ui/core/Icon';

const useStyles = makeStyles((theme) =>({
  root: {
    maxWidth: "300px",
    height: "400px",
    textAlign: "center"
  }, 
  fab: {
    // marginTop: "50%" 
  },
  ActionArea:{
    height: "100%"
  }
}));

function AddCard(props) {
  const classes = useStyles();

  const handleAdd = () => { 
    if(props.onClick) props.onClick()
  };

  return (
    <Card className={classes.root}>
      <CardContent className={classes.ActionArea}>   
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justify="center"
        style={{ minHeight: '100%' }}
      > 
        <Grid item xs={3}>
          <Fab
              color="primary"
              aria-label="add"
              className={classes.fab} 
              onClick={handleAdd}
            >
              <Add />
          </Fab>
        </Grid>    
      </Grid>  
      </CardContent> 
    </Card>
  );
}

export default AddCard;
