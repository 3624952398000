import React, { useContext, useEffect, useRef, useState } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import Box from "@material-ui/core/Box";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import Badge from "@material-ui/core/Badge";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Link from "@material-ui/core/Link";
import ExerciseCard from "../components/ExerciseCard";
import { UserContext } from "../context/UserContext/context";
import QuestionnaireAnswer from "./QuestionnaireAnswer";
import { Dialog } from "@material-ui/core";
import ExerciseAdd from "../components/ExerciseAdd";
import DataController from "../controllers/DataController";
import { SET_USER } from "../context/UserContext/types";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://material-ui.com/">
        Your Website
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
  gridItem: {
    margin: theme.spacing(1),
    width: '500px'
  }
}));

export default function DashboardPage() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const { user, dispatchUser } = useContext(UserContext)

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

  return (
    <div className={classes.root}>
      <CssBaseline />

      {(user.details.questionnaires && user.details.questionnaires.length > 0) ?
        <QuestionnaireAnswer />
        : <DashboardPage2 />}
    </div>
  );
}

export function DashboardPage2() {
  const classes = useStyles();
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  const { user, dispatchUser } = useContext(UserContext)
  const selectedExerciseRef = useRef()
  const [open, setOpen] = React.useState(false);
  const [exercises, setExercises] = useState([]);


  useEffect(() => {
   async function getEx(){
    try{
      let ex = await DataController.GetUserExercises(user?.details.uid, user?.details.accessToken)
      setExercises(ex.data)

    } catch(ex) {
      console.log(ex)
    }
   }
   getEx()
  }, [])

  const handleExerciseCardClick = (exercise) => {
    console.log(exercise)
    selectedExerciseRef.current = exercise
    setOpen(true);
  }

  const handleClose = (details) => {
    console.log("close");
    selectedExerciseRef.current = null;
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />

      <Grid container>
        {(exercises && exercises.length > 0) ?
          <>
            {exercises.map((item, i) => {
              return (
                <Grid
                  item xs={12} sm={6} md={4} lg={2} xl={2}
                  className={classes.gridItem}
                  key={`item_${i}`}
                >
                  <ExerciseCard
                    exercise={item}
                    onClick={handleExerciseCardClick}
                  />
                </Grid>
              );
            })}
        </>
        : <p>No exercises to show</p>
      }
      </Grid>
    </div>
  );
}
