import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import { Switch, Route, Link } from "react-router-dom";
import { SiteContext } from '../context/SiteContext/context';
import { Box, Typography } from '@material-ui/core';
import SignUp from './SignUp';
import Background from '../content/home-background.jpg'
import GettingStarted from '../containers/GettingStarted';
import OpenRoutes from '../routes/open-routes';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        width: '100%',
        height: '89vh'
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
        // marginTop: '100px'
    },
    container: {
        height: '95vh',
        width: '100%',
        padding: '0px',
        margin: '0px'
    },
    copyright: {
        position: 'absolute',
        zIndex: '100',
        textAlign: 'center',
        width: '100%',
        bottom: '0px',
    }
}));

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://app.ergomove.co.za/">
                ErgoMOVE
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

export default function WebsiteContent(props) {
    const classes = useStyles();
    const { settings } = useContext(SiteContext)

    return (
        <div className={classes.root}>
            <CssBaseline />
            <main className={classes.content}>
                <div className={classes.appBarSpacer} />
                <div className={classes.container}>
                    <Switch>
                        {settings.openRoutes.map((prop, key) => {
                            return (
                                <Route
                                    path={prop.path}
                                    component={prop.component}
                                    key={key}
                                    exact
                                    {...props}
                                    {...prop.props}
                                />
                            );
                        })}
                         <Route
                            path={"/"}
                            component={GettingStarted}
                            key={'default_route'}
                            {...props}
                            showLogin={true}
                        />
                    </Switch>
                    {/* <GettingStarted showLogin={props.showLogin} handleShowLogin={props.handleShowLogin} /> */}
                </div>
                <Box mt={5} className={classes.copyright}>
                    <Copyright />
                </Box>
            </main>
        </div>
    );
}