import React, { useContext, useEffect, useRef, useState } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import _ from 'lodash';
import Paper from "@material-ui/core/Paper";
import { UserContext } from "../context/UserContext/context";
import { DataContext } from "../context/DataContext/context";
import Utils from "../utils/Utils";
import DataController from "../controllers/DataController"
import { Checkbox, Dialog, Fab, Grid, IconButton, List, ListItem, ListItemIcon, ListItemText, Typography } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import TeamMemberListItem from "../components/TeamMemberListItem";
import TeamMemberAdd from "../components/TeamMemberAdd";


const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexGrow: 1,
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
    fab: {
        position: "absolute",
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    gridList: {
        width: "100%",
        textAlign: "left",
    },
    gridListTile: {
        margin: theme.spacing(1),
    },
    paperContainerHeading: {
        width: "100%",
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
    },
    headingTitle: {
        padding: theme.spacing(1),
    },
    headingActionItems: {
        color: theme.palette.primary.contrastText,
        textAlign: "right",
    },
    paperContainer: {
        width: "100%",
        margin: "auto",
    },
    gridItem: {
        margin: theme.spacing(3),
    },
}));

export default function TeamMembers({ props }) {
    const { user } = useContext(UserContext);
    // const { data, addTeamMembers, updateTeamMember, deleteTeamMember } = useContext(DataContext);

    const [clients, setClients] = useState([])
    const [roles, setRoles] = useState([])
    const [members, setMembers] = useState([])

    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const selectedTeamMemberRef = useRef()

    useEffect(() => {
        async function init() {
            try {
            setClients(await DataController.GetClients(user))
            setMembers(await DataController.GetMembers(user))
            setRoles(await DataController.GetRoles(user))
            } catch(e){
                console.error(e)
            }
        }
        init()
    }, [])

    const handleAdd = (e) => {
        selectedTeamMemberRef.current = null;
        setOpen(true);
    };

    const addMember = async (member) => {
        try {
            if (!member) return

            let newMember = await DataController.AddMember(member)
            DataController.inviteMember(newMember, user.details.accessToken)

            let d = [...members]
            d.push(newMember)
            setMembers(d)
        } catch (error) {
            alert(error)
            console.error(error)
            throw error
        }
    }

    const updateMember = async (member) => {
        try {
            console.log('update member: ', member)
            let d = [...members]
            let e = d.filter(e => e.id === member.id)
            if (e.length === 0) return

            member.uid = user.details.uid
            let data = await DataController.UpdateMember(member, user.details.accessToken)
            e[0] = data
            for (let t in d) {
                if (d[t].id === member.id) {
                    d[t] = { ...member }
                    break
                }
            }
            console.log('d', d)
            setMembers(d)
        } catch (err) {
            console.log(err)
            throw err
        }
    }

    const deleteMember = async (member) => {
        if (!member || !member.id) return
        try {
            let d = [...members]
            let e = d.filter(e => e.id !== member.id)
            if (e.length === 0) return

            await DataController.DeleteMember(member, user.details.accessToken)

            d = e
            setMembers(d)
        } catch (err) {
            console.log(err)
            throw err
        }
    }

    const handleSave = (details) => {
        if (!details || !details.name) throw "Cannot save, invalid details provided"
        if (selectedTeamMemberRef.current && _.isEqual(details, selectedTeamMemberRef.current))
            return handleClose()

        try {
            if (!details.id) handleNewMember(details)
            else updateMember(details)
            setOpen(false);

            selectedTeamMemberRef.current = null;
        } catch (error) {
            console.error(error)
        }
    };

    const handleClose = (details) => {
        selectedTeamMemberRef.current = null;
        setOpen(false);
    };

    const handleMemberCardClick = (member) => {
        selectedTeamMemberRef.current = member
        setOpen(true);
    }

    const handleNewMember = (member) => {
        if (!member || !member.name || member.name.length === 0) return

        addMember(member)
    }

    const handleOnEditClicked = (member) => {
        console.log('!!!!! member', member)
        if (!member || !member.id || !member.name || member.name.length === 0) return
        handleMemberCardClick(member)
    }

    const handleMemberDelete = (member) => {
        if (!member || !member.id || !member.name || member.name.length === 0) return
        deleteMember(member)
    }

    const handleInvite = async (member) => {
        try {
            if (!member) return
            await DataController.reInviteMember(member, user.details.accessToken)
            alert('Invite sent')
        } catch (error) {
            alert(error)
            console.error(error)
            throw error
        }
    }

    return (
        <div className={classes.root}>
            <Paper className={classes.paperContainer}>
                <div className={classes.paperContainerHeading}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm container>
                            <Grid item xs container direction="column">
                                <Grid item xs>
                                    <Typography variant="h6" className={classes.headingTitle}>Team Members</Typography>
                                </Grid>
                            </Grid>
                            {user.roles.editor && <Grid item>
                                <IconButton
                                    aria-label="ListView"
                                    className={classes.headingActionItems}
                                    onClick={handleAdd}
                                >
                                    <Add />
                                </IconButton>
                            </Grid>}
                        </Grid>
                    </Grid>
                </div>
                <List>
                    {members.map((item) => {
                        return (
                            <ListItem key={item.id + "_list_item"}>
                                <TeamMemberListItem editable={user.roles.editor} details={item} key={item.id + "_list_item"} onEdit={handleOnEditClicked} onDelete={handleMemberDelete} onReInvite={handleInvite}/>
                            </ListItem>
                        );
                    })}
                </List>
            </Paper>
            {user.roles.editor && <Dialog
                onClose={handleClose}
                aria-labelledby="member-dialog-title"
                open={open}
            >
                <TeamMemberAdd
                    handleSave={handleSave}
                    handleCancel={handleClose}
                    editable={user.roles.editor}
                    clients={clients}
                    details={selectedTeamMemberRef.current}
                    client={selectedTeamMemberRef.current ? selectedTeamMemberRef.current.client : false}
                    roles={roles}
                />
            </Dialog>}
            {user.roles.editor && <Fab
                color="secondary"
                aria-label="add"
                className={classes.fab}
                onClick={handleAdd}
            >
                <Add />
            </Fab>}
        </div>
    );
}
