import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import EditIcon from '@material-ui/icons/Edit';
import ConfirmationModal from  './ConfirmationModal'
import {
  Checkbox,
  IconButton,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  useTheme,
} from "@material-ui/core";
import { Delete } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexGrow: 1,
  },
  details: {
    display: "flex",
    flexDirection: "column",
  },
  content: {
    flex: "1 0 auto",
  },
  cover: {
    width: '20%',
    minWidth: '100px'
  },
  clients: {
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.spacing(2),
  },
  controls: {
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(2),
  },
  clientHeading: {
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.spacing(2),
  },
  ClientsTitle: {
    paddingTop: "10pt",
  },
  chip: {
    position: 'absolute',
    borderRadius: '0pt',
    backgroundColor: '#fff'
  },
  clientChip: {
    borderRadius: '0pt',
    margin: '1px'
  }
}));

function ClientListItem(props) {
  const classes = useStyles();
  const [deleteConfirmationModal, setDeleteConfirmationModal] = useState(false)
  const theme = useTheme();

  const handleEditClick = e => {
    if(props.onEdit) props.onEdit(props.client)
  }

  const handleDeleteClick = e => {
    setDeleteConfirmationModal(false)
    if(props.onDelete) props.onDelete(props.client)
  }

  return (
    <div className={classes.root}>
      <ListItemText primary={props.client.name} secondary={"Users: " + props.client.numberUsers}  />
      {props.editable&& <ListItemSecondaryAction>
        <IconButton edge="end" aria-label="comments" onClick={handleEditClick}>
          <EditIcon />
        </IconButton>
        <IconButton edge="end" aria-label="comments" onClick={(e) => { setDeleteConfirmationModal(true) }}>
          <Delete />
        </IconButton>
      </ListItemSecondaryAction>}
      {props.editable&& <ConfirmationModal
        open={deleteConfirmationModal}
        onClose={(e) => { setDeleteConfirmationModal(false) }}
        onConfirm={handleDeleteClick}
      >
        You are about to delete this exercise, are you sure?
      </ConfirmationModal>}
    </div>
  );
}

export default ClientListItem;
