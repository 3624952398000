import React, { createRef, useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import { Visibility } from "@material-ui/icons";
import {
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  TextareaAutosize,
  TextField,
} from "@material-ui/core";
import Fab from "@material-ui/core/Fab";
import Edit from "@material-ui/icons/Edit";
import Autocomplete, { createFilterOptions } from "@material-ui/lab/Autocomplete";
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  paperContainer: {
    width: "95%",
    margin: "auto",
  },
  paperContainerHeading: {
    width: "100%",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    padding: "5pt",
  },
  input: {
    display: "none",
  },
  media: {
    maxWidth: "250px",
    maxHeight: "250px",
  },
  content: {
    width: "100%",
    padding: "5pt",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "48%",
    minWidth: "200px",
  },
  button: {
    margin: theme.spacing(1),
  },
  buttonContainer: {
    width: "100%"
  },
  buttonLeft: {
    float: 'left'
  },
  buttonRight: {
    float: 'right'
  },
  searchButtom: {
    width: '100%',
    borderTop: '1px solid black',
    textAlign: 'center'
  }
}));

function TagAdd(props) {
  const classes = useStyles();
  const [details, setDetails] = useState(
    props.details ? {
      id: props.details.id ? props.details.id : undefined,
      name: props.details.name ? props.details.name : ''
    } : {
      name: '',
    }
  );

  const handleSave = (event) => {
    console.log('details', details)
    if (props.handleSave) props.handleSave(details)
  }

  const handleCancel = (event) => {
    if (props.handleCancel) props.handleCancel();
  }

  return (
    <div className={classes.root}>
      <Typography variant="h6" className={classes.paperContainerHeading}>
        Add Tag
        </Typography>
      <div className={classes.content}>
        <div>
          <TextField
            id="name"
            label="Name"
            disabled={!props.editable}
            placeholder="Tag Name"
            helperText="Enter the tag name"
            margin="normal"
            variant="outlined"
            fullWidth
            style={{ width: "97%", margin: "8pt" }}
            className={classes.textField}
            defaultValue={details.name}
            onChange={(e) => {
              let d = details
              d.name = e.target.value
              setDetails(d)
            }}
          />
        </div>
      </div>
      <div className={classes.buttonContainer}>
        <div className={classes.buttonLeft}>
          <Button className={classes.button} variant="contained" onClick={handleCancel}>Cancel</Button>
        </div>
        <div className={classes.buttonRight}>
          {props.editable && <Button className={classes.button} variant="contained" onClick={handleSave} color="primary">
            Save
          </Button>}
        </div>
      </div>
    </div>
  );
}

export default TagAdd;
