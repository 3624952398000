import React, { useContext, useEffect, useRef, useState } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import _ from 'lodash';
import Paper from "@material-ui/core/Paper";
import { UserContext } from "../context/UserContext/context";
import { DataContext } from "../context/DataContext/context";
import Utils from "../utils/Utils";
import DataController from "../controllers/DataController"
import { Button, Card, CardActions, CardContent, Checkbox, Dialog, Fab, Grid, IconButton, List, ListItem, ListItemIcon, ListItemText, Typography } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import TagListItem from "../components/TagListItem";
import TagAdd from "../components/TagAdd";
import PayFastForm from "../components/PayFastForm";
import AuthController from "../controllers/AuthController";
import PaymentController from "../controllers/PaymentController";
import PayStackForm from "../components/PayStackForm";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexGrow: 1,
        marginTop: '50px'
    },
    pricingItem: {
        maxWidth: '300px'
    },
    action: {
        padding: '10pt'
    },
    title: {
        textAlign: 'center',
        padding: '10pt'
    },
    cardContent: {
        textAlign: 'center'
    }
}));

export default function Pricing({ props }) {
    const classes = useStyles();
    const { user } = useContext(UserContext)
    const [signature, setSignature] = useState()
    const [products, setProducts] = useState([])
    const [showPayment, setShowPayment] = useState(false)
    let history = useHistory();

    useEffect(() => {
        async function fetchData() {
            await getProducts()
        }
        fetchData();
    }, [])

    // useEffect(() => {
    //     getSignature()
    //     setShowPayment(true)
    //     async function fetchData() {
    //         await getProducts()
    //     }
    //     fetchData();
    //     // getProducts()
    // }, [user])

    const getProducts = async () => {
        // setProduct({
        //     email,
        //     amount,
        //     metadata: {
        //         name,
        //         phone,
        //     },
        //     publicKey,
        //     text: "Pay Now",
        //     onSuccess: () =>
        //         alert("Thanks for doing business with us! Come back soon!!"),
        //     onClose: () => alert("Wait! You need this oil, don't go!!!!"),
        // })
        try {
            if (!user || !user.details || !user.details.clientId) {
                let productResponse = await AuthController.GetProducts()
                productResponse.data.forEach(p => {
                    console.log('element', p)
                    p.plan = p.plan_code
                });
                setProducts(productResponse.data)
                setShowPayment(false)
            } else {
                let productResponse = await AuthController.GetUserProducts({ id: user.details.clientId }, user.details.accessToken)
                productResponse.data.map(p => {
                    if(p.plan_code) p.config.plan = p.plan_code
                    p.config.onSuccess = onSuccess;
                    p.config.onClose = onClose
                })
                setProducts(productResponse.data)
                setShowPayment(true)
            }
        } catch (e) {
            console.error('s error', e)
            setShowPayment(false)
        }
    }

    const onSuccess = (response) => {
        // alert("Thanks for doing business with us! Come back soon!!")
        try {
            async function fetchData() {
                let verifyResponse = await PaymentController.VerifyPayment(user.details.clientId, response.reference)
                if (verifyResponse.success){
                    alert("Thanks for doing business with us!")
                    history.push('/profile')
                }
                else alert("OOps! Payment was unsuccessful")
            }
            fetchData()
        } catch (e) {
            console.error(e)
            alert("OOps! Payment was unsuccessful")
        }
    }

    const onClose = () => {
        alert("Wait! You need this oil, don't go!!!!")
    }
    const getSignature = async () => {
        try {
            if (!user || !user.details || !user.details.clientId) return
            let productResponse = await AuthController.ProductPaymentSignature({ id: user.details.clientId }, user.details.accessToken)
            setProducts(productResponse.data.product)
            setSignature(productResponse.data.signature)
            setShowPayment(true)
        } catch (e) {
            console.error('s error', e)
            setShowPayment(false)
        }
    }

    const getStarted = () => {

    }

    return (
        <div>
            <Grid className={classes.root} container spacing={2} >
                <Grid container justifyContent="center" spacing={10}>
                    <Grid key={"product_title_grid"} item className={classes.title} xs={12}>
                        <Typography variant="h4"> Choose Your Subscription </Typography>
                    </Grid>
                </Grid>
                <Grid container justifyContent="center" spacing={3}>
                    {products.map(product => {
                        if(product.active)
                            return <Grid key={"product_normal_grid"} item className={classes.pricingItem} xs={12} sm={6} l={4}>
                                <Card >
                                    <CardContent className={classes.cardContent}>
                                        <Typography> <b>{product.name}</b></Typography>
                                        <Typography variant="h6" color="secondary" className={classes.amount}> {`${product.currency} ${product.amount / 100} ${product.recurring ? `/ ${product.recurring.type_title}` : ''}`} </Typography>
                                        {product.recurring && <Typography variant="body" >
                                            {product.recurring.title}
                                        </Typography>}
                                        <br />
                                        <br />
                                        <Typography variant="body">
                                            {product.description}
                                        </Typography>
                                    </CardContent>
                                    <CardActions className={classes.action}>
                                        {showPayment ?
                                            <PayStackForm
                                                config={product.config} />
                                            : <Button className={classes.action} variant="contained" fullWidth color={'secondary'} href="/signup" >Sign Up</Button>
                                        }
                                    </CardActions>
                                </Card>
                            </Grid>
                        return <></>
                    })}
                </Grid>
            </Grid>
        </div>
    );
}
