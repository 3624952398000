import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Slider,
  TextField,
} from "@material-ui/core";
import { Delete } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: theme.spacing(2)
  },
}));

export default function Checkboxes(props) {
  const classes = useStyles();
  const [questions, setQuestions] = useState([])
  const [answer, setAnswer] = useState({})
  const [checked, setChecked] = useState([])
  const [marks, setMarks] = useState([])
  const [steps, setSteps] = useState(1)
  const [value, setValue] = useState(0)
  const [min, setMin] = useState()
  const [max, setMax] = useState()

  useEffect(() => {
    console.log('props', props)

    setQuestions(props.details.answers)

    let a = { ...answer }
    if (!a[props.details.question])
      a[props.details.question] = {
        answer: {}
      }
    Object.keys(props.details.answers).map(key => {
      a[props.details.question].answer[key] = {
        key: key,
        value: props.details.answers[key]
      }
    })
    setAnswer(a)

  }, [])

  const handleChange = (event, newValue, column) => {

    let a = { ...answer }
    if (!a[props.details.question])
      a[props.details.question] = {
        answer: {}
      }

    a[props.details.question].answer[column] = {
      key: column,
      value: newValue
    }
    if (!newValue) a[props.details.question].answer = {}
    setAnswer(a)


    if (typeof newValue === 'boolean') {
      let newChecked = checked
      let alreadyChecked = newChecked.indexOf(column)
      if (alreadyChecked < 0) newChecked.push(column)
      else newChecked.splice(alreadyChecked, 1)
      setChecked(newChecked)
      if(props.onComplete) {
        props.onComplete(newChecked)
      }
    }

  }

  return (
    <div className={classes.root}>
      {props.details.answers &&
        <>
          {Object.keys(props.details.answers).map(key => {
            if (key === 'Other') return <></>
            if (typeof props.details.answers[key] === 'boolean') {
              return <>
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox
                      checked={checked.includes(key)}
                      onChange={(event) => {
                        handleChange(event, !(answer && answer[props.details.question] && answer[props.details.question].answer && answer[props.details.question].answer.key === key), key)
                      }}
                      inputProps={{ 'aria-label': 'primary checkbox' }}
                    />}
                    label={key}
                  />
                </FormGroup>
              </>
            } else if (typeof props.details.answers[key] === 'string') {
              return <TextField
                id={key + "_txt"}
                label={key}
                placeholder={key}
                margin="normal"
                variant="outlined"
                className={classes.textField}
                defaultValue={props.details.answers[key]}
                onChange={(event) => {
                  handleChange(event, event.target.value, key)
                }}
              />
            }

            return (<></>)
          })}
          {(props.details.answers['Other'] !== undefined && props.details.answers['Other'] !== null) &&
            <TextField
              id={"other" + "_txt"}
              label={"Other"}
              placeholder={"Other"}
              margin="normal"
              variant="outlined"
              className={classes.textField}
              defaultValue={props.details.answers["Other"]}
              onChange={(event) => {
                handleChange(event, event.target.value, "Other")
              }}
            />
          }
        </>
      }

    </div>
  )
}