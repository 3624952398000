import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import EditIcon from '@material-ui/icons/Edit';

import {
  Checkbox,
  IconButton,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  useTheme,
} from "@material-ui/core";
import { Delete } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexGrow: 1,
  },
  details: {
    display: "flex",
    flexDirection: "column",
  },
  content: {
    flex: "1 0 auto",
  },
  cover: {
    width: '20%',
    minWidth: '100px'
  },
  tags: {
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.spacing(2),
  },
  controls: {
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(2),
  },
  tagHeading: {
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.spacing(2),
  },
  TagsTitle: {
    paddingTop: "10pt",
  },
  chip: {
    position: 'absolute',
    borderRadius: '0pt',
    backgroundColor: '#fff'
  },
  tagChip: {
    borderRadius: '0pt',
    margin: '1px'
  }
}));

function ExclusionListItem(props) {
  const classes = useStyles();
  const theme = useTheme();

  const handleEditClick = e => {
    if(props.onEdit) props.onEdit(props.exclusion)
  }

  const handleDeleteClick = e => {
    if(props.onDelete) props.onDelete(props.exclusion)
  }

  return (
    <div className={classes.root}>
      <ListItemText primary={props.exclusion.name} />
      {props.editable&& <ListItemSecondaryAction>
        <IconButton edge="end" aria-label="comments" onClick={handleEditClick}>
          <EditIcon />
        </IconButton>
        <IconButton edge="end" aria-label="comments" onClick={handleDeleteClick}>
          <Delete />
        </IconButton>
      </ListItemSecondaryAction>}
    </div>
  );
}

export default ExclusionListItem;
