import React, { useContext, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import { Visibility } from "@material-ui/icons";
import { Box, GridList } from "@material-ui/core";
import HorizontalChipSlider from "./HorizontalChipSlider";
import ConfirmationModal from "./ConfirmationModal";
import { UserContext } from "../context/UserContext/context";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "300px",
    width: '100%',
    height: "400px",
  },
  tagsTitle: {
    paddingTop: "10pt",
  },
  chip: {
    position: "absolute",
    borderRadius: "0pt",
    backgroundColor: "#fff",
  },
  tagChip: {
    borderRadius: "0pt",
    margin: "1px",
  },
  tagsContainer: {
    width: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    flexWrap: 'nowrap',
    transform: 'translateZ(0)',
    // '::-webkit-scrollbar': {
    //   height: '1px',
    // }
  },
  description: {
    maxHeight: '70px',
    overflow: 'hidden',
    wordWrap: "break-word"
  },
  gridList: {
    flexWrap: 'nowrap',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
  },
}));

function ExerciseCard(props) {
  const classes = useStyles();
  const { user } = useContext(UserContext)
  const [copyConfirmationModal, setCopyConfirmationModal] = useState(false)

  const handleCardClick = () => {
    if (props.onClick) props.onClick(props.exercise);
  };
  const handleCopy = (e) => {
    if (props.handleCopy) props.handleCopy(props.exercise);
  }

  return (
    <Card className={classes.root}>
      <CardActionArea onClick={handleCardClick}>
        <Chip
          icon={<Visibility />}
          className={classes.chip}
          size="small"
          label={props.exercise.views ? props.exercise.views : 0}
        />
        <CardMedia
          component="img"
          alt={props.exercise.name}
          height="140"
          image={
            props.exercise.thumbnail
              ? props.exercise.thumbnail
              : "placeholder.jpg"
          }
          title={props.exercise.name}
        />
      </CardActionArea>
      <CardContent>
          <Typography variant="h5">{props.exercise.name}</Typography>
          <Typography variant="body2" color="textSecondary" component="p" className={classes.description} >
            {props.exercise.description}
          </Typography>
          <Typography variant="subtitle1" className={classes.TagsTitle}>
            Tags
          </Typography>
          <div className={classes.tagsContainer} >
             {props.exercise.tags &&
                props.exercise.tags.map((prop, key) => {
                  return (
                    <Chip
                      size='small'
                      key={prop + key}
                      className={classes.tagChip}
                      label={prop.name}
                    />
                  );
                })}

                {/* <HorizontalChipSlider content={props.exercise.tags} numElements={2}/> */}
          </div>
        </CardContent>
      <CardActions>
        {user.roles.viewer&& <Button size="small" color="primary" onClick={handleCardClick}>
            View Exercise
          </Button>}
          {user.roles.editor&& <Button  size="small" color="primary" onClick={(e) => { setCopyConfirmationModal(true) }}>
            Make Copy
          </Button>}
      </CardActions>

      {user.roles.editor&& <ConfirmationModal
        open={copyConfirmationModal}
        onClose={(e) => { setCopyConfirmationModal(false) }}
        onConfirm={(e) => {
          setCopyConfirmationModal(false)
          handleCopy()
        }}
      >
        You are about to make a copy of this exercise. Are you sure?
      </ConfirmationModal>}

    </Card>
  );
}

export default ExerciseCard;
