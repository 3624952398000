import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import {
  Checkbox,
  FormControlLabel,
  Slider,
} from "@material-ui/core";
import { Delete } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: theme.spacing(2)
  },
}));

export default function LinearScale(props) {
  const classes = useStyles();
  const [questions, setQuestions] = useState([])
  const [answer, setAnswer] = useState({})
  const [checked, setChecked] = useState([])
  const [marks, setMarks] = useState([])
  const [steps, setSteps] = useState(1)
  const [value, setValue] = useState(0)
  const [min, setMin] = useState()
  const [max, setMax] = useState()

  useEffect(() => {
    console.log('props', props)

    setQuestions(props.details.subquestions)

    let newMin = min, newMax = max
    if (props.details.min && typeof props.details.min === 'object' && (!newMin || props.details.min[Object.keys(props.details.min)[0]] < newMin.value)) newMin = { label: Object.keys(props.details.min)[0], value: props.details.min[Object.keys(props.details.min)[0]] }
    if (props.details.max && typeof props.details.max === 'object' && (!newMax || props.details.max[Object.keys(props.details.max)[0]] > newMax.value)) newMax = { label: Object.keys(props.details.max)[0], value: props.details.max[Object.keys(props.details.max)[0]] }

    setMin(newMin)
    setMax(newMax)

    setMarks([newMin, newMax])

    if (props.details.steps) setSteps(props.details.steps)

  }, [])

  const handleChange = (event, newValue, column) => {

    let a = { ...answer }
    if (newValue && typeof newValue === 'number') {
      setValue(newValue)
      setChecked([])
      a[props.details.question] = {
        answer: {
          key: column,
          value: newValue
        }
      }
      setAnswer(a)

    } else {
      setValue(0)
      a[props.details.question] = {
        answer: {
          key: column,
          value: newValue
        }
      }
      if (!newValue) a[props.details.question].answer = {}
      setAnswer(a)


      let newChecked = checked
      let alreadyChecked = newChecked.indexOf(column)
      if (alreadyChecked < 0) newChecked.push(column)
      else newChecked.splice(alreadyChecked, 1)
      setChecked(newChecked)
    }
    if (props.onComplete) {
      props.onComplete(a)
    }


  }

  const valuetext = (value) => {
    return value
  }

  return (
    <div className={classes.root}>
      <Slider
        value={value}
        defaultValue={1}
        getAriaValueText={valuetext}
        aria-labelledby="discrete-slider"
        valueLabelDisplay="on"
        onChange={(event, newValue) => {
          handleChange(event, newValue, 'slider')
        }}
        step={steps}
        marks={marks}
        min={min && min.value ? min.value : 0}
        max={max && max.value ? max.value : 1}
      />
      {props.details.help &&
        Object.keys(props.details.help).map(key => {
          if (typeof props.details.help[key] === 'boolean') {
            return <>
              <FormControlLabel
                control={<Checkbox
                  checked={checked.includes(key)}
                  onChange={(event) => {
                    handleChange(event, !(answer && answer[props.details.question] && answer[props.details.question].help && answer[props.details.question].help.key === key), key)
                  }}
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />}
                label={key}
              />
            </>
          }

          return (<></>)
        })
      }
    </div>
  )
}