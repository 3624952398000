import {SET_DATA} from './types';

export const reducer = (state, action) => {
    switch (action.type) {
      case 'SET_DATA':
        return [
          ...state,
          action.data
        ];
      default:
        return state;
    }
  };
