import React, { useContext, useEffect, useRef, useState } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import _, { camelCase } from 'lodash';
import Paper from "@material-ui/core/Paper";
import { UserContext } from "../context/UserContext/context";
import { DataContext } from "../context/DataContext/context";
import Utils from "../utils/Utils";
import DataController from "../controllers/DataController"
import { Checkbox, Dialog, Fab, Grid, IconButton, List, ListItem, ListItemIcon, ListItemText, Typography } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import ClientListItem from "../components/ClientListItem";
import ClientAdd from "../components/ClientAdd";


const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexGrow: 1,
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
    fab: {
        position: "absolute",
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    gridList: {
        width: "100%",
        textAlign: "left",
    },
    gridListTile: {
        margin: theme.spacing(1),
    },
    paperContainerHeading: {
        width: "100%",
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
    },
    headingTitle: {
        padding: theme.spacing(1),
    },
    headingActionItems: {
        color: theme.palette.primary.contrastText,
        textAlign: "right",
    },
    paperContainer: {
        width: "100%",
        margin: "auto",
    },
    gridItem: {
        margin: theme.spacing(3),
    },
}));

export default function Clients({ props }) {
    const { user } = useContext(UserContext);
    // const { clients, getClients, dispatchClients, addClient, updateClient, deleteClient } = useContext(DataContext);

    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const selectedClientRef = useRef()
    const [clients, setClients] = useState([])

    // useEffect(() => {
    //     getClients()
    // }, [])

    useEffect(() => {
        async function init() {
            let t = await DataController.GetClients(user)
            setClients(t)
        }
        init()
    }, [])

    const handleAdd = (e) => {
        selectedClientRef.current = null;
        setOpen(true);
    };

    const addClient = async (client) => {
        try {
            if (!client) return

            let newClient = await DataController.AddClient(client)

            let d = [...clients]
            d.push(newClient)
            setClients(d)
        } catch (error) {
            throw error
        }
    }

    const updateClient = async (client) => {
        try {
            console.log('update client: ', client)
            let d = [...clients]
            let e = d.filter(e => e.id === client.id)
            if (e.length === 0) return

            let data = await DataController.UpdateClient(client)
            e[0] = data
            for (let t in d) {
                if (d[t].id === client.id) {
                    d[t] = { ...client }
                    break
                }
            }
            console.log('d', d)
            setClients(d)
        } catch (err) {
            console.log(err)
            throw err
        }
    }

    const deleteClient = async (client) => {
        if (!client || !client.id) return
        try {
            let d = [...clients]
            let e = d.filter(e => e.id !== client.id)
            if (e.length === 0) return

            await DataController.DeleteClient(client)

            d = e
            setClients(d)
        } catch (err) {
            console.log(err)
            throw err
        }
    }

    const handleSave = (details) => {
        if (!details || !details.name) throw "Cannot save, invalid details provided"
        if (selectedClientRef.current && _.isEqual(details, selectedClientRef.current))
            return handleClose()

        try {
            if (!details.id) handleNewClient(details)
            else updateClient(details)
            setOpen(false);

            selectedClientRef.current = null;
        } catch (error) {
            console.error(error)
        }
    };

    const handleClose = (details) => {
        selectedClientRef.current = null;
        setOpen(false);
    };

    const handleClientCardClick = (client) => {
        selectedClientRef.current = client
        setOpen(true);
    }

    const handleNewClient = (client) => {
        if (!client || !client.name || client.name.length === 0) return

        addClient(client)
    }

    const handleOnEditClicked = (client) => {
        if (!client || !client.id || !client.name || client.name.length === 0) return
        handleClientCardClick(client)
    }

    const handleClientDelete = client => {
        if (!client || !client.id || !client.name || client.name.length === 0) return
        deleteClient(client)
    }

    return (
        <div className={classes.root}>
            <Paper className={classes.paperContainer}>
                <div className={classes.paperContainerHeading}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm container>
                            <Grid item xs container direction="column">
                                <Grid item xs>
                                    <Typography variant="h6" className={classes.headingTitle}>Clients</Typography>
                                </Grid>
                            </Grid>
                            {user.roles.editor&& <Grid item>
                                <IconButton
                                    aria-label="ListView"
                                    className={classes.headingActionItems}
                                    onClick={handleAdd}
                                >
                                    <Add />
                                </IconButton>
                            </Grid>}
                        </Grid>
                    </Grid>
                </div>
                <List>
                    {
                        clients.map((item) => {
                            return (
                                <ListItem>
                                    <ClientListItem editable={user.roles.editor } client={item} onEdit={handleOnEditClicked} onDelete={handleClientDelete} />
                                </ListItem>
                            );
                        })}
                </List>
            </Paper>
            <Dialog
                onClose={handleClose}
                aria-labelledby="simple-dialog-title"
                open={open}
            >
                <ClientAdd
                    handleSave={handleSave}
                    handleCancel={handleClose}
                    editable={
                        user.roles.editor
                    }
                    clients={clients}
                    details={selectedClientRef.current}
                />
            </Dialog>
            {user.roles.editor&& <Fab
                color="secondary"
                aria-label="add"
                className={classes.fab}
                onClick={handleAdd}
            >
                <Add />
            </Fab>}
        </div>
    );
}
