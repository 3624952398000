import React, { createContext, useReducer } from "react";
import { reducer } from "./reducer";

export const UserContext = createContext();

const UserProvider = ({ children }) => {
  const [user, dispatchUser] = useReducer(reducer, {
      isAuthenticated: false,
      isAuthorized: false,
      isVerifying: false,
      details: null,
      roles: null,
      error: null,
      exercises: []
  })

  return <UserContext.Provider value={{ user, dispatchUser }}>{children}</UserContext.Provider>
}

export default UserProvider;
