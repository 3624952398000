import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import { Switch, Route } from "react-router-dom";
import { SiteContext } from '../context/SiteContext/context';
import { UserContext } from '../context/UserContext/context';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        width: '100%'
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },

    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
        maxWidth: '100%'
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    }
}));

export default function PortalContent() {
    const classes = useStyles();
    const { settings } = useContext(SiteContext)
    const { user } = useContext(UserContext)
    const [routes, setRoutes] = useState([])

    const hasAccess = (roles) => {
        if (!roles || roles.length === 0) return true

        return roles.some(r => user.roles[r])
    }

    useEffect(() => {
        let tmp = []
        settings.routes.map(r => {
            console.log('!! routes', r)
            if(hasAccess(r.roles)) tmp.push(r)
        })
        setRoutes(tmp)
    }, [])

    return (
        <div className={classes.root}>
            <CssBaseline />
            <main className={classes.content}>
                <div className={classes.appBarSpacer} />
                <Container className={classes.container}>
                    <Switch>
                        {routes.map((prop, key) => {
                            return (
                                <Route
                                    path={prop.path}
                                    component={prop.component}
                                    key={key}
                                    exact
                                />
                            );
                        })}
                    </Switch>
                </Container>
            </main>
        </div>
    );
}