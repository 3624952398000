import React, { useEffect, useState } from 'react';
import { Chip, IconButton, makeStyles, Slide } from '@material-ui/core';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        width: '100%'
    }, 
    chipContainer: { 
        width: '100%'
    }
}));

function Arrow(props) {
    const { direction, clickFunction } = props;
    const icon = direction === 'left' ? <ChevronLeft /> : <ChevronRight />;

    return <IconButton onClick={clickFunction}>{icon}</IconButton >;
}

function SingleChip(props){
    
    return(
        <Chip
            key={props.name+'_chip'}
            // className={classes.tagChip}
            label={props.name}
            size='small'
        />
    )
}

export default function HorizontalChipSlider(props) {
    const classes = useStyles();
    const [index, setIndex] = useState(0);
    const [content, setContent] = useState([]);
    const [numSlides, setNumSlides] = useState(0);
    const numElements = props.numElements ? props.numElements : 3;
    const [slideIn, setSlideIn] = useState(true);
    const [slideDirection, setSlideDirection] = useState('down');

    useEffect(()=>{
        if(props.content) {
            setContent(props.content.slice(index, numElements));
            setNumSlides(props.content.length / numElements)
        }
    },[])

    const onArrowClick = (direction) => {
        const increment = direction === 'left' ? -numElements : numElements;
        const newIndex = (index + increment + numSlides) % numSlides;

        const oppDirection = direction === 'left' ? 'right' : 'left';
        setSlideDirection(direction);
        setSlideIn(false);

        setTimeout(() => {
            setIndex(newIndex);
            setContent(props.content.slice(newIndex, numElements));
            setSlideDirection(oppDirection);
            setSlideIn(true);
        }, 0);
    };

    return (
        <div className={classes.root}>
            <Arrow
                direction='left'
                clickFunction={() => onArrowClick('left')}
            /> 
            <Slide in={slideIn} direction={slideDirection} className={classes.chipContainer}>
                <div>
                    {/* <CarouselSlide content={content} /> */}
                    {
                        content.map(item => {
                            return <SingleChip name={item.name}/>
                        })
                    }
                </div>
            </Slide>
            <Arrow
                direction='right'
                clickFunction={() => onArrowClick('right')}
            /> 
        </div>
    );
}