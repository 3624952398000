import React, { createRef, useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import { Visibility } from "@material-ui/icons";
import {
  Checkbox,
  Container,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  TextareaAutosize,
  TextField,
} from "@material-ui/core";
import Fab from "@material-ui/core/Fab";
import Edit from "@material-ui/icons/Edit";
import Autocomplete, { createFilterOptions } from "@material-ui/lab/Autocomplete";
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { useHistory } from "react-router-dom";
import QuestionType from "./QuestionType";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  paperContainer: {
    width: "95%",
    margin: "auto",
  },
  paperContainerHeading: {
    width: "100%",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    padding: "5pt",
  },
  input: {
    display: "none",
  },
  media: {
    maxWidth: "250px",
    maxHeight: "250px",
  },
  content: {
    width: "100%",
    padding: "5pt",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "48%",
    minWidth: "200px",
  },
  selectField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "98%",
    minWidth: "200px",
  },
  button: {
    margin: theme.spacing(1),
  },
  buttonContainer: {
    width: "100%"
  },
  buttonLeft: {
    float: 'left'
  },
  buttonRight: {
    float: 'right'
  },
  searchButtom: {
    width: '100%',
    borderTop: '1px solid black',
    textAlign: 'center'
  },
  itemHeading: {
    display: 'flex',
    flexGrow: 1
  },
  formControl: {
    marginTop: theme.spacing(1),
    paddingRight: theme.spacing(1)
  }
}));

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const filter = createFilterOptions();

function QuestionAdd(props) {
  const classes = useStyles();
  const exclusionsRef = useRef();
  const [newExclusions, setNewExclusions] = useState()
  const [questionType, setQuestionType] = useState()
  const [questionTypes, setQuestionTypes] = useState([
    { name: 'Multiple Choice', value: 'multiplechoice' },
    { name: 'Checkboxes', value: 'checkboxes' },
    { name: 'Linear Scale', value: 'linearscale' },
    // { name: 'Short Answer', value: 'short' },
    // { name: 'Multiple Choice Grid', value: 'multiplechoicegrid' },
    // { name: 'Checkbox Grid', value: 'checkboxgrid' },
  ])
  const [details, setDetails] = useState(
    props.details ? {
      ...props.details,
      exclusions: props.details.exclusions ? props.details.exclusions : [],
      // id: props.details.id ? props.details.id : undefined,
      // question: props.details.question ? props.details.question : '',
      // type: props.details.type ? props.details.type : '',
      // answers: props.details.answers ? props.details.answers : {},
      // condition: props.details.condition ? props.details.condition : {},
      // followup: props.details.followup ? props.details.followup : {},
      // question_type: props.details.question_type ? props.details.question_type : '',
      // subquestions: props.details.subquestions ? props.details.subquestions : [],
    } : {
      name: '',
      type: '',
      exclusions : []
    }
  );

  useEffect(() => {
    console.log('props.details', props.details)
    console.log('details', details)
    console.log('questionType', questionType)
    if (details.type) {
      let types = questionTypes.filter(t => t.value === details.type)
      if (types && types.length > 0)
        setQuestionType(types[0])
    }
  }, [])

  const handleSave = (event) => {
    console.log('details', details)
    if (props.handleSave) props.handleSave(details)
  }

  const handleCancel = (event) => {
    if (props.handleCancel) props.handleCancel();
  }

  const handleDetailsChange = (d) => {
    setDetails(d)
  }

  return (
    <div className={classes.root}>
      <Typography variant="h6" className={classes.paperContainerHeading}>
        Add Question
      </Typography>
      <div className={classes.content}>
        <div className={classes.itemHeading}>
          <TextField
            id="question"
            label="Question"
            disabled={!props.editable}
            placeholder="Question"
            helperText="Enter the question"
            margin="normal"
            variant="outlined"
            fullWidth
            style={{ width: "97%", margin: "8pt" }}
            className={classes.textField}
            defaultValue={details.question}
            onChange={(e) => {
              let d = details
              d.question = e.target.value
              setDetails(d)
            }}
          />
          <FormControl variant="outlined" className={classes.formControl} fullWidth>
            <InputLabel htmlFor="typeSelect">Question Type</InputLabel>
            <Select
              fullWidth
              labelId={'typeSelect'}
              value={questionType ? questionType.name : ''}
              className={classes.selectField}
              onChange={(e) => {
                let d = details
                d.type = e.target.value.value
                setDetails(d)
                setQuestionType(e.target.value)
              }}
              label="Question Type"
              renderValue={(value) => { return value }}
            >
              {questionTypes.map((r) => <MenuItem key={r + "_question_item"} value={r}>{r.name}</MenuItem>)}
            </Select>
          </FormControl>
        </div>
        <div className={classes.questionTypes}>
          <QuestionType type={questionType} details={details} onChange={handleDetailsChange} />
          <Divider />
        </div>
        <div>
          <Autocomplete
            id="exclusions"
            ref={exclusionsRef}
            disabled={!props.editable}
            multiple
            // disableCloseOnSelect
            defaultValue={props.exclusions.filter(t => details.exclusions.filter(tt => tt.id === t.id).length > 0)}
            options={props.exclusions}
            getOptionLabel={(option) => option.name}
            style={{ width: '97%', margin: "8pt" }}
            renderInput={(params) => <TextField
              {...params}
              fullWidth
              variant="outlined"
              label="Exclusions"
              placeholder="Search for a exclusion"
              helperText="Select all the appropriate exclusions, if exclusion does not exist type exclusion tag name and it will be created" />}
            renderOption={(option, { selected }) => (
              <React.Fragment>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option.name}
              </React.Fragment>
            )}
            filterOptions={(options, params) => {
              const filtered = filter(options, params);
              // Suggest the creation of a new value
              if (params.inputValue !== '') {
                filtered.push({
                  inputValue: params.inputValue,
                  name: `Add "${params.inputValue}"`
                });
                // handleAddNewTag(params.inputValue)
              }
              return filtered;
            }}
            onChange={(e, v) => {
              console.log('value', v)
              let d = details
              d.exclusions = v
              setDetails(d)
            }}
          />
        </div>
      </div>
      <div className={classes.buttonContainer}>
        <div className={classes.buttonLeft}>
          <Button className={classes.button} variant="contained" onClick={handleCancel}>Cancel</Button>
        </div>
        <div className={classes.buttonRight}>
          {props.editable && <Button className={classes.button} variant="contained" onClick={handleSave} color="primary">
            Save
          </Button>}
        </div>
      </div>
    </div>
  );
}

export default QuestionAdd;
